import { useEffect, useRef, useState } from "react"



export function CanvasView({ currentGame }) {


    const stateRef = useRef()



    class User {
        constructor(userId, name, x, y) {
            this.userId = userId
            this.name = name;
            this.x = x;
            this.y = y;
        }
    }
    const users = []

    const canvasRef = useRef()
    const ctx = useRef();
    const timerRef = useRef()


    let canvasHeight = 0
    let canvasWidth = 0;
    let viewportHeight = 0;
    let viewportWidth = 0;
    const startPointX = 20;
    const endPointX = 20;
    const startPointY = 20;
    const endPointY = 20;

    let x0;
    let x1
    let x2;
    let y0
    let y1
    let y2;


    const init = () => {
        let canvas = canvasRef.current;

        ctx.current = canvasRef.current.getContext('2d');

        canvas.style.width = "200%";
        canvas.style.height = "200%";
        console.log(canvas.offsetHeight, canvas.offsetHeight)
        canvas.width =2 * canvas.offsetWidth;
        canvas.height = 2 * canvas.offsetHeight;

        canvasHeight = canvas.clientHeight;  //innerHeight
        canvasWidth = canvas.clientWidth;

        viewportWidth = canvasWidth
        viewportHeight = canvasHeight

        x0 = x1 = x2 = 20;
        y0 = y1 = y2 = canvasHeight - 27;
    }




    const drawLines = () => {
        ctx.current.beginPath()
        ctx.current.moveTo(20, 20)
        ctx.current.lineTo(20, canvasHeight - 20)
        ctx.current.lineTo(canvasWidth - 20, canvasHeight - 20)
        ctx.current.strokeStyle = "rgba(255,255,255,0.5)"
        ctx.current.lineWidth = '0.4'
        ctx.current.stroke()
    }



    const crashedFillGradient = (x0, y0, x2, y2) => {
        const angle = 90 * Math.PI / 180
        const _x2 = x2 * Math.cos(angle)
        const _y2 = y2 * Math.sin(angle)
        const grd = ctx.current.createLinearGradient(x0, y0, _x2, _y2);
        grd.addColorStop(0, "rgb(82,84,85)");
        grd.addColorStop(1, "rgb(35,36,38)");
        return grd
    }


    const fillGradient = (x0, y0, x2, y2) => {
        const angle = 90 * Math.PI / 180
        const _x2 = x2 * Math.cos(angle)
        const _y2 = y2 * Math.sin(angle)
        const grd = ctx.current.createLinearGradient(x0, y0, _x2, _y2);
        grd.addColorStop(0, "rgb(75,21,165)");
        grd.addColorStop(1, "rgb(36,25,61)");
        return grd
    }

    const strokeGradient = (x0, y0, x2, y2) => {
        const angle = 45 * Math.PI / 180
        const _x2 = x2 * Math.cos(angle)
        const _y2 = y2 * Math.sin(angle)
        const grd = ctx.current.createLinearGradient(x0, y0, _x2, _y2);
        grd.addColorStop(0, "white")
        grd.addColorStop(1, "rgb(94,0,254)");
        return grd
    }



    const crashedStrokeGradient = (x0, y0, x2, y2) => {
        const angle = 45 * Math.PI / 180
        const _x2 = x2 * Math.cos(angle)
        const _y2 = y2 * Math.sin(angle)
        const grd = ctx.current.createLinearGradient(x0, y0, _x2, _y2);
        grd.addColorStop(0, "rgb(170,172,173)")
        grd.addColorStop(1, "rgb(59,60,62)");
        return grd
    }





    const drawText = (user) => {
        ctx.current.font = "light 14px Open Sans";
        ctx.current.fillStyle = "rgba(255,255,255,0.5)";
        ctx.current.textAlign = "center";
        ctx.current.fillText(user.name + ' @100INR', user.x, user.y);

        drawFontArc(user.x, user.y - 10)
    }



    const addText = (name, x, y) => {
        const userId = parseInt(Math.random() * 10000).toString()
        const user = new User(userId, name, x, y)
        users.push(user)
        drawText(user)
    }


    const fillLine = (x0, y0, x1, y1, x2, y2) => {
        ctx.current.beginPath();
        ctx.current.moveTo(x0, y0 + 6);
        ctx.current.quadraticCurveTo(x1, y1 + 6, x2, y2 + 6)
        ctx.current.lineTo(x2, y2);
        ctx.current.lineTo(x2, y0 + 6);

        const grad = stateRef.current == 'crashed' ? crashedFillGradient(x0, y0, x2, y2) : fillGradient(x0, y0, x2, y2)

        //const grad = fillGradient(x0, y0, x2, y2)
        ctx.current.fillStyle = grad
        ctx.current.fill();
        ctx.current.closePath();

    }

    const drawLine = (x0, y0, x1, y1, x2, y2) => {
        ctx.current.beginPath();
        ctx.current.moveTo(x0, y0);
        ctx.current.quadraticCurveTo(x1, y1, x2 - 3, y2) //
        ctx.current.lineWidth = 15
        const grad = stateRef.current == 'crashed' ? crashedStrokeGradient(x0, y0, x2, y2) : strokeGradient(x0, y0, x2, y2)
        ctx.current.strokeStyle = grad
        ctx.current.stroke();
    }

    const drawArc = (x, y) => {
        ctx.current.beginPath()
        ctx.current.arc(x - 2, y - 0, 10, 0, 2 * Math.PI)
        ctx.current.fillStyle = 'white'  //rgb(59,60,62)
        ctx.current.fill()
    }



    const drawFontArc = (x, y) => {
        ctx.current.beginPath()
        ctx.current.arc(x, y, 5, 0, 2 * Math.PI)
        ctx.current.fillStyle = "rgba(255,255,255,0.5)"
        ctx.current.fill()
    }


    const drawTexts = () => {

        for (let user of users) {
            user.y += 3;
            user.x -= 0.3;
            drawText(user)
        }
        for (let user of users) {
            if (user.y > canvasHeight) {
                let index = users.findIndex((item) => item.userId == user.userId)
                users.splice(index, 1)
            };
        }
    }





    const startDraw = () => {

        let endpointX = viewportWidth - 10;
        let endpointY = 10
        let xSpeed = 1;

        let time = 8000;


        if(canvasHeight<768){
            time = 7000;
        }
        const totalSteps = endpointX / xSpeed
        let timePerStop = Math.floor(time / totalSteps)
        const ySpeed = viewportHeight / totalSteps

        timerRef.current = setInterval(() => {


            if(stateRef.current!='crashed'){
                x2 += xSpeed
                y2 -= ySpeed;
    
                x2 = x2 > endpointX ? endpointX : x2
                y2 = y2 < endpointY ? endpointY : y2
    
                x1 += (xSpeed * 0.4)
                y1 -= (ySpeed * 0.1)
    
                x1 = x1 > endpointX ? endpointX : x1
                y1 = y1 < endpointY ? endpointY : y1
            }
            

            // if (x2 < endpointX) x1 += (xSpeed * 0.4)
            // if (y2 > endpointY) y1 -= (ySpeed * 0.1)

            ctx.current.clearRect(0, 0, canvasWidth, canvasHeight);

            drawLines()
            drawLine(x0, y0, x1, y1, x2, y2)
            fillLine(x0, y0, x1, y1, x2, y2)
            drawArc(x2, y2)
            drawTexts()


        }, timePerStop)

    }


    const onCrashed = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
        }


    }

    const stop = () => {
        onCrashed()
        ctx.current.clearRect(0, 0, canvasWidth, canvasHeight);
    }



    const resize = () => {
        init()
    }

    useEffect(() => {
        window.addEventListener('resize', resize);
        return () => {
            clearInterval(timerRef.current)
            window.removeEventListener('resize', resize)
        }
    }, [])


    useEffect(() => {

        if (!currentGame) return;

        init()
        stateRef.current = currentGame.status

       
        switch (currentGame.status) {
            case 'running': {
                startDraw()
             
                break
            }
            case 'lobby': {
               
                stop()
                break
            }
            case 'crashed' :{
               
                //stopDrawing()
            }
        }
    }, [currentGame.status])


    return <canvas ref={canvasRef} id="myCanvas"></canvas>

}