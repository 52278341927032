import { Button, Card, Navbar, Tab, Tabs } from "react-bootstrap";
import './wingo.scss'
import { MdOutlineWallet, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdRefresh, MdSync } from 'react-icons/md'
import { useContext, useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'
import PlaceBetSheet from "./place_bet_sheet";
import TimerView from "./widgets/timer_view";
import WingoContext, { WinGoContextProvider } from "../../context/wingo_context";
import CountDownView from "./widgets/count_down_view";
import { AccountContext } from "../../context/account_context";
import moment from 'moment'
import { BetItem } from "./widgets/bet_item";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png"
import { FiArrowLeft } from 'react-icons/fi';
import { NavBar } from "../../widgets/navBar";
const numberToPng = (number) => {
    switch (number) {
        case 0: return "zero_number.png"
        case 1: return "one_number.png"
        case 2: return "two_number.png"
        case 3: return "three_number.png"
        case 4: return "four_number.png"
        case 5: return "five_number.png"
        case 6: return "six_number.png"
        case 7: return "seven_number.png"
        case 8: return "eight_number.png"
        case 9: return "nine_number.png"
    }
}



function GameTypeTab({ active = false, title, onClick }) {
    return <div onClick={onClick} className={`game-type-tab ${active ? "active" : ""}`}>
        <img className={`tab-time-img ${active ? "active" : ""}`} src={active ? "time-enabled.png" : "time-disabled.png"}></img>
        <span className={`type-tab-title ${active ? "active" : ""}`}>Wingo</span>
        <span className={`type-tab-title ${active ? "active" : ""}`}>{title}</span>
    </div>
}


function ActionTab({ active = false, title, onClick }) {
    return <div onClick={onClick} className={`action-tab ${active ? "active" : ""}`}>
        <span className={`action-tab-title ${active ? "active" : ""}`}>{title}</span>
    </div>
}


export const WingoGame = () => {
    return <WinGoContextProvider>
        <WinGo />
    </WinGoContextProvider>
}


export default function WinGo() {


    var audio = new Audio("/audio/vpgo.mp3");

    const [activeActionTab, setActiveActionTab] = useState(0)

    const [bottomSheet, showBottomSheet] = useState(undefined)
    const [quantity, setQuantity] = useState(1)

    const navigate = useNavigate();


    const { currentGame, history, lastNumbers, connectSocket, closeSocket, activeGameType, setActiveGameType, fetchGameHistory, betHistory, fetchBetHistory,soundEnabled,toggleSound } = useContext(WingoContext)

    const runningGame = currentGame[activeGameType]
    console.log(runningGame, currentGame)


    const { syncWalletBalance, wallet, mainBalance } = useContext(AccountContext)

    const onClickBet = (bet) => showBottomSheet({ ...bet, "quantity": quantity })
    const [generateRandomNumber, setGenerateRandomNumber] = useState(false)

    const getRandomNumber = () => {
        setGenerateRandomNumber(true)
        setTimeout(() => {
            const randomNumber = parseInt(Math.random() * 10)
            onClickBet({
                type: 'number',
                value: randomNumber
            })
            setGenerateRandomNumber(false)
        }, 800)
    }


    const gameTypeHistory = history[activeGameType];

    const last5Games = gameTypeHistory.data.slice(0, 5).map(element => element['result'].number)


    useEffect(() => {
        connectSocket()
        return () => closeSocket()
    }, [])



    return <>
        <audio id="backgroundMusic" loop={false}>
            <source src={"/audio/vpgo.mp3"} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
        <div className="wingo-container">
            {/* <NavBar title="Wingo"></NavBar> */}
            {/* <Navbar expand="lg" className="wingo-nav" bg="dark">
            <span className="logo pt-3"><img src={logo} /></span>
        </Navbar> */}

            <div className="wingo-nav" >
                <span onClick={() => navigate(-1)}>
                    <FiArrowLeft color='white' className='ms-1' size={20}></FiArrowLeft>
                </span>
                <span className="logo pt-2 pb-2">
                    <img src={logo} width={140} />
                </span>
                <span className="wingo-action-buttons">
                    <div onClick={toggleSound}>
                        <img hidden={!soundEnabled} className="wingo-sound-button" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAI9/vQqATwHGRUoHQYjKwCnzmPwAAAwJJREFUaN7tWd2aqyAMlEDkR1De/2nPsu6WtW0Y0O7F+bZz1yIzgYQE4/TGG2/8IczG8UaUM6XD/4YpZ22TusRufMw36J8DlL+xnGZXi84/QXVoOfyvzhlv8xE6Hfkr4nyJntgFdTBS5QJv5mlNdGqXZv62mtMT67iMrF+PxvJjcAnha++tez6xjKWbMeVhM2S+/6Ivs0SBKs1VrguKdsdVeiCwjQmsn/zaTQ18+7UaZPr5dUdk53sn0yA/g6DIn2DzYb57CFPMjx/Pd6Ax/y7ToEBUvfFZ+bEA3TLI0n3KfOXHAlPg+MFezyJGKvP81CsgADiA5t8T4DJNTb8moIADkIDTIJgsiGcgUOyzzQJTJoXzAmsGGWkDFsAtsm0CBQzAAqYdI9zWNz7qcqq8kaPINoOEWkUjUb6BkiTgStoQLWwMKpsPIPUggGuzL+m9fQGo0OH5QeNGpolijIad0wc1TSpwlXmyk7If51rF76DuKpy6SUgsogui6Pzs7n0p5CK5+jvJBany3ysIse5EHztpAfzIUzBi52Tr2vDp3OMxCwGxiUG09kevFwSUmJC1kEbs8+gNwwJ1AhKumXGo0o1O+H8EGlukhwRIcHKUnWwnIAwEajz6/rS5ihlnK5ZKZWLuLq9GTKc8nCpISmn+RckuDdk5GfEI2ufpmsSMY84VHHV8Q9cKszxqp0bJzLyXTF/0WtU1vqToV34QvSAe63vDAVYJJPXaIgy6zouXgNS8ni/o6mjL1TF6077/M7r8dgOXV3D7BhAINmDAlSUEON/WKD4DgjtgalI4gaXjFZVrDjj3isqoU6HBizhochA0LgAzQJMjXWqGYAf4S+0czE/zpYYU5ldXWmqYX69jTTs/9/egAT9oa+I9FfhBY7Z3m9xuDeAXKhgFmKBjBquFzXH0gQH6C9VImyQbnG1dADBU/UAR5gf2xDrv4A7zcQsksjOr+vxTBcf76uAeYpgtt4DpMRSTxK4XFDvjH+oqojcv/tTobdyPFG3szDy98cYbfwf/ACnpTX1HX6f8AAAAAElFTkSuQmCC"></img>
                        <img hidden={soundEnabled} className="wingo-sound-button" src="https://damangames.in/assets/png/voice-off-633f5ccc.png" />
                    </div>

                </span>

            </div>
            <div className="head-container">
                <div className="wingo-wallet-container" >
                    <div className="wallet-inner-section m-2">
                        <span className="wallet-amount">{mainBalance()}  <span className="balance-sync-button">
                            <MdRefresh color="black" size={20} onClick={syncWalletBalance} />
                        </span></span>


                        <span className="wallet-balance-label mt-1">
                            <MdOutlineWallet color="#f00" size={18} className="me-1" />
                            Wallet Balance
                        </span>

                        <span className="mt-2 wallet-action-button-container"  >
                            <span className="wallet-action-button withdraw" onClick={() => {
                                navigate('/withdraw')
                            }}>Withdraw</span>
                            <span onClick={() => {
                                navigate('/deposit')
                            }} className="wallet-action-button deposit">Deposit</span>
                        </span>
                    </div>
                </div>
            </div>

            <div className="game-type-tab-container ms-1 me-1 mt-2 mb-2">
                <GameTypeTab active={activeGameType == '1min'} title={"1 Min"} onClick={(e) => {
                    e.preventDefault()
                    setActiveGameType('1min')
                }} />
                <GameTypeTab active={activeGameType == '3min'} title={"3 Mins"} onClick={(e) => {
                    e.preventDefault()
                    setActiveGameType('3min')
                }} />
                <GameTypeTab active={activeGameType == '5min'} title={"5 Mins"} onClick={(e) => {
                    e.preventDefault()
                    setActiveGameType('5min')
                }} />
                <GameTypeTab active={activeGameType == '15min'} title={"15 Min"} onClick={(e) => {
                    e.preventDefault()
                    setActiveGameType('15min')
                }} />
            </div>


            <div className="mt-2 time-container ms-1 me-1">

                <div className="time-container-inner pt-1 pb-1">
                    <div className="section-1">

                        <span className="how-to-play">
                            How to play
                        </span>
                        <span className="last-games-label">Last Games</span>
                        <div className="last-games-container">
                            {
                                last5Games.map(item => {
                                    return <div className="last-history-item">{
                                        <img src={numberToPng(item)}></img>
                                    }</div>
                                })
                            }
                        </div>

                    </div>

                    <div className="section-2">
                        <TimerView currentGame={runningGame} />
                        <span className="game-id">{runningGame && runningGame['gameId']}</span>

                    </div>
                </div>

            </div>


            <div className="action-container p-1 ms-1 me-1 mt-2">

                <div className="action-wrapper">
                    <div className="color-action-container">
                        <span className="color-button green" onClick={() => onClickBet({ 'betType': 'color', 'value': 'green' })}>Green</span>
                        <span className="color-button violet" onClick={() => onClickBet({ 'betType': 'color', 'value': 'violet' })}>Violet</span>
                        <span className="color-button red" onClick={() => onClickBet({ 'betType': 'color', 'value': 'red' })}>Red</span>
                    </div>

                    <div className="number-action-container">
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => {
                            return <div key={item} onClick={() => onClickBet({ 'betType': 'number', "value": item })} className={`number-button ${generateRandomNumber ? `number-button-animated` : ""}`} >
                                <img src={numberToPng(item)}></img>
                            </div>
                        })}
                    </div>


                    <div className="random-action-container">
                        <span className="random-button" onClick={getRandomNumber}>
                            Random
                        </span>
                        {[1, 5, 10, 20, 50, 100].map(item => {
                            return <div key={item} onClick={() => setQuantity(item)} className={`x-button ${item == quantity ? "x-button-active" : ''}`}>X{item}</div>
                        })}
                    </div>

                    <div className="bigsmall-container">
                        <span className="bigsmall-button small-button" onClick={() => onClickBet({ betType: 'bigSmall', 'value': 'small' })}>
                            Small
                        </span>
                        <span className="bigsmall-button big-button" onClick={() => onClickBet({ betType: 'bigSmall', 'value': 'big' })}>
                            Big
                        </span>
                    </div>

                </div>

                <CountDownView currentGame={runningGame} />
            </div>


            <div className="action-tab-container ms-1 me-1 mt-2 mb-2">

                <ActionTab active={activeActionTab == 0} title={"Game History"} onClick={() => {
                    setActiveActionTab(0)
                }} />
                <ActionTab title={"My History"} active={activeActionTab == 1} onClick={() => {
                    setActiveActionTab(1)
                }} />
            </div>

            {activeActionTab == 0 ? <HistoryTabContainer gameTypeHistory={gameTypeHistory} fetchGameHistory={fetchGameHistory} activeGameType={activeGameType} />
                : <MyBetsTab betHistory={betHistory} activeGameType={activeGameType} fetchBetHistory={fetchBetHistory} />}
        </div>
        <PlaceBetSheet bet={bottomSheet} gameType={activeGameType} onClose={() => showBottomSheet(undefined)} />
    </>
}



function HistoryTabContainer({ gameTypeHistory, fetchGameHistory, activeGameType }) {
    const limit = 10;
    const startIndex = (gameTypeHistory.currentPage - 1) * limit
    const endIndex = startIndex + limit

    const currentPage = gameTypeHistory['currentPage']
    const totalPage = Math.ceil(gameTypeHistory.totalItems / limit);



    return <div className="history-container ms-1 me-1" >
        <div className="header">
            <span className="header-item gameId">
                Period
            </span>
            <span className="header-item">
                Number
            </span>
            <span className="header-item">
                Big Small
            </span>
            <span className="header-item">
                Color
            </span>
        </div>


        <div className="history-body">
            {
                gameTypeHistory.data.slice(startIndex, endIndex).map(item => {
                    const resultNo = item['result'].number;
                    return <div className="history-row">
                        <span className="history-item gameId">
                            {item['gameId']}
                        </span>
                        <span className={`history-item history-number history-number-${resultNo == 0 ? "zero" : resultNo == 5 ? 'five' : resultNo % 2 == 0 ? 'red' : 'green'}`}>
                            {item['result'].number}
                        </span>
                        <span className="history-item">
                            {item['result'].bigSmall}
                        </span>
                        <span className="history-item">
                            {item['result'].colors.map(item => {
                                return <div className={`history-item-color-${item}`}></div>
                            })}
                        </span>
                    </div>
                })
            }

        </div>


        <div className="footer">
            <span className="pagination-button">
                <MdKeyboardArrowLeft color="white" size={'24px'} onClick={() => {
                    if (currentPage - 1 < 1) return;
                    fetchGameHistory(activeGameType, currentPage - 1)
                }} />
            </span>
            <span className="pagination ms-2 me-2">{currentPage} of {totalPage}</span>
            <span className="pagination-button">
                <MdKeyboardArrowRight color="white" size={'24px'} onClick={() => {
                    if (currentPage + 1 > totalPage) return;
                    fetchGameHistory(activeGameType, currentPage + 1)
                }} />
            </span>
        </div>
    </div>

}



function MyBetsTab({ betHistory, activeGameType, fetchBetHistory }) {

    const navigate = useNavigate()



    const currentBetHistory = betHistory[activeGameType]
    const limit = 10;
    const startIndex = (currentBetHistory.currentPage - 1) * limit
    const endIndex = startIndex + limit

    const currentPage = currentBetHistory['currentPage']
    const totalPage = Math.ceil(currentBetHistory.totalItems / limit);
    const [detailsSection, setDetailsSection] = useState(undefined)



    return <div className="history-container ms-1 me-1">
        <div className="bet-header">
            <span className="details-button" onClick={() => {
                navigate('/betRecords')
            }}>
                Detail
            </span>
        </div>


        <div className="bet-body">
            {
                currentBetHistory.data.slice(startIndex, endIndex).map(item => {
                    //const resultBigSmall = item['result'].bigSmall;
                    return <BetItem setDetailsSection={setDetailsSection} detailsSection={detailsSection} item={item} />
                })
            }

        </div>


        <div className="footer">
            <span className="pagination-button">
                <MdKeyboardArrowLeft color="white" size={'24px'} onClick={() => {
                    if (currentPage - 1 < 1) return;
                    fetchBetHistory(activeGameType, currentPage - 1)

                }} />
            </span>
            <span className="pagination ms-2 me-2">{currentPage} of {totalPage}</span>
            <span className="pagination-button">
                <MdKeyboardArrowRight color="white" size={'24px'} onClick={() => {
                    if (currentPage + 1 > totalPage) return;
                    fetchBetHistory(activeGameType, currentPage + 1)

                }} />
            </span>
        </div>
    </div>

}


