import { useEffect, useState } from "react";
import { NavBar } from "../../widgets/navBar";
import { getRequest } from "../../api/api";
import { LoadingPage } from "../../widgets/loading_page";
import { EmptyWidget } from "../../widgets/empty_widget";


import { BetItem } from "../../games/wingo/widgets/bet_item";

export default function BetRecords() {

    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })
    const [detailsSection,setDetailsSection] = useState(undefined)

    const gameType = (index)=>{
        switch(index){
            case 0 : return "1min"
            case 1 : return "3min"
            case 2 : return "5min"
            case 3 : return "15min"
            default : return '1min';
        }
    }
    const fetch = async () => {
        try {

            var type = gameType(activeIndex)
            let endpoint = `bets/vpgo?type=${type}`;
            const betsResult = await getRequest(endpoint)
            setData({ data: betsResult, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }

    useEffect(() => {
        fetch();
    }, [activeIndex])


    return <>
        <NavBar title={"Bet Records"}></NavBar>
        <Tabs activeIndex={activeIndex} onClick={(index) => {
            setActiveIndex(index)
        }} />


        {data.loading && <LoadingPage />}

        {data.data && data.data.totalItems == 0 && (<div className="h-100 d-flex  flex-column justify-content-center align-items-center"> <EmptyWidget /></div>)}


        <div>
            {data.data && data.data['bets'].map(item => {
                return <BetItem item={item} setDetailsSection={setDetailsSection} detailsSection={detailsSection}  />
            })}
        </div>


    </>
}

function BetsListView({ bets }) {
    return
}



function Tabs({ activeIndex, onClick }) {
    return <div data-v-caf22e0f="" className="subordinate__container-header pb-4">
        <div data-v-caf22e0f="" className="van-tabs van-tabs--card footer-tabBar">
            <div className="van-tabs__wrap"><div role="tablist" className="van-tabs__nav van-tabs__nav--card" aria-orientation="horizontal" >
                <div id="van-tabs-1-0" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 0 ? "van-tab--active" : ""}`} aria-selected="true" aria-controls="van-tab-1" onClick={() => onClick(0)}><span className="van-tab__text van-tab__text--ellipsis">1 Min</span></div>
                <div id="van-tabs-1-1" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 1 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-2" onClick={() => onClick(1)}><span className="van-tab__text van-tab__text--ellipsis">3 Min</span></div>
                <div id="van-tabs-1-2" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 2 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-3" onClick={() => onClick(2)}><span className="van-tab__text van-tab__text--ellipsis">5 Min</span></div>
                <div id="van-tabs-1-4" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 3 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-4" onClick={() => onClick(3)}><span className="van-tab__text van-tab__text--ellipsis">15 Min</span></div>
            </div>
            </div>
            <div className="van-tabs__content"></div></div></div>
}