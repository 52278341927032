import { useContext, useState } from "react"
import Wallet from "./pages/Wallet/index"
import Profile from "./pages/profile"
import Dashboard from './dashboard';
import { Home } from "./pages/Home";
import { LoginPage } from "./pages/auth/login";
import SignupPage from "./pages/auth/signup";

import { BrowserRouter as Router, Route, Redirect, Routes, useParams, useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom';

import { AccountContext, AccountContextProvider } from './context/account_context';


import { createBrowserHistory } from "history";
import { DepositPage } from "./pages/Wallet/deposit";
import { DepositSuccess } from "./pages/Wallet/deposit_success";
import TransactionHistory from "./pages/Wallet/history";
import { CommisionPage } from "./pages/promotion/commision";
import { Subordinate } from "./pages/promotion/subordinate";
import { NewSubordinate } from "./pages/promotion/newSubordinate";
import { InvitationRule } from "./pages/promotion/invitationRule";
import ActivityPage from "./pages/activity";
import Attendence from "./pages/activity/attendence";
import BetRecords from "./pages/profile/betRecords";
import { GameStatics } from "./pages/profile/gameStatics";
import { MissionBonusPage } from "./pages/activity/missionBonus";
import { CoinpaymentsPage } from "./pages/Wallet/coinPaymentPage";
import { SplashPage } from "./pages/splash/splashPage";
import { CurrencyContext } from "./context/currencyContext";
import { WingoGame } from './games/wingo'
import CrahGamePage from "./games/crash";
import { WithdrawPage } from "./pages/withdraw";
import { WithdrawRequest } from "./pages/Wallet/withdrawRequests";
import { ResetPasswordPage } from "./pages/auth/resetPassword";
import { Support } from "./pages/support";
import UpdateAvatarPage from "./pages/profile/updateAvatarPage";

const hist = createBrowserHistory();
export function Main() {

  const { account } = useContext(AccountContext)
  const [activeTab, setActiveTab] = useState(0);
  return <>
  
  <div id="app">

    <Router history={hist}>
      <Routes>

        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/resetPassword" element={<ResetPasswordPage />} />


        <Route path="/dashboard/:tabId" element={<DashboardWrapper/>} />


        {/* <Route path="/dashboard/:tabId" element={<PrivateRoute element={Dashboard} />} /> */}

        <Route path="/deposit" element={<PrivateRoute element={DepositPage} />} />
        <Route path="/deposit_success" element={<PrivateRoute element={DepositSuccess} />} />

        <Route path="/transaction_history" element={<PrivateRoute element={TransactionHistory} />} />
        <Route path="/vpgo" element={<PrivateRoute element={WingoGame} />} />

        <Route path="/subordinate" element={<PrivateRoute element={Subordinate} />} />
        <Route path="/commision" element={<PrivateRoute element={CommisionPage} />} />
        <Route path="/new-subordinate" element={<PrivateRoute element={NewSubordinate} />} />
        <Route path="/rules" element={<PrivateRoute element={InvitationRule} />} />
        <Route path="/attendence" element={<PrivateRoute element={Attendence} />} />
        <Route path="/betRecords" element={<PrivateRoute element={BetRecords} />} />
        <Route path="/gameStats" element={<PrivateRoute element={GameStatics} />} />
        <Route path="/missionBonus" element={<PrivateRoute element={MissionBonusPage} />} />
        <Route path="/checkoutPage" element={<PrivateRoute element={CoinpaymentsPage} />} />
        <Route path="/withdraw" element={<PrivateRoute element={WithdrawPage} />} />
        <Route path="/withdrawRequests" element={<PrivateRoute element={WithdrawRequest} />} />
        <Route path="/updateAvatar" element={<PrivateRoute element={UpdateAvatarPage} />} />

        <Route path="/support" element={<PrivateRoute element={Support} />} />
        <Route path="/crash" element={<PrivateRoute element={CrahGamePage}/>}/>

        <Route path="/splash" element={<SplashPage/>} />
        
        <Route path="/" element={<Navigate to={'/dashboard/home'} />} />
        <Route path="/dashboard" element={<Navigate to={'/dashboard/home'} />} />
      </Routes>
    </Router>

  </div>

  
  </>
}



function DashboardWrapper(props) {
  const { account } = useContext(AccountContext)


  const navigate = useNavigate();

  const params = useParams()
  const tabId = params['tabId']

  
  const { currencyCode, currencyRates } = useContext(CurrencyContext)

  if (tabId != 'home' && !account) return <Navigate to={'/login'} />
  if (!currencyRates) return <Navigate to={"/splash"} />

  return <Dashboard/>
}


function AuthRoute(props) {
  const { account } = useContext(AccountContext)
  if (!account) return <Navigate to={'/login'} />
  return <props.element />
}


function PrivateRoute(props) {
  const { account } = useContext(AccountContext)
  const { currencyCode, currencyRates } = useContext(CurrencyContext)

  if (!account) return <Navigate to={'/login'} />
  if (!currencyRates) return <Navigate to={"/splash"} />
  return <props.element />
}








