import { useContext, useEffect, useState } from "react";
import { NavBar } from "../../widgets/navBar";
import { getRequest, useFetch } from "../../api/api";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { CurrencyContext } from "../../context/currencyContext";

export default function TransactionHistory() {
    const {convertCoin} = useContext(CurrencyContext)

    const location = useLocation()
    const type = location.search.split("=")[1]
    const [apiData, setApiData] = useState({ loading: true, error: null, data: undefined })

    const fetchHistory = () => {

        let endpoint = "users/transaction_history?";
        if(type) endpoint += "type="+type
        getRequest(endpoint)
            .then(res => {
                setApiData({ loading: false, data: res })
                debugger;
            })
            .catch(e => {
                setApiData({ loading: false, error: e })
            })
    }


    useEffect(() => {
        fetchHistory()
    }, [])


    if (apiData['loading']) return <div className="d-flex h-100 justify-content-center align-items-center w-100">
        <Spinner size="sm" className="page-loading-spinner"></Spinner>
    </div>
    if (apiData['error']) return <div>Error: </div>



    return <div className="rechargeh__container pt-4">

        <NavBar title={'Transction Histroy'} ></NavBar>

        <div className="rechargeh__container-content" data-v-17ffea4a>
            {
                apiData['data'].transactions.map(item => {
                    return <TransctionItem item={item} />
                })
            }
        </div>
    </div>
}


function TransctionItem({ item }) {
    const {convertCoin} = useContext(CurrencyContext)
    return <div data-v-17ffea4a="" className="rechargeh__container-content__item" >
        <div data-v-17ffea4a="" className="rechargeh__container-content__item-header">
            <span data-v-17ffea4a="" className="recharge_tit">{statusLabel(item)}</span><div data-v-17ffea4a="" className="recharge_right recharge">Complete <i data-v-17ffea4a="" className="van-badge__wrapper van-icon van-icon-arrow"></i></div></div>
        <div data-v-17ffea4a="" role="separator" className="van-divider van-divider--hairline divier"></div>
        <div data-v-17ffea4a="" className="rechargeh__container-content__item-body">
            <div data-v-17ffea4a="">
                <span data-v-17ffea4a="">Balance</span><span data-v-17ffea4a="" className="price">{convertCoin(item['amount'])}</span></div>
            {/* <div data-v-17ffea4a=""><span data-v-17ffea4a="">Closing</span><span data-v-17ffea4a="">₹{fetchClosingBalance(item)}</span></div> */}
            <div data-v-17ffea4a=""><span data-v-17ffea4a="">Time</span><span data-v-17ffea4a="">{moment(item.createdAt).format('yyyy-MM-DD hh:mm:ss A')}</span></div>
            <div data-v-17ffea4a=""><span data-v-17ffea4a="">Order number</span><div data-v-17ffea4a="" className="order"><span data-v-17ffea4a="" className="me-1">{item['_id']}</span>
                <img data-v-17ffea4a="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAMFBMVEUAAACvsMetr8Wqr8KusMausMarr8OvsMevsMavscelr8WusMausMausMasr8ausMY46WCNAAAAD3RSTlMAn2Ag75BAv69/EN/Pv1Dhl63OAAAAc0lEQVQoz2MgDqwUhAJRA4jA+f8w4Azms/1WggKNr2ABpo9AAsHCFEhS/A5Wr2cAEWCHm/gTIqDvCLVT4v8CsIC8Alg7mDWMBApFPqEK/P9/AVVASI0BzQwGrALxTbBI2J8AFjCBh+kfiELmQFhEziIu4gFZQkr21tMcBQAAAABJRU5ErkJggg==" alt="" />
            </div></div></div></div>
}

const fetchClosingBalance =(item)=>{
    switch(item['type']){
        case "depositBonus" : 
        case 'firstDepositBonus':
        case "bonus" : 
        case "referralBonus": return item['closingBalance'].bonus
        case 'deposit' : return item['closingBalance'].deposit
    }
}

const statusLabel =(item)=>{
    switch(item['type']){

        case 'deposit' :return "Deposit"
        case "withdraw" : return "Withdraw"
        case "bet" : return 'Bet' 
        case "win" : return 'Win' 
        case "referralBonus":return 'Referral Bonus'
        case "missionLevelBonus" : return "Mission Level Bonus"
        case "depositBonus" : return 'Deposit Bonus' 
        case 'firstDepositBonus': return 'First Deposit Bonus'
        case "dailyCheckIn" : return "Daily CheckIn"
        case "withdrawRefund" : return "Withdraw Refund"
        case "commission" : return "Commission"
        case "bonus" : return 'Bonus'
       
     
        default : return 'Other'
    }
}