import { NavBar } from "../../widgets/navBar";



import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';


import { toast } from 'react-toastify';
import { makeSubmitRequest } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { ContryCodesSheet } from "../../models/countryCodesSheet";
import { FaAngleDown } from "react-icons/fa";

export function ResetPasswordPage() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [otpSent, setOTPSent] = useState(false)

    const [showPassword, setShowPassword] = useState(false)
    const [countrySheet, setShowCountrySheet] = useState(false)
    const [countryCode, setCountryCode] = useState("+91")




    const [otp, setOTP] = useState()

    const [data, setData] = useState({})
    const [toggle, setToggle] = useState(2)


    const onInputChange = (e) => {
        e.stopPropagation()
        if (e.target.name == "phone") {
            let value = e.target.value;
            setData({ ...data, [e.target.name]: value })
        } else {
            setData({ ...data, [e.target.name]: e.target.value })
        }
    }


    const onChangeOTP = (e) => {
        setOTP(e.target.value)
    }


    const sendOTP = (e) => {
        e.preventDefault()
        setLoading(true)

        const body = {...data}

        if(body['phone']) body['phone'] = countryCode + body['phone']

        if (!otpSent) {
            makeSubmitRequest('users/forgot-password', body).then((data) => {
                setLoading(false)
                setOTPSent(true)
            }).catch(e => {
                setLoading(false)
                toast.error(e.message)
            })
        } else {
            body['otp'] = otp
            makeSubmitRequest('users/reset-password', body).then(() => {
                setLoading(false)
                toast.success('Reset password successfully')
                navigate(-1)
            }).catch(e => {
                setLoading(false)
                toast.error(e.message)
            })
        }


    }


    function updateToggle(id) {
        setOTPSent(false)
        setData((oldState) => {
            const newState = { ...oldState, email: undefined, phone: undefined }
            setOTP(undefined)
            return newState;
        })
        setToggle(id)
    }



    return <>

        <ContryCodesSheet open={countrySheet} onClose={() => setShowCountrySheet(false)} onSelectCountry={(code) => {
            setCountryCode(code)
            setShowCountrySheet(false)
        }}> </ContryCodesSheet>


        <NavBar title={"Reset Password"}></NavBar>

        <div className="mb-3 text-white pt-4">
            <h2 className="mt-4 fw-bold mb-2 text-uppercase text-center f-20">Forgot Password</h2>
            <p className=" mb-4 f-10 text-center ms-2 me-2">Enter the Email/Phone number associated with your account, and we'll send OTP to reset your password.</p>
            <div className="mb-3">

                <ul className="d-flex m-0 tab-btn-design">
                    <li className={toggle == 1 ? "active" : ""} onClick={() => updateToggle(1)}>Phone</li>
                    <li className={toggle == 2 ? "active" : ""} onClick={() => updateToggle(2)}>Email</li>
                </ul>


                <Form onSubmit={sendOTP}>
                    {toggle == 1 && <div style={{ position: 'relative' }} className='mb-2'>
                        <Form.Group className="form-group" controlId="formBasicEmail">
                            {/* <Form.Label className="form-label mrbl">Phone number</Form.Label> */}
                            <span className="county_code" onClick={() => setShowCountrySheet(true)}>{countryCode}<FaAngleDown /></span>
                            <Form.Control
                                type="text"
                                disabled={otpSent || loading}
                                placeholder="Enter Phone Number"
                                bsPrefix="form-control system_input"
                                required
                                minLength={10}
                                maxLength={10}
                                name="phone"
                                className="app-input LS-input pl_40"
                                onChange={onInputChange}
                            />
                        </Form.Group>
                    </div>}

                    {toggle == 2 && <div style={{ position: 'relative' }} className='mb-2'>
                        <Form.Group className="form-group">

                            <Form.Control
                                className="app-input LS-input"
                                required
                                name="email"
                                type="email"
                                placeholder="Enter Email"
                                bsPrefix="form-control system_input"
                                onChange={onInputChange}
                                id="basic-addon1"

                            />
                        </Form.Group>
                    </div>}

                    {otpSent && <Form.Group
                      
                        className="mb-2"
                        controlId="otpControlId"
                        disabled={loading}
                        defaultValue={data['otp']}
                    >
                        <Form.Control
                            className="app-input LS-input"
                            type="text" pattern="\d*" maxLength={6} placeholder="6-Digit OTP" bsPrefix='form-control system_input'
                            onChange={onChangeOTP} />
                    </Form.Group>}

                    {otpSent && <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                    >
                    
                        <Form.Control type="password"
                            disabled={loading}
                            className="app-input LS-input"
                            defaultValue={data['password']}
                            name='password'
                            placeholder="Password" bsPrefix='form-control system_input' required minLength={6} maxLength={50}
                            onChange={onInputChange} />
                    </Form.Group>}


                    <div className="d-grid">
                        <Button
                            className="system_button app-button LSbtn"
                            variant="primary"
                            type="submit" disabled={loading}>
                            {otpSent ? 'Reset Password ' : "Send OTP"}
                        </Button>
                    </div>
                </Form>

            </div>
        </div>


    </>
}