import { useContext, useEffect, useState } from 'react';
import { NavBar } from '../../widgets/navBar'
import { getRequest } from '../../api/api';
import lotteryicon1 from '../../images/iconLottery.png'
import lotteryicon2 from '../../images/lotter-road-map.png'
import { CurrencyContext } from '../../context/currencyContext';


export function GameStatics() {
    const {convertCoin} = useContext(CurrencyContext)
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })
    const [detailsSection, setDetailsSection] = useState(undefined)

    const gameType = (index) => {
        switch (index) {
            case 0: return "today"
            case 1: return "yesterday"
            case 2: return "week"
            case 3: return "month"
            default: return 'month';
        }
    }
    const fetch = async () => {
        try {

            var type = gameType(activeIndex)
            let endpoint = `bets/vpgo/stats?date=${type}`;
            const betsResult = await getRequest(endpoint)
            setData({ data: betsResult, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }

    useEffect(() => {
        fetch();
    }, [activeIndex])

    return <>

        <NavBar title={"Game Statistics"} />
        <Tabs activeIndex={activeIndex} onClick={(index) => {
            setActiveIndex(index)
        }} />

        <div data-v-134c12b0="" className="gamestats-container-banner"><h1 data-v-134c12b0="">{(data['data'] && data['data'].totalAmount) ? convertCoin(data['data'].totalAmount) : "0"}</h1><span data-v-134c12b0="">Total bet</span></div>


        <div data-v-134c12b0="" className="gamestats-container-list-wrapper"><div data-v-134c12b0="" className="gamestats-container-items">
            <div data-v-134c12b0="" className="gamestats-container-item">
                <h1 data-v-134c12b0="" className='m-0'>
                   
                <span data-v-134c12b0="" className='h6 m-0 p-0' style={{fontSize:"25px"}}></span>
                </h1>
                <div data-v-134c12b0="" className="gamestats-container-item-content">
                    <img data-v-134c12b0="" alt="" className="" data-origin={lotteryicon2} src={lotteryicon2} />
                    <div data-v-134c12b0="" className="gamestats-container-item-content-list">
                        <div data-v-134c12b0="">
                        <h4 data-v-134c12b0="" className='p-0 m-0'>Total bet</h4>
                        <h5 data-v-134c12b0="">{(data['data'] && data['data'].totalAmount) ? convertCoin(data['data'].totalAmount) : " 0"}</h5>
                        </div>
                        <div data-v-134c12b0="">
                        <h4 data-v-134c12b0="">Number of bets</h4><h5 data-v-134c12b0="">{data['data'] && (data['data'].noOfBets ?? 0)}</h5></div><div data-v-134c12b0=""><h4 data-v-134c12b0="">Profit</h4><span data-v-134c12b0="">{data['data'] && data['data'].wonAmount ? convertCoin(data['data'].wonAmount): " 0" }</span></div></div></div></div></div></div>

    </>
}

function Tabs({ activeIndex, onClick }) {
    return <div data-v-caf22e0f="" className="subordinate__container-header pb-4">
        <div data-v-caf22e0f="" className="van-tabs van-tabs--card footer-tabBar">
            <div className="van-tabs__wrap"><div role="tablist" className="van-tabs__nav van-tabs__nav--card" aria-orientation="horizontal" >
                <div id="van-tabs-1-0" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 0 ? "van-tab--active" : ""}`} aria-selected="true" aria-controls="van-tab-1" onClick={() => onClick(0)}><span className="van-tab__text van-tab__text--ellipsis">Today</span></div>
                <div id="van-tabs-1-1" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 1 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-2" onClick={() => onClick(1)}><span className="van-tab__text van-tab__text--ellipsis">Yesterday</span></div>
                <div id="van-tabs-1-2" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 2 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-3" onClick={() => onClick(2)}><span className="van-tab__text van-tab__text--ellipsis">This Week</span></div>
                <div id="van-tabs-1-4" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 3 ? "van-tab--active" : ""}`} aria-selected="false" aria-controls="van-tab-4" onClick={() => onClick(3)}><span className="van-tab__text van-tab__text--ellipsis">This month</span></div>
            </div>
            </div>
            <div className="van-tabs__content"></div></div></div>
}