import { Navbar } from "react-bootstrap";
import { NavBar } from "../../widgets/navBar";
import { useLocation } from "react-router-dom";
import {decode as base64Decode, encode as base64Encode} from 'base-64';
import { useEffect } from "react";



export function CoinpaymentsPage(props) {

    const location = useLocation()

    var q = location.search.split('=')[1];
   
    var apiResponse = JSON.parse(base64Decode(q))


    function fetchResponse(){

    }

    useEffect(()=>{

    },[])

    return <>
    <NavBar title={"Cashout"}></NavBar>
        <div className="qr-field pt-5 text-center">
            <img style={{ width: "221px", height: "221px" }} src={apiResponse['qrcode_url']}>
            </img>
        </div>

        <div className="pay-block">
            <div className="w-row">
                <div className="w-col w-col-3">
                    <div className="sc2-label">Amount Requested</div>
                    <div className="text-center"><a href="litecointestnet:mvcPKbQo8VcrkzNUpK7f2q5add3d1RJaaQ?amount=2.81497000" className="cashout-amount">{apiResponse['amount']} {apiResponse['coin']}</a></div>
                </div>

                {/* <div className="w-col w-col-3 mt-2">
                    <div className="sc2-label">Deposit Amount</div>
                    <div><a href="litecointestnet:mvcPKbQo8VcrkzNUpK7f2q5add3d1RJaaQ?amount=2.81497000" className="cashout-amount">- INR</a></div>
                </div> */}
                
                <div className="w-col w-col-6 mt-1">
                    <div className="sc2-label mb-1">To Address</div>
                    <div className="address">{apiResponse['address']}</div>
                </div>

            </div>
            <div className="checkout-grey sct mt-1">Payment ID: {apiResponse['txn_id']}</div>
        </div>
    </>

}