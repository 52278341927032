import logo from '../images/logo.png'


export default function Footer() {
    return <footer className="mt-3 mb-4">
        <div className="footer-top">
            <span className="logo footer"><img src={logo} /></span>
        </div>
        
        <div className="footer-top mt-1">
            <div className="footer-column footer-column_center social-icons mt-1">
                <div className="darkblue-bg">
                    <div className="footer-icon_monocolor">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20" className="Icon_icon__2Th0s"><path d="M10.603 10H6.687v9.8H2.174V10H0V6.794h2.175V4.712C2.174 1.882 3.485.2 7.06.2h4.12v3.469H7.814c-.999-.002-1.122.484-1.122 1.389l-.006 1.736H11.2z"></path></svg>
                    </div>
                    <div className="footer-icon_monocolor ">
                        <svg viewBox="0 -31 512 512" xmlns="http://www.w3.org/2000/svg" className="Icon_icon__2Th0s"><path d="M123.195 260.738l63.68 159.188 82.902-82.903L411.918 450 512 0 0 213.266zm242.5-131.629L208.98 272.051l-19.52 73.566-36.058-90.164zm0 0"></path></svg>
                    </div>

                    <div className="footer-icon_monocolor">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 169" className="Icon_icon__2Th0s"><path d="M122.406 0H46.654C20.929 0 0 20.93 0 46.655v75.752c0 25.726 20.929 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.063 20.93 148.133 0 122.406 0zm31.657 122.407c0 17.455-14.201 31.655-31.656 31.655H46.654C29.2 154.063 15 139.862 15 122.407V46.655C15 29.201 29.2 15 46.654 15h75.752c17.455 0 31.656 14.201 31.656 31.655v75.752z"></path><path d="M84.531 40.97c-24.021 0-43.563 19.542-43.563 43.563 0 24.02 19.542 43.561 43.563 43.561s43.563-19.541 43.563-43.561c0-24.021-19.542-43.563-43.563-43.563zm0 72.123c-15.749 0-28.563-12.812-28.563-28.561 0-15.75 12.813-28.563 28.563-28.563s28.563 12.813 28.563 28.563c0 15.749-12.814 28.561-28.563 28.561zm45.39-84.842c-2.89 0-5.729 1.17-7.77 3.22a11.053 11.053 0 00-3.23 7.78c0 2.891 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z"></path></svg>
                    </div>
                    <div className="footer-icon_monocolor">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="Icon_icon__2Th0s"><path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"></path></svg>
                    </div>
                </div>
            </div>
           
            <div className="footer-column footer-column_right">
                <span className="age_limit">18+</span>
            </div>
        </div>
        <div className="footer-top justify-content-center mt-3">
            <div className="footer-column d-flex justify-content-center">
                <nav className="ftr-nav">
                    <a href="about_us">About us</a>
                    <a href="rules">The rules</a>
                    <a href="contact_us">Contacts</a>
                    <a href="#">Mobile version</a>
                    <a href="#">Affiliate program</a>
                    <a href="#">FAQ</a>
                </nav>
            </div>
        </div>
        
        <div className="footer-bottom  justify-content-center">
            <div className="footer-column footer-column_left antillephone">
                <div className="footer__antillephone-license d-flex align-items-center">

                    <p className="ftr-txt"></p>
                </div>
            </div>
        </div>
    </footer>
}