

import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import pagebanner from "../../images/game-bnr.jpg";
import { toast } from 'react-toastify';
import { makeSubmitRequest } from '../../api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { ContryCodesSheet } from '../../models/countryCodesSheet';
export default function SignupPage() {

    const [showPassword, setShowPassword] = useState(false)
    const [countrySheet, setShowCountrySheet] = useState(false)
    const [countryCode, setCountryCode] = useState("+91")

    const togglePasswordEye = () => {
        setShowPassword(!showPassword)
    }


    const location = useLocation()
    var inviteCode = location.search.split("=")[1];

    const navigate = useNavigate()
    const handleCloseLocal = () => {
        setLoading(false)
        setOTPSent(false)
        setData({})

    }

    const [otpSent, setOTPSent] = useState(false)
    const [loading, setLoading] = useState(false)

    const [termsChecked, setTermsChecked] = useState(false)


    const [data, setData] = useState({ referredBy: inviteCode })


    const [otp, setOTP] = useState();

    const signUP = (e) => {
        e.preventDefault();

        if(!termsChecked) return toast.error('Please accept terms and conditions first!')
        setLoading(true);

        if (!otpSent) {
            const body = {}
            if(data['phone']){
                body['phone'] = countryCode + data['phone']
            }else {
                body['email'] = data['email']
            }
            makeSubmitRequest("users/send-signup-otp", body)
                .then((data) => {
                    console.log("response ", data);
                    setLoading(false);
                    setOTPSent(true);
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e.message);
                });
        } else {

            const userState= {...data}

            if(userState['phone']){
                userState['phone'] = countryCode + userState['phone']
            }
            makeSubmitRequest("users/signup", { user: userState, otp: otp })
                .then(() => {
                    setLoading(false);
                    toast.success("Sign up successfully");
                    navigate("/login");
                    handleCloseLocal();
                })
                .catch((e) => {
                    setLoading(false);
                    toast.error(e.message);
                });
        }
    };
    const onInputChange = (e) => {
        //e.stopPropegatioin()

        if (e.target.name == "email") {
            let value = e.target.value;
            setData({ ...data, [e.target.name]: value });
        } else {
            setData({ ...data, [e.target.name]: e.target.value });
        }
    };

    const onChangeOTP = (e) => {
        setOTP(e.target.value);
    };
    const [toggle, setToggle] = useState(2)
    function updateToggle(id) {
        setOTPSent(false)
        setData((oldState) => {
            const newState = { ...oldState, email: undefined, phone: undefined }
            setOTP(undefined)
            return newState;
        })
        setToggle(id)
    }


    return (
        <>
            <ContryCodesSheet open={countrySheet} onClose={() => setShowCountrySheet(false)} onSelectCountry={(code) => {
                setCountryCode(code)
                setShowCountrySheet(false)
            }}> </ContryCodesSheet>



            <div className="text-white">
                <div className="regi_bnr">
                    <h6 className="fw-bold mb-2 text-uppercase text-center pt-2">Welcome To <span style={{ color: '#00ff66' }}> VpGames </span></h6>
                    <p>START YOUR GAME JOURNEY NOW</p>
                </div>
                <h4 className="fw-bold mb-2 text-uppercase text-center pt-2 fs-25">Sign Up</h4>
                {/* <h6 className="mb-3 f-20 text-center">Please enter details to sign up!</h6> */}
                <div>
                    <ul className="d-flex m-0 tab-btn-design">
                        <li className={toggle == 1 ? "active" : ""} onClick={() => updateToggle(1)}>Phone</li>
                        <li className={toggle == 2 ? "active" : ""} onClick={() => updateToggle(2)}>Email</li>
                    </ul>
                    <Form onSubmit={signUP}>
                        <Form.Group className="form-group mb-2">
                            {/* <Form.Label className="form-label mrbl">Full name</Form.Label> */}
                            <Form.Control
                                disabled={otpSent || loading}
                                type="text"
                                placeholder="Full Name"
                                bsPrefix="form-control system_input"
                                required
                                minLength={3}
                                maxLength={30}
                                defaultValue={data["name"]}
                                className="app-input LS-input"
                                name="name"
                                onChange={onInputChange}
                            />
                        </Form.Group>
                        {toggle == 1 && <div style={{ position: 'relative' }} className='mb-2'>
                            <Form.Group className="form-group" controlId="formBasicEmail">
                                {/* <Form.Label className="form-label mrbl">Phone number</Form.Label> */}
                                <span className="county_code" onClick={() => setShowCountrySheet(true)}>{countryCode}<FaAngleDown /></span>
                                <Form.Control
                                    type="text"
                                    disabled={otpSent || loading}
                                    placeholder="Enter Phone Number"
                                    bsPrefix="form-control system_input"
                                    required
                                    minLength={10}
                                    maxLength={10}
                                    name="phone"
                                    className="app-input LS-input pl_40"
                                    onChange={onInputChange}
                                />
                            </Form.Group>
                        </div>}

                        {toggle == 2 && <div style={{ position: 'relative' }} className='mb-2'>
                            <Form.Group className="form-group">

                                <Form.Control
                                    className="app-input LS-input"
                                    required
                                    name="email"
                                    type="email"
                                    placeholder="Enter Email"
                                    bsPrefix="form-control system_input"
                                    onChange={onInputChange}
                                    id="basic-addon1"

                                />
                            </Form.Group>
                        </div>}
                        <Form.Group className="form-group mb-2" controlId="promocode">
                            <Form.Control
                                type="text"
                                disabled={otpSent || loading || inviteCode}
                                defaultValue={data["referredBy"]}
                                name="referredBy"
                                className="app-input LS-input"
                                placeholder="Referral Code"
                                bsPrefix="form-control system_input"
                                onChange={onInputChange}
                            />
                        </Form.Group>
                        <div style={{ position: 'relative' }}>
                            <Form.Group className="form-group mb-2" controlId="formBasicPassword">
                                {/* <Form.Label className="form-label mrbl">Password</Form.Label> */}
                                <Form.Control
                                    className="app-input LS-input"
                                    type={showPassword ? "text" : "password"}
                                    disabled={otpSent || loading}
                                    defaultValue={data["password"]}
                                    name="password"
                                    placeholder="Password"
                                    bsPrefix="form-control system_input"
                                    required
                                    minLength={6}
                                    maxLength={50}
                                    onChange={onInputChange}
                                />
                            </Form.Group>

                            <span onClick={togglePasswordEye} className='icon_hide_show'>
                                {showPassword ? <FaEye></FaEye> : <FaEyeSlash />}
                            </span>

                        </div>

                        {otpSent && <Form.Group
                            hidden={!otpSent}
                            className="form-group mb-2"
                            controlId="otpControlId"
                            disabled={loading}
                        >

                            <Form.Control
                                className="app-input LS-input"
                                type="text"
                                pattern="\d*"
                                maxLength={6}
                                placeholder="6-digit OTP"
                                bsPrefix="form-control system_input"
                                onChange={onChangeOTP}
                            />
                        </Form.Group>}

                        <Form.Group
                            onClick={() => {
                                setTermsChecked(!termsChecked)
                            }} className="form-group">
                            <Form.Check
                                checked={termsChecked}
                                onChange={() => setTermsChecked(!termsChecked)}
                                label={`I Agree to the Confirm I am at least 18 Years old`}
                            />
                        </Form.Group>



                        <div className="d-grid">
                            <Button
                                disabled={loading}
                                variant="primary"
                                type="submit"
                                className="system_button app-button LSbtn"
                            >
                                {!otpSent ? "Send OTP" : "Verify OTP"}
                            </Button>
                        </div>
                    </Form>


                    <div className="mt-2 mb-3">
                        <p className="mb-0  text-center">
                            <b> Already have account? </b>
                            <a
                                onClick={() => {
                                    navigate("/login");
                                }}
                                disabled={loading}
                                className="text-primary fw-bold cursor-pointer text-decoration-none "
                            >
                                <b style={{ color: "#00ff66" }}> Log in</b>
                            </a>
                        </p>
                    </div>

                    <div style={{ height: "10px" }}></div>
                </div>
            </div>
        </>
    );
}




