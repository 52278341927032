import { useNavigate } from "react-router-dom"
import { NavBar } from "../../widgets/navBar"
import logo from '../../images/logo.png'
import activityimg1 from '../../images/activity-img-01.png'
import activityimg2 from '../../images/activity-img-02.png'
export default function ActivityPage() {

    const navigate = useNavigate();
    return <div>

        <div className="activity-navbar">
           <img src={logo} style={{marginLeft:'17px',marginTop:'2px'}} />
        </div>

        <div className="activity-wrapper" data-v-30ca6441 data-v-54c44f11>
            <div data-v-30ca6441="" className="activity-banner">
                <div data-v-30ca6441=""><div data-v-30ca6441="" className="banner-title mt-2"><b> Activity</b></div>
                    <div data-v-30ca6441="" className="banner-para">Please remember to follow the event page</div>
                    <div data-v-30ca6441="" className="banner-para">We will launch user feedback activities from time to time</div>
                </div>
            </div>


            <div data-v-30ca6441="" className="activity-panel-content">
                <div data-v-30ca6441="" onClick={()=>navigate('/missionBonus')} className="content-title" ><img data-v-30ca6441="" className="" data-origin={activityimg1} src={activityimg1} />
                <div data-v-30ca6441="" className="content-para">Invitation Bonus</div><p data-v-30ca6441="">Invitation Bonus</p></div>
                <div data-v-30ca6441="" className="content-title" onClick={()=>navigate('/attendence')}><img data-v-30ca6441="" className="" data-origin={activityimg2} src={activityimg2} />
                    <div data-v-30ca6441="" className="content-para">Daily CheckIn bonus</div><p data-v-30ca6441="">The more consecutive days you sign in, the higher the reward will be.</p></div>
            </div>

        </div>





    </div>
}