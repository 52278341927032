import { useContext, useEffect } from 'react'
import logo from '../../images/logo.png'
import { CurrencyContext } from '../../context/currencyContext'
import { useNavigate } from 'react-router-dom'


export function SplashPage(){
    const navigate = useNavigate()
    const {fetchDefaultCurrency,syncCurrencyRates,currencyRates,currencyCode} = useContext(CurrencyContext)



    useEffect(()=>{
        syncCurrencyRates()
    },[])

    
    useEffect(()=>{
        console.log('currency Rate here!... ', currencyRates)
        if(currencyRates){
            navigate("/")
        }
    },[currencyRates])

    return <div className="splashContainer">
        <img src={logo} className='splashLogo'></img>
    </div>
}