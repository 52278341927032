import { useContext, useState } from "react";
import { AppTab } from "./app_tab";

import { GrAdd } from "react-icons/bs";
import { MdAdd, MdSubscript, MdRemove, MdClose, MdArrowBackIos, MdArrowForwardIos } from "react-icons/md"
import AviatorContext from "../context/aviator_context";
import { Col, Row } from "react-bootstrap";

export function BetControl({ main }) {


    const [betAmount, setBetAmount] = useState(10)
    const [autoCashoutX, setAutoCashoutX] = useState(100)



    const { placeBet, cancelBet, cashOutBet, actionLoading, currentGame, bet, isGameInLobby, isGameRunning,multipler } = useContext(AviatorContext)

    const [autoTabIndex, setShowAutoTabIndex] = useState(0)


    const betStatus = bet!=undefined  ? bet['status'] : ""
    const gameStatus = currentGame ? currentGame['status'] : ""



    const onInputChange = (e) => {
        e.preventDefault()
        setBetAmount(e.target.value)
    }


    const onOutBetXChange = (e) => {
        e.preventDefault()
        setAutoCashoutX(e.target.value)
    }



    ///cancel, success
    return <>
        <div className="bet-control" >
            <button className={`bet-button ${(betStatus == 'lobby' || (betStatus == "pending" && gameStatus== 'lobby')) ? "cancel" : "success"   }`} 
            disabled={actionLoading || (gameStatus == "lobby" && betStatus == 'pending' )} onClick={() => {
                
                betStatus == 'lobby' ? cancelBet() : betStatus =='pending' ? cashOutBet() : placeBet(betAmount)
            }}>
                <>

                {
                    betStatus == ""  && "Place Bet"
                }
                {
                    (betStatus == "lobby" || (betStatus=="pending" && (gameStatus =="lobby" || gameStatus['crashed']))) && "Cancel"
                }

                {
                    (betStatus == "pending" && gameStatus == "running") && "Cashout"
                }
               
                <span className="bet-button-sub-text">
                    {betStatus=='pending' && gameStatus == "running"  ?  "₹ " + parseFloat((bet.amount *multipler).toFixed(2)) :"(Next Round)" }
                </span>
                </>
            </button>

            <div style={{ width: "100%"}} className="mt-2">
                    <div className="ms-1 me-1">
                        <>
                            <span className="input-label">Amount</span>
                            <div className="input-wrapper">
                                <input onChange={onInputChange} value={betAmount} type="number" className="main_bet_amount" placeholder="Enter amount" disabled={bet} />
                                <div className="qty-buttons">
                                    <button className="minus " disabled={bet || actionLoading} id="main_minus_btn" onClick={() => setBetAmount(parseInt(betAmount) - 1 < 10 ? 10 : parseInt(betAmount) - 1)}>
                                        <span className="material-symbols-outlined">
                                            <MdRemove size={18} />
                                        </span>
                                    </button>
                                    <button className="plus" disabled={bet || actionLoading} id="main_plus_btn" onClick={() => setBetAmount(parseInt(betAmount) + 1)}>
                                        <span className="material-symbols-outlined">
                                            <MdAdd size={18} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="qty-button-container">
                                <button className="btn-amount-easy" onClick={() => setBetAmount(parseInt(betAmount) + 100)} disabled={bet || actionLoading}><span className="amt">100</span><span className="currency">₹</span></button>
                                <button className="btn-amount-easy" onClick={() => setBetAmount(parseInt(betAmount) + 200)} disabled={bet || actionLoading}><span className="amt">200</span><span className="currency">₹</span></button>
                                <button className="btn-amount-easy" onClick={() => setBetAmount(parseInt(betAmount) + 500)} disabled={bet || actionLoading}><span className="amt">500</span><span className="currency">₹</span></button>
                                <button className="btn-amount-easy" onClick={() => setBetAmount(parseInt(betAmount) + 1000)} disabled={bet || actionLoading}><span className="amt">1000</span><span className="currency">₹</span></button>
                            </div>
                        </>
                    </div>
                    <div md={12}  className="ms-1 me-1 mt-2">
                        <span className="input-label">Auto Cashout</span>
                        <div className="input-wrapper">
                            <input onChange={onOutBetXChange} value={autoCashoutX} type="number" className="main_bet_amount" placeholder="Enter cashout x" disabled={bet} />
                            <div className="qty-buttons">
                                <button className="minus " disabled={bet || actionLoading} id="main_minus_btn" onClick={() => setAutoCashoutX(autoCashoutX - 1 < 10 ? 10 : autoCashoutX - 1)}>
                                    <span className="material-symbols-outlined">
                                        <MdArrowBackIos size={16} />
                                    </span>
                                </button>
                                <button className="plus" disabled={bet || actionLoading} id="main_plus_btn" onClick={() => setAutoCashoutX(autoCashoutX + 1)}>
                                    <span className="material-symbols-outlined">
                                        <MdArrowForwardIos size={16} />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </>
}






// bet && (bet['status'] == 'pending' || bet['status'] == 'joined') ? "controls bet-border-red" : "controls"




//check_status
//place_all_bets   -> To place the bet

//new_game_generated
//lets_fly_one
//lets_fly
//incrementor

//new_bets_arrived
//update_newly_arrived_bet

//cash_out_bet
//user_add_bet_response
//user_cash_out_main_response
//user_cash_out_extra_response
//crash_plane


{/* <div className="buttons-block" id="bet_button" hidden={bet} >
                        <button className="btn btn-success bet font-family-title ng-star-inserted main_bet_btn" onClick={() => { placeBet(betAmount) }} id="main_bet_now"><label className="font-family-title label bet_text_family">BET</label></button>
                    </div>
                    <div className="buttons-block" id="cancle_button" hidden={!(bet && (bet['status'] == 'pending' || bet['status'] == 'joined'))} >
                        <div className="btn-tooltip f-14 mb-1" id="waiting" >Waiting for next round</div>
                        <button className="btn btn-danger bet font-family-title height-70 ng-star-inserted main_bet_btn" onClick={()=>cancelBet(main?"main":'extra')} id="main_cancel_now"><label className="font-family-title label bet_text_family">CANCEL</label></button>
                    </div>
                    <div className="buttons-block" id="cashout_button" hidden={!(bet && bet['status'] == 'placed')}>
                        <input type="hidden" name="main_bet_id" id="main_bet_id" />
                        <button className="btn btn-warning bet font-family-title ng-star-inserted" onClick={()=>cashOutBet(main ?'main' : 'extra')}>
                            <label className="font-family-title label bet_text_family">CASH OUT</label>
                            <div className="font-family-title label bet_text_family" id="cash_out_amount">{bet && bet['status'] == 'placed' && (currentGame['incremented'] * betAmount).toFixed(2)}</div>
                        </button>
                    </div> */}
