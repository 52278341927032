import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

import Footer from "../widgets/footer";
import { AccountContext } from "../context/account_context";
import Header from "../widgets/header";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import games from '../games.json'
import { Home } from "../pages/Home";
import PromotionPage from "../pages/promotion";
import Wallet from "../pages/Wallet";
import Profile from "../pages/profile";
import ActivityPage from "../pages/activity";
import { CurrencyContext } from "../context/currencyContext";
import { useIntl } from "react-intl";
import { Navigate } from 'react-router-dom';

export default function Dashboard(props) {
    const {syncCurrencyRates} = useContext(CurrencyContext)
    useEffect(()=>{
        syncCurrencyRates()
    },[])

    const navigate = useNavigate();

    const params = useParams()
    const tabId =params['tabId']
    

    const [activeTab, setActiveTab] = useState(0);

    const [showLoginDialog, setShowLoginDialog] = useState(false)

    const [showSignupDialog, setShowSignupDialog] = useState(false)
    const [showForgotDialog, setShowForgotDialog] = useState(false)

    const { account, signOut } = useContext(AccountContext)

    const location = useLocation()
    
 

    return <>


        {tabId == "home" && <Home />}
        {tabId == "activity" && <ActivityPage />}
        {tabId == "promotion" && <PromotionPage />}
        {tabId == "wallet" && <Wallet />}
        {tabId == "profile" && <Profile />}

        <BottomTab activeTab={tabId} onTabSelect={(tab) => {
            setActiveTab(tab)
        }} />

    </>
}



function BottomTab({ activeTab, onTabSelect }) {
    const navigate = useNavigate();
    return <div data-v-df3dcb90="" className="tabbar__container">
        <div data-v-df3dcb90="" onClick={() => navigate('/dashboard/home')}
            className={`tabbar__container-item ${activeTab == 'home' ? "active" : ""}`}> <div data-v-df3dcb90="" className="home">Home</div></div>
        <div data-v-df3dcb90="" onClick={() => navigate('/dashboard/activity')}
            className={`tabbar__container-item  ${activeTab == 'activity' ? "active" : ""}`}><div data-v-df3dcb90="" className="activity">Activity</div> </div>
        <div data-v-df3dcb90="" onClick={() => navigate('/dashboard/promotion')}
            className={`tabbar__container-item  ${activeTab == 'promotion' ? "active" : ""}`}><div data-v-df3dcb90="" className="promotion">Promotion</div></div>
        <div data-v-df3dcb90="" onClick={() => navigate('/dashboard/wallet')}
            className={`tabbar__container-item  ${activeTab == 'wallet' ? "active" : ""}`}><div data-v-df3dcb90="" className="wallet">Wallet</div></div>
        <div data-v-df3dcb90="" onClick={() => navigate('/dashboard/profile')}
            className={`tabbar__container-item  ${activeTab == 'profile' ? "active" : ""}`}><div data-v-df3dcb90="" className="main">Account</div></div>
    </div>
}

