import { useContext, useEffect, useState } from "react"
import { NavBar } from "../../widgets/navBar"
import { getRequest } from "../../api/api"
import { Spinner } from "react-bootstrap";
import { FaAngleDown } from 'react-icons/fa'
import { DatePickerBottomSheet } from "../../widgets/datePickerBottomSheet";
import { LevelPickerBottomSheet } from "../../widgets/levelPickerBottomSheet";
import moment from 'moment'
import { EmptyWidget } from "../../widgets/empty_widget";
import { LoadingPage } from "../../widgets/loading_page";
import icon7 from'../../images/icon7.png'
import { CurrencyContext } from "../../context/currencyContext";


export const Subordinate = () => {
    const {convertCoin} = useContext(CurrencyContext)

    const todayDate = new Date()
    todayDate.setDate(todayDate.getDate() - 1)

    const maximumDate = {
        day: todayDate.getDate(),
        month: todayDate.getMonth() + 1,
        year: todayDate.getFullYear()
    }

    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })

    const [uidSearch, setUIDSearch] = useState()
    const [dateSheet, showDateSheet] = useState(false)
    const [levelSheet, showLevelSheet] = useState(false)
    const [selectedDate, setSelectedDate] = useState(maximumDate)
    const [selectedLevel, setSelectedLevel] = useState()


    const fetch = async (uid) => {
        try {
            let endpoint = "commision/subordinate?";

            endpoint += "date=" + `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;
            if (uid) endpoint += `&uid=${uid}`
            if (selectedLevel) endpoint += `&level=${selectedLevel}`


            const subordinate = await getRequest(endpoint)
            setData({ data: subordinate, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }

    useEffect(() => {

    })
    useEffect(() => {
        fetch()
    }, [selectedDate, selectedLevel])


    if (data['loading']) return <div className="h-100 d-flex flex-column align-items-center justify-content-center">
        <Spinner size="sm" className="page-loading-spinner"></Spinner>
    </div>

    if (data['error']) return <div> ERROR</div>




    return <>
        <DatePickerBottomSheet
            selectedDate={selectedDate}
            maximumDate={maximumDate}
            onDateSelect={(date) => {
                console.log(date)
                setSelectedDate(date)
                showDateSheet(false)
            }}
            open={dateSheet} onClose={() => {
                showDateSheet(false)
            }} />

        <LevelPickerBottomSheet
            open={levelSheet}
            selectedLevel={selectedLevel}
            onLevelSelect={(item) => {
                setSelectedLevel(item)
                showLevelSheet(false)
            }}
            onClose={() => {
                showLevelSheet(false)
            }}

        />
        <NavBar title={"Suborinate"} />

        
        {data.data.length == 0 &&<div className="h-100 d-flex  flex-column justify-content-center align-items-center"> <EmptyWidget /></div>}
        {
            data.loading && <LoadingPage/>
        }
        <div data-v-f29e8dc2="" className="TeamReport__C-head">
            <div data-v-f29e8dc2="" className="TeamReport__C-head-fixed">
                <div data-v-98a792e3="" data-v-f29e8dc2="" className="searchbar-container"><input data-v-98a792e3="" type="text" autoComplete="off" className="searchbar-container__searchbar" placeholder="Search subordinate UID" maxLength={30} onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setUIDSearch(e.target.value)
                }} />
                    <img data-v-98a792e3="" className="searchIcon" onClick={() => { fetch(uidSearch) }} src={icon7} />
                    <img data-v-98a792e3="" hidden={true} className="clearIcon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAANlBMVEUAAABlZWVlZWVlZWVmZmZmZmZlZWVgYGBmZmZkZGRnZ2dmZmZmZmZmZmZkZGRlZWVwcHBmZmbpTDmrAAAAEXRSTlMAXyDvv99wEM9An4CvkFAwEIs/ESUAAALKSURBVGje7VnpztsgEORaMIev93/Z1gkqUhczJriuPinzKwE8w86CzSG++OIxKBmDIdp/Q5NJcVlvJHc26Z1BJ6nuYTf7Kcywhg96byOoEXpTDDGzXNSLbFu9nY0el1DpjxPW8+rVmjGJmPtIcTsfWpQlYn/3p9x532635DCoMwirCz2Ap3eSbA//3PWMpE6b3DvstF32M7zjdT32a9tv6aQu8RNI2uBTbmr0BcY9YZfMq92J/bhnBk4vwA8VZpAsxI8VbNNIjTKFM61bj/MGHLCDE0iAFQOwzSmtjtoghpBaHgSYAAx3mJQaAUiBgU3y5wGIYZiz6aZq0lITiwnU+7MQQk2Z2q7JI6W1EMJJAMvfpbqZF7lnV1kI2lUbU6WUKeBKqs4mk9tCElwVs9ncIQVoLkq7o8JVmhtgxGXzTMWjVMowFS/kky1VhssqgALkz/B89K6lCCnwAg7NErqUFGAFzo+HZMxfU6xQ/oCVYazlGCuUn6h1YDF5cUEB89cdpzyIsALgL9OWWNqduKJQ5ccCx1MCQWJ+zscLsIIUP1VgxCJic3soyY69eYAAHqb81UZsoi33TTTPJloAz+BXBf8gPPuyk8Ova+C4uv+Ds/IiP/DJ5N/Hpz/69u5liwSLpf6FF1/EcdU4sHSEQ9Leu/jlpa46jggu3/noXopDeAOi8QaEGhsQvPWRpBk/r+c8CuzeRkAlgJq0HeWXJQCGxDZXH56HhNZRRhoTCDmAZw5DOKY7jnPogQMpdKTmxo7UmpiBAuSPqJkBCoDf/OuD2e3/Hy2PH45jONN5M+PSfsBwf5pTeifZdV0yf3DFQv7yXZi2/Uk7YBZM37Af2JSNUqfex9wNHcUHUKFc1K2VvpeLugS6jyTyVaP1q3uVqkWWq0Z0F4Yl2tDBD99Ut6577XbPbXj9wpqxj2BdYjL00iEyIUolvvjiKfwCFvRUSxPTUJMAAAAASUVORK5CYII=" />
                </div>
                <div data-v-f29e8dc2="" className="TeamReport__C-head-line2">
                    <div onClick={() => {
                        showLevelSheet(true)
                    }} data-v-f29e8dc2=""><span data-v-f29e8dc2="" className="default">{
                        !selectedLevel ? "All" : "Tier " + selectedLevel
                    }</span><i data-v-f29e8dc2="" className="van-badge__wrapper van-icon van-icon-arrow-down">
                            <FaAngleDown></FaAngleDown></i></div>
                    <div data-v-f29e8dc2="" onClick={() => {
                        showDateSheet(true)
                    }}><span data-v-f29e8dc2="" className="default">{selectedDate && (selectedDate['year'] + "-" + selectedDate['month'] + "-" + selectedDate['day'])}</span><i data-v-f29e8dc2="" className="van-badge__wrapper van-icon van-icon-arrow-down">
                            <FaAngleDown></FaAngleDown></i></div>
                </div>
            </div>
        </div>
        <div style={{ height: '60px' }}></div>
        {
            data.data.map(item => {
                return <Item item={item} />
            })
        }


    </>
}


function Item({ item }) {
    const {convertCoin} = useContext(CurrencyContext)

    return <div data-v-f29e8dc2="" key={item['_id']} className="TeamReport__C-body-item mt-2"><div data-v-f29e8dc2="" className="TeamReport__C-body-item-head">
        <div data-v-f29e8dc2="" className="title">UID:{item['accountId']}</div><img data-v-f29e8dc2="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAABlZWVnZ2dlZWVmZmZgYGBlZWVkZGRgYGBmZmZoaGhmZmZmZmZlZWVkZGRmZmZwcHBmZmYn3H9lAAAAEXRSTlMAYJ/v3xC/QCCAIK+QcFDPEFwncHAAAADVSURBVFjD7dY7EsMgDEVRia//ydv/ZhOlyYxHChq7SaHTAhdsGiio6sEjvZJpanDIs7k+w+dhHKLASz8Dy1BLA6tsk9UjbAASDVX50E6KBIBJoc2LwGnesSVRft0iT2ZgKvDIux6Q9U67HljhlasWmGQo8cie5QhaoMufo7FZ9tEC/BkY4whEIAIRiMB/BhqAfinQWCS8LZcCXyvdC5TlVkDWXwuUJLaDxNVbEBE46crb23ocbubbu6xpoMF8hc7wKqSqD/jkhQxzhkNbyPTsPHI8KWheb7c1z9Bcc9kAAAAASUVORK5CYII=" /></div><div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail">
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-lv">Level<span data-v-f29e8dc2="">{item['level']}</span></div>
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-commission">Deposit amount<span data-v-f29e8dc2="">{convertCoin(item['depositAmount']??0)}</span></div>
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-commission">Bet amount<span data-v-f29e8dc2="">{convertCoin(item['betAmount'] ?? 0)}</span></div>
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-commission">Commission<span data-v-f29e8dc2="">{convertCoin(item['commisionAmount'] ?? 0) }</span></div><div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-time">Time<span data-v-f29e8dc2="">{moment(item.createdAt).format('DD MMM YYYY')}</span></div>
        </div>
    </div>
}