
import FreshChat from 'react-freshchat'


export function Support() {

    console.log('support !')
    return <div>
        <FreshChat
            token={"ec7f61b2a50bed5833fbdf6a5a1f3948"}
            onInit={widget => {
                widget.user.setProperties({
                    email: "email@test.com",
                    first_name: "Test user",

                })

            }}
        />
    </div>
}