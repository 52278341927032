
import React, { useContext, useEffect, useState } from 'react'
import { getRequest } from '../api/api'
import fx from 'money'
import axios from 'axios'
import currency from 'currency.js'



export const CurrencyContext = React.createContext()

const supportedCurries = [
    {
        code : "INR",
        symbol : "₹",
    },
    {
        code : "USD",
        symbol : "$",
    },
    {
        code : "PKR",
        symbol : "Rs",
    },   

    {
        code : "EUR",
        symbol : "€"
    },
    {
        code : "AED",
        symbol : "AED"
    }
]

const defaultCurrency = {
    code : "USD",
    symbol : "$",
}

export const getCurrenyFromCode = (code)=>{
    const currency = supportedCurries.find(e=>e['code']==code)
    return currency ??defaultCurrency; 
}

export function CurrencyContextProvider({children}){

    const [currentCurrency,setCurrentCurrency] = useState(localStorage.getItem('currentCurrency') ? JSON.parse(localStorage.getItem('currentCurrency')) : defaultCurrency)
    const [currencyRates,setRates] = useState(localStorage.getItem('rates') ? JSON.parse(localStorage.getItem('rates')) : undefined)

    const rates = localStorage.getItem('rates')
    fx.base = "USD"
    if(rates!=undefined){
        fx.rates = JSON.parse(rates);
    }


    function fetchDefaultCurrency(){
        axios.get("https://ipapi.co/json/").then((res)=>{
            const responseData = res.data;
            const currency = responseData['currency']
            var _currentCurrency = getCurrenyFromCode(currency);
            localStorage.setItem('currentCurrency', JSON.stringify(_currentCurrency))
            setCurrentCurrency(_currentCurrency)
        }).catch(e=>{
            debugger;
        })
        
    }


    const syncCurrencyRates = async ()=>{
        console.log('fetching currency rate...')
        getRequest('currecyRates')
        .then(res=>{
            localStorage.setItem('rates',JSON.stringify(res))
            localStorage.setItem('lastSyncRates', new Date().getTime())
            fx.rates = res;
            setRates(res)
        }).catch(e=>{

        })
    }



 const convertCoin = (amount)=>{
    var convertedAmount =  fx(amount).convert({from:"USD",to:currentCurrency['code']})
    return currency(convertedAmount).format({symbol:currentCurrency['symbol']})
}


 const convertCoinWithoutFormat = (amount)=>{
    var convertedAmount =  fx(amount).convert({from:"USD",to:currentCurrency['code']})
    return currency(convertedAmount).value;
}




    useEffect(()=>{
        syncCurrencyRates()
        fetchDefaultCurrency();
    },[])

    return <CurrencyContext.Provider value={{syncCurrencyRates,currency,currencyRates,fetchDefaultCurrency,syncCurrencyRates,convertCoin,convertCoinWithoutFormat,currentCurrency}}>
        {children}
    </CurrencyContext.Provider>

}


// {
//     "USD": "1",
//     "AED": "3.6725",
//     "AFN": "70.0115",
//     "ALL": "94.7137",
//     "AMD": "402.935",
//     "ANG": "1.79",
//     "AOA": "840.0098",
//     "ARS": "364.41",
//     "AUD": "1.5189",
//     "AWG": "1.79",
//     "AZN": "1.7001",
//     "BAM": "1.8178",
//     "BBD": "2",
//     "BDT": "109.8213",
//     "BGN": "1.8175",
//     "BHD": "0.376",
//     "BIF": "2836.7786",
//     "BMD": "1",
//     "BND": "1.3404",
//     "BOB": "6.8929",
//     "BRL": "4.9109",
//     "BSD": "1",
//     "BTN": "83.434",
//     "BWP": "13.6002",
//     "BYN": "3.2525",
//     "BZD": "2",
//     "CAD": "1.3584",
//     "CDF": "2549.7019",
//     "CHF": "0.8791",
//     "CLP": "873.3936",
//     "CNY": "7.171",
//     "COP": "4016.623",
//     "CRC": "526.0556",
//     "CUP": "24",
//     "CVE": "102.4838",
//     "CZK": "22.5967",
//     "DJF": "177.721",
//     "DKK": "6.925",
//     "DOP": "56.606",
//     "DZD": "134.4237",
//     "EGP": "30.921",
//     "ERN": "15",
//     "ETB": "56.2091",
//     "EUR": "0.929",
//     "FJD": "2.2389",
//     "FKP": "0.7966",
//     "FOK": "6.9249",
//     "GBP": "0.7969",
//     "GEL": "2.6678",
//     "GGP": "0.7966",
//     "GHS": "12.1643",
//     "GIP": "0.7966",
//     "GMD": "65.2862",
//     "GNF": "8585.0929",
//     "GTQ": "7.7925",
//     "GYD": "208.8782",
//     "HKD": "7.8104",
//     "HNL": "24.5549",
//     "HRK": "7.0028",
//     "HTG": "132.1877",
//     "HUF": "354.3025",
//     "IDR": "15515.7784",
//     "ILS": "3.7038",
//     "IMP": "0.7966",
//     "INR": "83.434",
//     "IQD": "1308.863",
//     "IRR": "41975.766",
//     "ISK": "139.2395",
//     "JEP": "0.7966",
//     "JMD": "155.5623",
//     "JOD": "0.709",
//     "JPY": "144.7538",
//     "KES": "153.9312",
//     "KGS": "89.2604",
//     "KHR": "4105.3341",
//     "KID": "1.5187",
//     "KMF": "457.2505",
//     "KRW": "1315.2162",
//     "KWD": "0.3072",
//     "KYD": "0.8333",
//     "KZT": "459.1522",
//     "LAK": "20539.6207",
//     "LBP": "15000",
//     "LKR": "325.7959",
//     "LRD": "190.803",
//     "LSL": "18.9214",
//     "LYD": "4.8194",
//     "MAD": "10.131",
//     "MDL": "17.7448",
//     "MGA": "4562.4164",
//     "MKD": "57.0546",
//     "MMK": "2088.6345",
//     "MNT": "3466.8929",
//     "MOP": "8.0447",
//     "MRU": "39.5442",
//     "MUR": "44.0093",
//     "MVR": "15.4361",
//     "MWK": "1693.4903",
//     "MXN": "17.3773",
//     "MYR": "4.6697",
//     "MZN": "63.8615",
//     "NAD": "18.9214",
//     "NGN": "825.9903",
//     "NIO": "36.4208",
//     "NOK": "10.8996",
//     "NPR": "133.4943",
//     "NZD": "1.6316",
//     "OMR": "0.3845",
//     "PAB": "1",
//     "PEN": "3.747",
//     "PGK": "3.7045",
//     "PHP": "55.4744",
//     "PKR": "284.0927",
//     "PLN": "4.0203",
//     "PYG": "7329.0426",
//     "QAR": "3.64",
//     "RON": "4.6113",
//     "RSD": "108.9312",
//     "RUB": "92.5569",
//     "RWF": "1244.7187",
//     "SAR": "3.75",
//     "SBD": "8.4901",
//     "SCR": "12.8192",
//     "SDG": "578.1271",
//     "SEK": "10.4671",
//     "SGD": "1.3404",
//     "SHP": "0.7966",
//     "SLE": "22.967",
//     "SLL": "22966.9518",
//     "SOS": "570.9578",
//     "SRD": "38.0177",
//     "SSP": "1073.4704",
//     "STN": "22.7711",
//     "SYP": "12867.9597",
//     "SZL": "18.9214",
//     "THB": "35.4183",
//     "TJS": "10.9264",
//     "TMT": "3.4981",
//     "TND": "3.1304",
//     "TOP": "2.3375",
//     "TRY": "28.9665",
//     "TTD": "6.7557",
//     "TVD": "1.5187",
//     "TWD": "31.3922",
//     "TZS": "2512.2768",
//     "UAH": "36.7488",
//     "UGX": "3772.3518",
//     "UYU": "39.1053",
//     "UZS": "12304.9699",
//     "VES": "35.6248",
//     "VND": "24240.6963",
//     "VUV": "119.6197",
//     "WST": "2.7376",
//     "XAF": "609.6673",
//     "XCD": "2.7",
//     "XDR": "0.7524",
//     "XOF": "609.6673",
//     "XPF": "110.911",
//     "YER": "249.0306",
//     "ZAR": "18.9216",
//     "ZMW": "24.1516",
//     "ZWL": "5829.908"
// }