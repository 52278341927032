import { createContext, useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify";
import { io } from 'socket.io-client';
import useTrait from "../utils/useTraint";
import { AccountContext } from "./account_context";






const AviatorContext = createContext()
export default AviatorContext;



export function AviatorContextProvider({ children }) {

    const { account, accountName } = useContext(AccountContext)
    const [connectionStatus, setConnectionStatus] = useState('pending')
    const socketRef = useRef()


    const [currentGame, setCurrentGame] = useState({})
    const [multipler, setMultipler] = useState(1)

    const currentGameRef = useRef()
    const [bet, setBet] = useState()



    //history
    const [historyPointsList, setHistoryPointsList] = useState([])

    const [bets, setBets] = useState([])
    const [myBetsHistory, setMyBetsHistory] = useState([])
    const [autoCashout, setAutoCashout] = useState()
    const [actionLoading, setActionLoading] = useState(false)
    const tiemrId = useRef()

    useEffect(() => {
        if (currentGame['status'] == 'running') {
            const startedAt = currentGame['startedAt']
            const nowTime = Date.now();

            const countDiff = parseInt((nowTime - startedAt)/150)
            setMultipler(1 + (countDiff * 0.01))
            tiemrId.current = setInterval(() => {
                setMultipler((oldState)=>{
                    return oldState + 0.01;
                })
            },150)
        }else if(tiemrId.current) {
            setMultipler(1)
           clearInterval(tiemrId.current)
        }
    }, [currentGame['status']])



    const createBet = (amount) => { return { "amount": amount, gameId: currentGame['_id'], _id: account['_id'], status: 'lobby', 'avatar': account['avatar'], accountAlias: accountName() } }
    //const updateCashoutBet = (incrementor, wonAmount, cashoutOut) => { return { ...betRef.current, wonAmount: wonAmount, incrementor: incrementor, cashoutAt: cashoutOut } }
    const updateJoinBet = (betId,timestamp) => { 
        setBet((oldBetStatus)=>{
            return { ...oldBetStatus,_id :betId, joinAt: timestamp, status: 'pending' }
        })
      
    }

    const isGameRunning = () => currentGameRef.current && currentGameRef.current.status == 'running'
    const isGameCrashed = () => currentGameRef.current && currentGameRef.current.status == 'crashed'
    const isGameInLobby = () => currentGameRef.current && currentGameRef.current.status == 'lobby'

    // const isBetPending = () => betRef.current && betRef.current.status == 'lobby'
    // const isBetJoined = () => betRef.current && betRef.current.status == 'pending'
    // const isBetCashout = () => betRef.current && betRef.current.status == 'cashout'


    useEffect(()=>{
        if(bet && bet['status']=='lobby' &&  currentGame
         && currentGame['status'] == 'lobby'){
            joinGameEmit(bet)
         }else if(bet && bet['status'] == 'pending') {
            setMyBetsHistory((prevState) => {
                const newState = [bet,...prevState]
                return newState;
            })
         }else if(bet && bet['status'] == 'cashout'){
            setBet(undefined)
         }
       
    },[(bet &&  bet['status'])])



    const placeBet = (amount) =>  setBet(createBet(amount))
    const cancelBet = () => setBet(undefined)



    const cashOutBet = () => {
        if (!isGameRunning()) return;

        setActionLoading(true)

        socketRef.current.emit('cashout', bet, async (response) => {
            setActionLoading(false)
            if (response['success']) {
                
                const data = response['data']
                const mulX = data['incrementor']
                const wonAmount = data['cashoutAmount']
                toast.success('You have crashed out!' + mulX + " " + wonAmount)
                
                setBet(undefined)

                // const updatedBet = updateCashoutBet(mulX, wonAmount)


                setMyBetsHistory(prevState => {
                    const newState = [...prevState]
                    const foundItem = newState.find((item) => item['_id'] == data['betId'])
                    if(!foundItem) return prevState;
                    
                    foundItem['status'] = 'cashout'
                    foundItem['incrementor'] = mulX;
                    foundItem['cashoutAmount'] = wonAmount;
                    return newState;
                })



            } else {
                const message = response['message']
                toast.error(message)
            }

        })


    }




    const onGameStateChange = async function (game) {
        console.log('onGameStateChange',game)
        setCurrentGame(game)
    }

    // const incrementor = (_data) => {
    
    //     const gameState = currentGameRef.current;
    //     const newIncState = { ...gameState, 'incremented': _data['incremented'] };
    //     setCurrentGame(newIncState)
    //     const incValue = _data['incremented']

    //     if (incValue >= autoCashoutRef.current) {
    //         cashOutBet()
    //     }
    // }




    const onNewBet = (newBet) => {
        setBets(prevState => {
            const newState = [newBet, ...prevState]
            return newState;
        })
    }


    const cashoutBets = (placedBet) => {
        const mul = placedBet['incrementor']
        const wonAmount = placedBet['cashout_amount']

        setBets((prevBets) => {
            const newState = [...prevBets]
            const foundItem = newState.find(item => item['_id'] == bet['betId'] && item['gameId'] == bet['gameId'])

            foundItem['status'] = 'cashout'
            foundItem['cashout_x'] = mul;
            foundItem['cashout_amount'] = wonAmount;

            return newState

        })
    }




    function connectSocket() {
        console.log(process.env.REACT_APP_SERVER_SOCKET + "crash", 'server socket url')
        let socket = io(process.env.REACT_APP_SERVER_SOCKET + "crash", {
            transports: ['websocket'],
            query: {
                authorization: localStorage.getItem('token')
            }
        });
        socketRef.current = socket;
        socket.on("connect", () => {
            setConnectionStatus('connected')
            console.log(socket.id, 'socket connected'); // x8WIv7-mJelg7on_ALbx
        });

        socket.on("disconnect", () => {
            setConnectionStatus('disconnected')
        });

        socket.on("error", (e) => {
            setConnectionStatus('error')
        });


        socket.on("connect_error", (err) => {

            console.log('connect_error', err)


            if (err.message == 'xhr poll error') {
                setConnectionStatus('no_internet_error')
            }

            else if (err.message == 'unauthorized_user' || err.message == 'unknown_user') {
                setConnectionStatus('unauthorized_user')
            }
            else {
                setConnectionStatus('connect_error')
            }
        });


        socket.on('game_status', onGameStateChange)
        //socket.on('incrementor', incrementor)
        socket.on('newBet', onNewBet)
        socket.on('player_cashout', cashoutBets)

        socket.on('init', init)

    }


    function init(data) {
        const betHistory = data['bet_history'];
        const gamePayload = data['game']
        setCurrentGame(gamePayload)
        setHistoryPointsList(betHistory)
    }


    function joinGameEmit(mBet) {
        setActionLoading(true)
        socketRef.current.emit('join_game', mBet, (data) => {
            if (data['success']) {
                toast.success('Bet placed!')
                const responseData = data['data']
                const betId = responseData['_id']
                updateJoinBet(betId,responseData['join_timestamp'])
            } else {
                setBet(undefined)
                console.error('joinGameEmit : ERR  ')
                toast.error(data.message)
            }
            setActionLoading(false)
        })
    }




    const makeAutoCashout = (cashoutX) => {
        setAutoCashout(cashoutX)
    }


    useEffect(() => {
        
       // if(!currentGame || !currentGame['status']) return;
        currentGameRef.current = currentGame;
        if(currentGame.status=='lobby' && bet && bet['status'] == 'lobby'){

            joinGameEmit(bet)
        }

        if(currentGame.status=='crashed'){
            setHistoryPointsList(prevState => {
                return [currentGame.incrementor,...prevState]
            })

            if(bet && bet['status'] == 'pending'){
                setBet(undefined)
            }
          
        }

    }, [(currentGame && currentGame['status'])])


    // useEffect(() => {
    //     betRef.current = bet;
    // }, [bet])


    // useEffect(() => {
    //     autoCashoutRef.current = autoCashout;
    // }, [autoCashout])




    useEffect(() => {
        connectSocket()
    }, [account])



    const cleanGame = () => {
        setCurrentGame({})
        setBet(undefined)
        
    }

    const closeSocket = (params) => {
        if(socketRef.current) socketRef.current.disconnect()
        socketRef.current =undefined
    }

    return <AviatorContext.Provider value={{
        placeBet,
        actionLoading,
        currentGame,
        cashOutBet,
        cancelBet,
        bets,
        bet,
        myBetsHistory,
        makeAutoCashout,
        historyPointsList,

        connectSocket,
        closeSocket,
        cleanGame,
        connectionStatus,

        isGameInLobby,
        isGameCrashed,
        isGameRunning,
        multipler
    }}>
        {children}
    </AviatorContext.Provider>
}