import React, { useEffect, useRef, useState } from "react";



function TimerView({ currentGame }) {

    const [secondsRemaining, setTimeRemaining] = useState('0')
    const timeRef = useRef()


    useEffect(() => {
        if (!currentGame) return;

        const gameEndSeconds = parseInt(new Date(currentGame['completeAt']).getTime() / 1000)
        timeRef.current = setInterval(() => {

         
            const currentSeconds = parseInt(new Date().getTime() / 1000)

            const timeLeft = gameEndSeconds - currentSeconds

            if (timeLeft <= 0) {
                return clearInterval(timeRef.current)
            }

            setTimeRemaining(timeLeft)
        }, 1000);


        return () => clearInterval(timeRef.current)
    }, [currentGame])


    const seconds = (secondsRemaining % 60).toString();
    const minutes = parseInt(secondsRemaining / 60).toString();


    const minFirstDigit = minutes.length > 1 ? minutes.split('')[0] : 0;
    const minSecundDigit = minutes.length > 1 ? minutes.split('')[1] : minutes;


    const secondFirstDigit = seconds.length > 1 ? seconds.split('')[0] : 0;
    const secondSecundDigit = seconds.length > 1 ? seconds.split('')[1] : seconds;



    return <>
        <span className="time-remaining-label">Time Remaining</span>
        <div className="mt-1">
            <span className="time-digit">{minFirstDigit}</span>
            <span className="time-digit">{minSecundDigit}</span>
            <span className="time-digit">:</span>
            <span className="time-digit">{secondFirstDigit}</span>
            <span className="time-digit">{secondSecundDigit}</span>
        </div>

    </>
}


export default React.memo(TimerView)