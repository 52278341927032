

import { useContext, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { isEmail } from '../../utils/utils';
import { makeSubmitRequest } from '../../api/api';
import { toast } from 'react-toastify';
import { AccountContext } from '../../context/account_context';
import { NavBar } from "../../widgets/navBar";
import { useNavigate } from 'react-router-dom';

export default function UpdateAvatarPage() {

    const [loading, setLoading] = useState(false)
    const { changeAvatar } = useContext(AccountContext)
    const navigate = useNavigate()

    const changeImage = async (avatarIndex) => {
        if (loading) {
            return;
        }
        setLoading(true)
        makeSubmitRequest('users/change_avatar', { avatar: avatarIndex }).then(e => {

            toast.success('Successfully changed')
            changeAvatar(avatarIndex)
            handleCloseLocal()
            navigate(-1)
        }).catch(e => {
            setLoading(false)
            toast.error(e.message)
        })
    }

    const handleCloseLocal = () => {
        setLoading(false)
    }

    return (

        <>
            <NavBar title={"Update Avatar"}></NavBar>

            <div data-v-37c57b49 className='avatar-container-content pt-4'>
                <div data-v-37c57b49 className='van-grid'>
                    {[...Array(50).keys()].map((index) => <div data-v-37c57b49 className='van-grid-item'>
                        <div className='van-grid-item__content van-grid-item__content--center'>
                            <img src={`avatar/av-${index + 1}.png`} className='avatar_img' onClick={() => changeImage(index + 1)} />
                        </div>
                    </div>)}

                </div>
            </div>
        </>
    );
}
