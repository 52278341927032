
import { FiArrowLeft } from 'react-icons/fi';

import { useNavigate } from 'react-router-dom';

export function NavBar({onBackClick,title}){

    const navigate = useNavigate();

    return <div className="app-nav" >
        <span onClick={()=>navigate(-1)}>
          <FiArrowLeft color='white' className='ms-1' size={20}></FiArrowLeft>
        </span>
        <span className="nav-bar-title">
            {title}
        </span>
    </div>
}