import logo from './logo.svg';
import { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GameLoadingView } from './widgets/game_loading';

import 'material-symbols/outlined.css';
import Dashboard from './dashboard';
import { AccountContext, AccountContextProvider } from './context/account_context';
import { AviatorContextProvider } from './context/aviator_context';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Redirect, Routes } from "react-router-dom";
import { Navigate } from 'react-router-dom';



import { Home } from './pages/Home';
import { Main } from './main';
import { CurrencyContextProvider } from './context/currencyContext';
import { IntlProvider, FormattedMessage, FormattedNumber } from 'react-intl'





function App({ props, ref }) {
  const auth = localStorage.getItem('token');



  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"

      />
        <CurrencyContextProvider>
          <AccountContextProvider>
            <AviatorContextProvider>
              <AppRoutes />
            </AviatorContextProvider>
          </AccountContextProvider>
        </CurrencyContextProvider>
     
    </div>
  );
}

export default App;



function AppRoutes() {


  return <Main />



}

