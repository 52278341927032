import { useEffect, useState } from "react";
import { getRequest } from "../../api/api";
import { NavBar } from "../../widgets/navBar";
import moment from "moment";
import { LoadingPage } from "../../widgets/loading_page";
import { EmptyWidget } from "../../widgets/empty_widget";



export function NewSubordinate() {
    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })

    const [selectedIndex, setSelectedIndex] = useState(0)

    const fetch = async () => {
        try {
            let endpoint = "commision/newSubordinates?";

            var date = selectedIndex ==0  ? "today" : selectedIndex == 1 ?  "yesterday"  : 'month'
            endpoint += "date="+date;
            const subordinate = await getRequest(endpoint)
            setData({ data: subordinate, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }



    useEffect(() => {
        fetch()
    }, [selectedIndex])


    return <div>

        <NavBar title={'New Subordinate'} />

        <Tabs activeIndex={selectedIndex} onClick={(index)=>{
            setSelectedIndex(index)
        }}/>
        {data['loading'] && <LoadingPage />}


        {data.data && data.data.length == 0 && <div className="h-100 d-flex  flex-column justify-content-center align-items-center"> <EmptyWidget /></div>}


        {
            <div>
                {
                    data.data && data.data.map(item => <Item item={item}></Item>)
                }
            </div>
        }
    </div>
}





function Tabs({activeIndex,onClick}) {
    return <div data-v-caf22e0f="" className="subordinate__container-header pb-4">
        <div data-v-caf22e0f="" className="van-tabs van-tabs--card footer-tabBar">
            <div className="van-tabs__wrap"><div role="tablist" className="van-tabs__nav van-tabs__nav--card" aria-orientation="horizontal" >
                <div id="van-tabs-1-0" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 0 ? "van-tab--active" :""}`} aria-selected="true" aria-controls="van-tab-2" onClick={()=>onClick(0)}><span className="van-tab__text van-tab__text--ellipsis">Today</span></div>
                <div id="van-tabs-1-1" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 1 ? "van-tab--active" :""}`} aria-selected="false" aria-controls="van-tab-3" onClick={()=>onClick(1)}><span className="van-tab__text van-tab__text--ellipsis">Yesterday</span></div>
                <div id="van-tabs-1-2" role="tab" className={"van-tab van-tab--card " + `${activeIndex == 2 ? "van-tab--active" :""}`} aria-selected="false" aria-controls="van-tab-4" onClick={()=>onClick(2)}><span className="van-tab__text van-tab__text--ellipsis">This month</span></div>
            </div>
            </div>
            <div className="van-tabs__content"></div></div></div>
}


function Item({ item }) {
    return <div data-v-caf22e0f="" className="subordinate__container-content__item ar-1px-b">
        <div data-v-caf22e0f="">
            <span data-v-caf22e0f="">{
                item['email']
            }</span><span data-v-caf22e0f="">UID:{item['accountId']}</span></div>
        <div data-v-caf22e0f=""><span data-v-caf22e0f="">{item['level'] > 1 ? "Indirect" : "Direct"} subordinates</span>
            <span data-v-caf22e0f="">{moment(item.createdAt).format('DD MM YYYY hh:mm:ss')}</span>
        </div></div>
}

String.prototype.hidePhone = function () {
    var characters = this.split("")

    let str = ""
    for (let i = 0; i < characters.length; i++) {
        if (i > 2 && i < characters.length - 6) {
            str += "*"
        }
        else str += characters[i]
    }

    return str
}