
import {FaCircleCheck} from 'react-icons/fa6'

export function DepositSuccess(){
    return <div className="deposit-success-container">
        <div>
            <FaCircleCheck size={100} color="green"></FaCircleCheck>
        </div>
        <p className="deposit-success-message mt-4">
            Deposit Success
        </p>
        <span className="backHome">Back to home</span>
    </div>
}