import { useContext, useEffect, useState } from "react";
import { getRequest, makeSubmitRequest } from "../../../api/api";
import { NavBar } from "../../../widgets/navBar";
import { LoadingPage } from "../../../widgets/loading_page";
import { toast } from 'react-toastify';
import coinimg from '../../../images/coin-attendence.png'
import { CurrencyContext } from "../../../context/currencyContext";

export default function Attendence() {

    const {convertCoin} = useContext(CurrencyContext)

    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })

    const fetch = async () => {
        try {
            let endpoint = "dailyCheckIn";

            const user = await getRequest(endpoint)
            setData({ data: user, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }


    const makeCheckIn = async () => {
        try {
            let endpoint = "dailyCheckIn/checkIn";
            await makeSubmitRequest(endpoint)
            toast('Checked In')
            fetch();
        } catch (e) {
            toast.error(e.message);
        }
    }



    useEffect(() => {
        fetch()
    }, [])


    if (data['loading']) return <LoadingPage />

    if (data['error']) return <LoadingPage />

    const { attendence, bonusList } = data['data']

    var lastItem = bonusList.pop()

    return <div>
        <NavBar title="Daily Check Bonus">

        </NavBar>
        <div data-v-94557d3d="" className="dailySignIn__container-hero mt-3">
            <div data-v-94557d3d="" className="dailySignIn__container-hero__header">
                <h1 data-v-94557d3d="">Daily CheckIn bonus</h1>
                <p data-v-94557d3d="" className="mt-1">Get rewards based on consecutive login days</p>
                <div data-v-94557d3d="" className="mt-2">Check in Bonus consecutively<span data-v-94557d3d="">{attendence['count']}</span>Day</div>
                {/* <p data-v-94557d3d="">Accumulated</p>
                <h1 data-v-94557d3d="">₹0.00</h1> */}
                </div>
            {/* <div data-v-94557d3d="" className="dailySignIn__container-hero__footer"><button data-v-94557d3d="">Game Rules</button><button data-v-94557d3d="">History</button></div> */}
        </div>



        <div data-v-94557d3d="" className="dailySignIn__container-content">
            <div data-v-94557d3d="" className="dailySignIn__container-content__wrapper">


                {bonusList.map((item, index) => <BonusItem index={index} item={item} />)}


                <div data-v-94557d3d="" className="dailySignIn__container-content__wrapper-block">
                    <div data-v-94557d3d=""><span data-v-94557d3d="">{convertCoin(lastItem["amount"])}</span><span data-v-94557d3d="">{lastItem["day"]} Day</span></div></div></div>
            <div data-v-94557d3d="" className="dailySignIn__container-content__footer">
                <button data-v-94557d3d="" className="" onClick={makeCheckIn}>Daily CheckIn</button></div></div>
    </div>
}




function BonusItem({ index, item }) {
    const {convertCoin} = useContext(CurrencyContext)
    return <div key={index} data-v-94557d3d="" className="dailySignIn__container-content__wrapper-block">
        <div data-v-94557d3d="" className="dailySignIn__container-content__wrapper-block__header">
            <img data-v-94557d3d="" className="" data-origin="https://damangames.in/assets/png/SignInTop-2fa51663.png" src="https://damangames.in/assets/png/SignInTop-2fa51663.png" />
            <span data-v-94557d3d="">{convertCoin(item["amount"])}</span>
        </div>
        <img data-v-94557d3d="" className="" data-origin="https://damangames.in/assets/png/coin-294b6998.png" src={coinimg} />
        <span data-v-94557d3d="">{index + 1} Day</span>
    </div>
}