import React,{ useContext, useEffect, useRef, useState } from "react"
import WingoContext from "../../../context/wingo_context";




function CountDownView({ currentGame }) {

    var audio = new Audio("/audio/vpgo.mp3");
    const { soundEnabled,toggleSound } = useContext(WingoContext)


    const [countDown, setTimeRemaining] = useState(Infinity)
    const timeRef = useRef()

    useEffect(() => {
        if (!currentGame) return;
        const gameEndSeconds = parseInt(new Date(currentGame['completeAt']).getTime() / 1000)
        timeRef.current = setInterval(() => {
           
            const currentSeconds = parseInt(new Date().getTime() / 1000)
            const timeLeft = gameEndSeconds - currentSeconds

            if(timeLeft < 5){
                if(soundEnabled){
                    document.getElementById('backgroundMusic').play()
                }
              
            }
            if (timeLeft <= 0) {
                return clearInterval(timeRef.current)
            }
            setTimeRemaining(timeLeft.toString())
        }, 1000);
        return () => clearInterval(timeRef.current)
    }, [currentGame])


    return <div className="count-down-wrapper" hidden={countDown>5}>
        <div className="count-down-timer">
            <span className="timer-count">{countDown}</span>
        </div>
    </div>

}


export default React.memo(CountDownView);