import { BottomSheet } from "react-spring-bottom-sheet";

import countryCodes from '../assests/CountryCodes.json'


export function ContryCodesSheet({ open, onClose,onSelectCountry }) {


    return <>

        <BottomSheet open={open} maxHeight={600} onDismiss={onClose}>
            <div>
                <ul className="country_code_picker">
                    {
                        countryCodes.map(item => {
                            return <li onClick={()=>onSelectCountry(item['dial_code'])} className="country_code">
                                   <div className="country_code">{item['dial_code']}</div>
                                <div className="country_name">{item['name']}</div>
                             
                            </li>
                        })
                    }
                </ul>
            </div>
        </BottomSheet>
    </>
}