import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../context/account_context";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../api/api";
import icon1 from '../../images/icon9.png';
import icon2 from '../../images/icon10.png';
import icon3 from '../../images/icon11.png';
import icon4 from '../../images/icon12.png';
import walletimg from '../../images/wallets.png'
import logo from "../../images/logo.png"
function Wallet() {
    const [apiData, setApiData] = useState({loading: true, error : null, data: undefined})
    const navigate = useNavigate()
    const { syncWalletBalance,mainBalance,bonusBalance } = useContext(AccountContext)


    const fetchUserDetails = ()=>{
        getRequest('users/wallet').then(res=>{
            setApiData({loading : false, data : res})
        }).catch(e=>{
            setApiData({loading : false, error : e})
        })
    }

    useEffect(() => {
        syncWalletBalance()
    }, [])



    return <div className="wallet-container wallet-container-width" data-v-fe2fea6f>
        <div data-v-905dfe8c="" data-v-fe2fea6f="" className="navbar">
            <div data-v-905dfe8c="" className="navbar-fixed">
                <div data-v-905dfe8c="" className="navbar__content">
                    <div data-v-905dfe8c="" className="navbar__content-left">
                    </div>
                    <div data-v-905dfe8c="" className="navbar__content-center">
                            <img src={logo} />
                        <div data-v-905dfe8c="" className="navbar__content-title"></div>
                    </div>
                    <div data-v-905dfe8c="" className="navbar__content-right"></div>
                </div>
            </div>
        </div>



        <div data-v-fe2fea6f="" className="wallet-container-header"><div data-v-fe2fea6f="" className="wallet-container-header-belly"><className data-v-fe2fea6f="" src={walletimg} alt="" /><div data-v-fe2fea6f="">
            {mainBalance()}
        </div><span data-v-fe2fea6f="">Total balance</span></div></div>



        <div data-v-fe2fea6f="" className="wallet-container-content">
            <div data-v-fe2fea6f="" className="container">
                <div data-v-fe2fea6f="" className="progressBars">
                    <div data-v-fe2fea6f="" className="progressBarsL">
                        <div data-v-fe2fea6f="" className="van-circle">

                        </div>
                        <h3 data-v-fe2fea6f="">{mainBalance()}</h3>
                        <span data-v-fe2fea6f="">Main wallet</span></div><div data-v-fe2fea6f="" className="progressBarsR"><div data-v-fe2fea6f="" className="van-circle">


                        </div><h3 data-v-fe2fea6f="">{bonusBalance()}</h3><span data-v-fe2fea6f="">Bonus</span></div></div><div data-v-fe2fea6f="" className="recycleBtnD">

                </div>
                <div data-v-fe2fea6f="" className="userDetail">
                    <div data-v-fe2fea6f="" onClick={() => {
                        navigate('/deposit')
                    }}>
                        <div data-v-fe2fea6f="" className="imgD">'
                            <img data-v-fe2fea6f="" src={icon1} />
                        </div>
                        <span data-v-fe2fea6f="">Deposit</span>
                    </div>


                    <div data-v-fe2fea6f="" onClick={()=>navigate('/withdraw')}>
                        <div data-v-fe2fea6f="" className="imgD">
                        <img data-v-fe2fea6f="" src={icon2} />
                    </div><span data-v-fe2fea6f="">Withdraw</span></div>
                    <div data-v-fe2fea6f="" onClick={()=>{
                        navigate('/transaction_history?type=deposit')
                    }}>
                        <div data-v-fe2fea6f="" className="imgD">
                            <img data-v-fe2fea6f="" src={icon3} />
                        </div>
                        <span data-v-fe2fea6f="">Deposit history</span>
                    </div>
                    <div data-v-fe2fea6f="" onClick={()=>{
                        navigate('/withdrawRequests')
                    }
                    }>
                        <div data-v-fe2fea6f="" className="imgD">
                            <img data-v-fe2fea6f="" src={icon4} />
                        </div><span data-v-fe2fea6f="">Withdrawal history</span></div></div>
            </div><div data-v-fe2fea6f="" className="gameList"><div data-v-fe2fea6f="" className="box select">

            </div>


            </div>
        </div>


    </div>
}

export default Wallet;