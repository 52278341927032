import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import games from "../../games.json";
import Footer from "../../widgets/footer";
import logo from '../../images/logo.png'
import img1 from "../../assests/why-bitips-better-1.png";
import img2 from "../../assests/why-bitips-better-2.png";
import img3 from "../../assests/why-bitips-better-3.png";
import img4 from "../../assests/why-bitips-better-4.jpg";
import bnrimg1 from '../../images/banner-img-01.jpeg'
import bnrimg2 from '../../images/banner-img-02.jpeg'
import bnrimg3 from '../../images/banner-img-03.jpeg'
import bnrimg4 from '../../images/banner-img-04.jpg'
import bnrimg5 from '../../images/banner-img-05.jpg'
import bnrimg6 from '../../images/banner-img-06.jpg'
import iconpay1 from "../../assests/payment-icon-1.png";
import iconpay2 from "../../assests/payment-icon-2.png";
import iconpay3 from "../../assests/payment-icon-3.png";
import iconpay4 from "../../assests/payment-icon-4.png";
import iconpay5 from "../../assests/payment-icon-5.png";
import iconpay6 from "../../assests/payment-icon-6.png";
import iconpay7 from "../../assests/payment-icon-7.png";
import iconpay8 from "../../assests/payment-icon-8.png";
import iconpay9 from "../../assests/payment-icon-9.png";
import iconpay10 from "../../assests/payment-icon-10.png";

import "bootstrap/dist/css/bootstrap.css";
export function Home() {
  const navigate = useNavigate();
  return (
    <div
      classNameName="content_home"
      data-v-c8195b8f
      style={{ background: "#070044" }}
    >
      <div data-v-905dfe8c="" data-v-c8195b8f="" className="navbar">
        <div data-v-905dfe8c="" className="navbar-fixed" style={{background:'#181515'}}>
          <div data-v-905dfe8c="" className="navbar__content">
            <div data-v-905dfe8c="" className="navbar__content-left">
              <h3 className="logo">
                <img src={logo} />
              </h3>
            </div>
            <div data-v-905dfe8c="" className="navbar__content-center">
              <div data-v-905dfe8c="" className="navbar__content-title"></div>
            </div>
            <div data-v-905dfe8c="" className="navbar__content-right">
              <div data-v-c8195b8f="" className="content__right"></div>
            </div>
          </div>
        </div>
      </div>
      <Carousel prevIcon={null} nextIcon={null} interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg6}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg1}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg4}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg2}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg3}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bnrimg5}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <div className="d-flex flex-column align-items-center text-white">
        <h2 className="our-games">Our Games</h2>
        <span className="our-games-subtitles text-white">
          Casino Days has something for everyone. With over 5000 games to choose
          from, play the best slots, jackpots and live casino games.
        </span>
      </div>
      <div className="d-flex flex-column align-items-start text-center">
        <h2
          className="ms-4 mt-4 text-white text-center game-heding"          
        >
          Popular Games in World
        </h2>
        <div className="games-row justify-content-center">
          {games.slice(0, 2).map((item) => {
            return (
              <div
                className="game-card"
                onClick={() => {
                  if (!item.available) return;
                  navigate(item.endpoint);
                }}
              >
               <a href={item.link}><img src={item.logo} /></a>
                
              </div>
            );
          })}
        </div>
        <div className="games-row mt-2 justify-content-center">
          {games.slice(2, 4).map((item) => {
            return (
              <div
                className="game-card"
                onClick={() => {
                  if (!item.available) return;
                  navigate(item.endpoint);
                }}
              >
                <a href={item.link}><img src={item.logo} /></a>
                
              </div>
            );
          })}
        </div>
        <div className="games-row mt-2 justify-content-center">
          {games.slice(4, 6).map((item) => {
            return (
              <div
                className="game-card"
                onClick={() => {
                  if (!item.available) return;
                  navigate(item.endpoint);
                }}
              >
                <a href={item.link}><img src={item.logo} /></a>
                
              </div>
            );
          })}
        </div>
        <div className="games-row mt-2 justify-content-center">
          {games.slice(6, 8).map((item) => {
            return (
              <div
                className="game-card"
                onClick={() => {
                  if (!item.available) return;
                  navigate(item.endpoint);
                }}
              >
                <a href={item.link}><img src={item.logo} /></a>
              </div>
            );
          })}
        </div>
        <div className="games-row mt-2 justify-content-center">
          {games.slice(8, 10).map((item) => {
            return (
              <div
                className="game-card"
                onClick={() => {
                  if (!item.available) return;
                  navigate(item.endpoint);
                }}
              >
                <a href={item.link}><img src={item.logo} /></a>
                
              </div>
            );
          })}
        </div>
      </div>
      <section className="why-bitips-better">
        <div className="overlay pt-120">
          <div className="container wow fadeInUp">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="section-header text-center">
                  <h5 className="sub-title mt-3">Why VpGames is better?</h5>
                  <h2 className="title">This is What You Get</h2>
                  <p className="text-white">
                    With a player-centric approach, VpGames is able to satisfy
                    millions of gamers across the globe. VpGames has its
                    priority set on its community, ensuring an everlasting and
                    endlessly entertaining gambling experience.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="single-area d-flex align-items-start">
                  <div className="img-area">
                    <img src={img1} alt="image" />
                  </div>
                  <div className="text-area">
                    <h4>Refer and Earn Benefits</h4>
                    <p>
                      Whever you refer the app to other members. You gets
                      promotional benefits in your account!
                    </p>
                  </div>
                </div>
                <div className="single-area d-flex align-items-start">
                  <div className="img-area">
                    <img src={img2} alt="image" />
                  </div>
                  <div className="text-area">
                    <h4>Lots of Rewards</h4>
                    <p>
                      You will get lots of rewards like daily app check-in
                      bonus, mission bonus, deposit bonus etc!
                    </p>
                  </div>
                </div>
                <div className="single-area d-flex align-items-start">
                  <div className="img-area">
                    <img src={img3} alt="image" style={{ borderRadius: 50 }} />
                  </div>
                  <div className="text-area">
                    <h4>Third-Party Games</h4>
                    <p>
                      Our platform may integrate third-party games, thses will
                      have seprate account/wallet to play games.
                    </p>
                  </div>
                </div>
                <div className="single-area d-flex align-items-start">
                  <div className="img-area">
                    <img src={img4} alt="image" style={{ borderRadius: 50 }} />
                  </div>
                  <div className="text-area">
                    <h4>Multiple Payment Options</h4>
                    <p>
                      Due to several reasons, crytocurrency is the most popular
                      and widely used digital currency, We have multiple payment
                      options!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="newsletter">
                <div className="section-header text-center">
                  <h4 className="title text-white mt-3">
                    Accepted Payment Methods
                  </h4>
                </div>
                <div className="col-md-12 d-flex">
                  <div className="col">
                    <img src={iconpay1} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay2} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay3} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay4} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay5} alt="payment" />
                  </div>
                </div>
                <div className="col-md-12 d-flex mt-2">
                  <div className="col">
                    <img src={iconpay6} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay7} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay8} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay9} alt="payment" />
                  </div>
                  <div className="col">
                    <img src={iconpay10} alt="payment" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row telegram-btn mt-3">
            <div className="col-md-12 justify-content-center d-flex">
              <div className="social-link d-flex align-items-center">
                <a
                  className="d-flex mb-0"
                  href="https://t.me/officialVpGamesprediction"
                  target="_blank"
                >
                  <i className="fab fa-telegram"></i>
                  <p className="mb-0">Join Our Telegram Channel</p>
                </a>
              </div>
            </div>
            <div className="col-md-12 justify-content-center d-flex mt-1">
              <div className="social-link d-flex align-items-center">
                <a
                  className="d-flex mb-0"
               
                  href="https://morewinapp.s3.ap-south-1.amazonaws.com/app-release.apk" 
                  target="_blank"
                  
                >
                  <i className="fa fa-download"></i>&nbsp;
                  <p className="mb-0">Download App</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Footer />
    </div>
  );
}