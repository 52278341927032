import { useContext, useEffect, useRef, useState } from "react";
import { NavBar } from "../../widgets/navBar";
import { useNavigate } from "react-router-dom";
import { getRequest, makeSubmitRequest } from "../../api/api";
import icon1 from '../../images/icon13.png';
import { AccountContext } from "../../context/account_context";
import { Col, Row, Spinner, Toast } from "react-bootstrap";
import iconpay1 from "../../assests/payment-icon-1.png";
import iconpay2 from "../../assests/payment-icon-2.png";
import iconpay3 from "../../assests/payment-icon-3.png";
import iconpay4 from "../../assests/payment-icon-4.png";
import iconpay5 from "../../assests/payment-icon-5.png";
import iconpay6 from "../../assests/payment-icon-6.png";
import iconpay7 from "../../assests/payment-icon-7.png";
import iconpay8 from "../../assests/payment-icon-8.png";
import iconpay9 from "../../assests/payment-icon-9.png";
import iconpay10 from "../../assests/payment-icon-10.png";
import ltctCoin from "../../assests/ltct_coin.png";
import rechargeicon from "../../images/recharge-icon.png"
import fx from "money";
import { decode as base64Decode, encode as base64Encode } from 'base-64';
import { toast } from "react-toastify";
import { CurrencyContext } from "../../context/currencyContext";




export function DepositPage() {

    const coins = [{
        "label": "BTC",
        icon: iconpay1,
        "code": "BTC"
    }, {
        "label": "ETH",
        icon: iconpay2,
        "code": "ETH"
    }, {
        "label": "USDT.TRC20",
        icon: iconpay3,
        "code": "USDT.TRC20",
    }, {
        "label": "BNB",
        icon: iconpay4,
        "code": "BNB"
    }, {
        "label": "DOGE",
        icon: iconpay6,
        "code": "DOGE"
    }, {
        "label": "TRX",
        icon: iconpay7,
        "code": "TRX"
    },
    {
        "label": "Litecoin Testnet",
        icon: ltctCoin,
        "code": "LTCT"
    }
    ]

    const [amount, setAmount] = useState()
    const [convertedPrice, setConvertedPrice] = useState()

    const [coinRates, setCoinRates] = useState({ loading: true, error: undefined, data: undefined })
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)


    const [selectedCoin, setSelectedCoin] = useState(coins[0])
    const [minDepostAmount, setMinDepostAmount] = useState(0)

    const { mainBalance } = useContext(AccountContext)
    const { currencyRates } = useContext(CurrencyContext)


    const onAmountChange = () => {
        if (!amount) {
           return setConvertedPrice("")
        }
        const rateInUsd = coinRates.data[selectedCoin['code']]
        debugger
        var totalAmount = rateInUsd * amount;
        var rateInInr = fx(totalAmount).convert({ from: "USD", to: "INR" }).toFixed(2)
        setConvertedPrice(rateInInr)
    }

    useEffect(() => {
        onAmountChange()
    }, [amount, selectedCoin])



    useEffect(() => {
        if (!coinRates.data) return;
        const rateInUsd = coinRates.data[selectedCoin['code']]
        var minValue = 10/rateInUsd;
        setMinDepostAmount(minValue)
    }, [selectedCoin, coinRates, coinRates.data])


    const deposit = () => {
        if (loading) return;
       
        if(amount < minDepostAmount){
            return toast.error(`Min deposit amount should be ${minDepostAmount} ${selectedCoin['code']}`)
        }

        setLoading(true)
        makeSubmitRequest('coinpayments/makeDeposit', { amount: amount, coin: selectedCoin['code'] })
            .then(res => {
                setLoading(false)
                const encodedString = base64Encode(JSON.stringify({ ...res, "coin": selectedCoin['code'] }));

                navigate('/checkoutPage?q=' + encodedString, { replace: true })

            }).catch(e => {
                setLoading(false)
                toast.error(e.message)
                console.error('deposit erro', e)
            })
    }



    const fetchCoinRates = async () => {
        try {
            var rates = await getRequest('coinpayments/coinRates')

            setCoinRates({ data: rates, loading: false })
        } catch (e) {
            setCoinRates({ error: e, loading: false })
            toast.error('Opps! something went wrong')
            navigate(-1)
        }

    }

    useEffect(() => {
        fetchCoinRates()
    }, [])



    if (coinRates.loading || coinRates.error) {
        return <div className="d-flex h-100 justify-content-center align-items-center w-100">
            <Spinner size="sm" className="page-loading-spinner"></Spinner>
        </div>
    }



    return <div className="Recharge__container pt-4 pb-4" data-v-643fef5d>

        <NavBar title={"Deposit"} />

        <div data-v-d42c1f7a="" data-v-643fef5d="" className="balanceAssets ye">
            <div data-v-d42c1f7a="" className="balanceAssets__header">
                <div data-v-d42c1f7a="" className="balanceAssets__header__left">
                    <img data-v-d42c1f7a="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAArlBMVEUAAAD/sjH/zRj/zR//1zbuR0f/zBb/zRf/zhr/zRn/+HfuSUn/zBn/+njvSUn/zCH/+Xf/+XX/+HT/+nj/zBn/zRr/pSL/20H/2T3/1Db/zBf/30j/1zn/7mP/8Gb/3UT/9nL/9W//8mn/82z/7GDuSEj/+Xf/0zP/5lX/5FL/4Uz/6Vv/6Fj/40//613/+HT/vTT/2033kj3/xzz+yzX/rSj/sSr/0kH/sSz/rym/OY7sAAAAFnRSTlMAIsBCE+/v3IFxkZGRgYEy7+/v7rBhR1ORgAAAAh9JREFUWMPtkuly2jAQgA3hyJ2e1BKyMYGC7dbYXEn6/i/W1QqNughsI03+dPIh7SXzDSMcfPD/czXsQfTmqcv5tb+md88lHV/PzYAjXd/L4ZorD02nyw1D98u55oQb98uhDHpOl9PlFvcOl/ONn+Lp4ssZWA6XV+D70DI0vwIPnxZOfL2lnttFA6VV6vqBiL6UNouyFZ+JKDlNWSY1HA6pyB0qmgIJbJOxSnAnZowBk4GK3KGimWI6lcEs7LDChP3hMQMVuUNFz4rZIet2Rqa6PJpSkROxLYrj5xi3A1Rk8BZFcSQXFpoYw+FAT2IcmIeIKHKHiubRfC43xkguDIAa6KkeSXSkIg+IaDVfwWi1khtrALKu1AEs1aisayq6gPV2PyJ0AsOv1qSVMfiI0rdRreh3W6pRa1Gqc0pHyHrUIErboS5om63ffEUv8L0K8vqcKCOk2Tn2UiSLelEz8hft8yzbnhMVRZHBByKEGvCO9ltI50R5cZLj+WvTv5YXuYE2lKpJ1JbxS71onI81WJGGVlW96AJeqz81Ig+Cf5l48D6iH8gEtiomWE90g6VusMOEWCJnbFGIERMl1DmUoVYUevBOIiFECAFPhGyELDDgR00EgqXujkTCAyJiMGAQIApmSgOjpQ7MEl2KMCUR7ZgzOyLasCVjyyUsCTMBUYeqx83wXLEhov5mt3Rit+kT0eNPZ+4Cwl3fTdN/DD5oy19QoJEcy1l/8AAAAABJRU5ErkJggg==" />
                    Balance</div>
            </div>
            <div data-v-d42c1f7a="" className="balanceAssets__main"><p data-v-d42c1f7a="">{mainBalance()}</p>
                <img data-v-d42c1f7a="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFkAAAA7CAMAAAAn6dbMAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMAIN9AgO8QMM9gcKC/kK9Qj29ZmZoEAAAB7klEQVRYw93Y23LjIAwGYHE+OU5W7/+wO2M1/r31QAzBN6uLTqd0voAEyA79H6EWvhAurtZ0ypEvhy6qR+au6LGXPtrl63nW3BfFTy2zMnlfXFTTd9FD/9CepttF6IXmRxY60/xIQgeaH+m2fNBjow3ND++uTNpka81YFZs7L8jm18+RSedL5zoNZLo0PlrggXo8t5XWx1dGuNCVjnaiLfMwvSVaVQb/MI/TupFBJZzsDfmppshKNDmpIYL+WhbLShNUCrP+Vn5t0ko/Mn2kfTIN2RuFbSHOW/5EB8dsK7KMZmx0dop2GbSv93d7llEzd6weQcZfo6/mFTRkDOJQW4L8XlOVznykIaNmej/UK/2S27QVOp9k1Ay/nWQyvMVabyScfssBbTG+q3eWKXH9qimgD7LSWMsi42cZNFGDDpBR2tPWhHwslW3eui4c5Nc/fVw9A1VlUslUz2HcaZFRM0RNlmjRIokccOK+lEFvcpIkP+l7GXeA4z0sTZBBIx7NizbQMK1988Uq0RDd7hVFVjRKhw+PaJEG6dz6L3lCGqKdwM1EF+oMY8sjeWqGRb5uecyOs1lM+jUfRlucH4Zvo+1Nb9PoQ1zUbTQv2UzWLXdE39tY0n208x3fXfTRpuu7i555q96LZonXknFuFn8BRodWROohzGIAAAAASUVORK5CYII=" alt="" /></div>

        </div>


        <div className="depositCoinContainer">
            {
                coins.map(item => {
                    return <div onClick={() => {
                        setSelectedCoin(item)
                    }} className={item['code'] == selectedCoin['code'] ? "depositCoinCard active" : "depositCoinCard"} >
                        <img src={item['icon']} width={"40px"}></img>
                        <span>{item['label']}</span>
                    </div>
                })
            }
        </div>


        <div data-v-a78043af="" className="Recharge__content-paymoney boxStyle">
            <div data-v-a78043af="" className="Recharge__content-paymoney__title">
                <img data-v-a78043af="" src={icon1} alt="" />
                <p data-v-a78043af="">Deposit amount</p>
            </div>


            <div data-v-a78043af="" className="Recharge__content-paymoney__money-input">
                <div data-v-a78043af="" className="place-div">{
                    <img width={"30px"} src={selectedCoin['icon']} className="ms-3 me-3"></img>}
                </div>
                <div data-v-a78043af="" className="van-cell van-field amount-input" >
                    <div className="van-cell__value van-field__value">
                        <div className="van-field__body">
                            <input

                                onChange={(e) => {
                                    setAmount(e.target.value)
                                    onAmountChange()
                                }}
                                inputMode="numeric"
                                id="van-field-1-input"
                                className="van-field__control"
                                placeholder="Please enter the amount" />
                        </div>
                    </div>
                </div>

            </div>


            <div data-v-a78043af="" className="Recharge__content-paymoney__money-input mt-2">
                {/* <div data-v-a78043af="" className="place-div">{
                    <img width={"30px"} src={selectedCoin['icon']} className="ms-3 me-3"></img>}
                </div> */}
                <div data-v-a78043af="" className="van-cell van-field amount-input" >
                    <div className="van-cell__value van-field__value">
                        <div className="van-field__body">
                            <input
                                value={convertedPrice}
                                disabled={true}
                                inputMode="numeric"
                                id="van-field-1-input"
                                className="van-field__control"
                                placeholder="Please enter the amount" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div data-v-643fef5d="" className={`Recharge__container-rechageBtn ${amount == "" ? "" : "rechage_active"}`} onClick={deposit}>
            {loading ? <Spinner size="sm" style={{ height: "16px", width: "16px" }}></Spinner> : "Deposit"}
        </div>


        <div data-v-c2e24b33="" data-v-643fef5d="" className="Recharge__container-intro ms-2 me-2 mt-2" ><div data-v-c2e24b33="" className="Recharge__container-intro__title">
            <img data-v-c2e24b33="" src={rechargeicon} alt="" />
            <span data-v-c2e24b33="">Recharge instructions</span></div>
            <div data-v-c2e24b33="" className="Recharge__container-intro__lists">
                <div data-v-c2e24b33="" className="item">
                    <p data-v-c2e24b33="">Minimum deposit: {minDepostAmount} {selectedCoin['code']} , deposits less than {minDepostAmount} {selectedCoin['code']}  will not be credited</p>
                    <p data-v-c2e24b33="">Do not deposit any non-currency assets to the above address, or the assets will not be recovered.</p>
                    <p data-v-c2e24b33="">Please confirm that the operating environment is safe to avoid information being tampered with or leaked.</p>
                    <p data-v-c2e24b33="">The transfer amount must match the order you created, otherwise the money cannot be credited successfully.</p>
                    <p data-v-c2e24b33="">Note: do not cancel the deposit order after the money has been transferred.</p>
                </div>
            </div>
        </div>

    </div>
}

