
import React, { useContext } from 'react'
import { AccountContext } from '../../context/account_context'
import { useNavigate } from 'react-router-dom'
import icon1 from '../../images/icon14.png';
import icon2 from '../../images/icon15.png';
import icon3 from '../../images/icon11.png';
import icon4 from '../../images/icon12.png';
import icon5 from '../../images/icon16.png';
import icon6 from '../../images/icon17.png';
import icon7 from '../../images/icon18.png';
import officon from '../../images/off.png';
import profile from '../../images/profile-img.png';
export default function Profile() {

    const { account, signOut } = useContext(AccountContext)
    const navigate = useNavigate()



    return <>
        <div data-v-afc8f66f="" data-v-63f9c5de="" className="userInfo__container pt-2" >
            <div data-v-afc8f66f="" className="userInfo__container-content">
                <div data-v-afc8f66f="" className="userInfo__container-content-wrapper">
                    <div data-v-afc8f66f="" className="userInfo__container-content__avatar" onClick={()=>navigate('/updateAvatar')}>
                        <img data-v-afc8f66f="" src={"/avatar/av-"+account['avatar']+".png"} className="userAvatar" />
                    </div><div data-v-afc8f66f="" className="userInfo__container-content__name"><div data-v-afc8f66f="" className="userInfo__container-content-nickname">
                        <h6 data-v-afc8f66f="" className='text-white'>{account['name']}</h6>
                   

                    </div><div data-v-afc8f66f="" className="userInfo__container-content-uid mb-1"><span data-v-afc8f66f="">UID</span><span data-v-afc8f66f="">|</span><span data-v-afc8f66f="">{account['accountId']}</span><img data-v-afc8f66f="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAf2/fzyAQv1i/o5gAAABWSURBVBjTY0AHSYJgoApis1p0gEFzApDD1AKR8VAAchgbIaolBIA6xNvBOkAchA4gB6EDyEHooBOn0EMBweloDkCSUUP2ALrX2GBeCADJB0Fk1NCDCQANHCEHp1BAOwAAAABJRU5ErkJggg==" />
                        </div>
                        <div data-v-afc8f66f="" className="userInfo__container-content-logintime"> 
                        
                        {account['email'] && <span data-v-afc8f66f="">Email: {account['email']}</span>}
                        {account['phone'] && <span data-v-afc8f66f="">Phone: {account['phone']}</span>}
                         </div></div></div>

            </div>
        </div>
        <div data-v-562fa2cf=""
            className="financialServices__container-box">
            <div data-v-562fa2cf="" onClick={() => {
                navigate('/betRecords')
            }}>
                <img data-v-562fa2cf="" src={icon1} />
                <div data-v-562fa2cf="" className="financialServices__container-box-para"><h3 data-v-562fa2cf="" className='mb-0'>Bet</h3><span data-v-562fa2cf="">Betting history</span></div>
            </div>
            <div data-v-562fa2cf="" onClick={() => {
                navigate('/transaction_history')
            }}>
                <img data-v-562fa2cf="" src={icon2} />
                <div data-v-562fa2cf="" className="financialServices__container-box-para"><h3 data-v-562fa2cf="" className='mb-0'>Transaction</h3><span data-v-562fa2cf="">Transaction history</span></div>
            </div>
            <div data-v-562fa2cf="" onClick={() => {
                navigate('/transaction_history?type=deposit')
            }}>
                <img data-v-562fa2cf="" src={icon3} /><div data-v-562fa2cf="" className="financialServices__container-box-para"><h3 data-v-562fa2cf="" className='mb-0'>Deposit</h3><span data-v-562fa2cf="">Deposit history</span></div>
            </div>
            <div data-v-562fa2cf="" onClick={() => {
                navigate('/transaction_history?type=withdraw')
            }}>
                <img data-v-562fa2cf="" src={icon4} /><div data-v-562fa2cf="" className="financialServices__container-box-para"><h3 data-v-562fa2cf="" className='mb-0'>Withdraw</h3><span data-v-562fa2cf="">Withdraw history</span></div>
            </div>
        </div>
        <div data-v-9bbf0064="" data-v-63f9c5de="" className="settingPanel__container mt-2">
            <div data-v-9bbf0064="" className="settingPanel__container-items">
                <div data-v-9bbf0064="" className="settingPanel__container-items__item ar-1px-b"><div data-v-9bbf0064="" className="settingPanel__container-items__title">
                    <img data-v-9bbf0064="" src={icon5} />
                    <span data-v-9bbf0064="">Notification</span></div><div data-v-9bbf0064="" className="settingPanel__container-items-right"><i data-v-9bbf0064="" className="van-badge__wrapper van-icon van-icon-arrow" ></i>
                    </div></div>
                <div data-v-9bbf0064="" className="settingPanel__container-items__item ar-1px-b" onClick={()=> navigate('/gameStats')}><div data-v-9bbf0064="" className="settingPanel__container-items__title"><img data-v-9bbf0064="" src={icon6} /><span data-v-9bbf0064="">Game statistics</span></div><div data-v-9bbf0064="" className="settingPanel__container-items-right"><i data-v-9bbf0064="" className="van-badge__wrapper van-icon van-icon-arrow" ></i></div>
                </div>
                <div data-v-9bbf0064="" className="settingPanel__container-items__item ar-1px-b">
                    <div data-v-9bbf0064="" className="settingPanel__container-items__title"><img data-v-9bbf0064="" src={icon7} /><span data-v-9bbf0064="">Language</span></div>
                    <div data-v-9bbf0064="" className="settingPanel__container-items-right">
                        <span data-v-9bbf0064="">English</span>
                    </div>
                </div>
            </div>
        </div>
        <div data-v-5763c798="" className="serviceCenter-wrap-header">
            <button data-v-5763c798="" onClick={signOut}>
                <img data-v-5763c798="" className="" data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAQlBMVEUAAABlZWVmZmZlZWVmZmZmZmZmZmZkZGRmZmZlZWVra2tmZmZmZmZnZ2dmZmZgYGBmZmZmZmZlZWViYmJlZWVmZmYJxgEqAAAAFXRSTlMA799/v18fEI9vHz+fL68gT88wL2Dza9ruAAACiklEQVRo3u1YS2KrMAxE8hdMEmjr+1/1vbhpZxEcG4l0xSzB1li/EWY4ceKv4Yjd8E5wzjS8E/k/hgE4CU6CBkZi1yZwxKPI/Oeac6Y2Aeec2QuOn+/gNgGVhWan+WDLNuoJEZeldpcTt+9Nc+hJsp9z3hmmz+I2pZ4qghN02WffTv1l6lcw9NqP+/rA9DPcCFXRSQAG35tfs7+TTWemV9jvJwCDHYD6KiPToojcVZHua65SsSvdmVozN/MkJQhle2gFKMnlOjVk6YYgCudBfF2rMwIkJAh0l7CXDjjdRHP3Ff6VA9qRyYhyxQExAVygUH3FmqGPCTdW22TUEKDUbTVCk54g1Ow4MIsJEIn6cz3BiJM+JefjCAKfNy8RH3gsIWgfdYFOawmum/0UoYNyApiKm7TLMQRfm8GwmARyAqRz3RSpSUuAMuLNrUFLgF4mwVbNKjxslzi/lcAxOSEBIQcKQE8FVQSIqmiFgGiRIKdPAqIFZK0uIHoYmKoIiBIWsvY0D+Rojy46oIxwVMFMBiQzGc/1sJV6DFnfy/i+qjGbY4rUVmNHegKudmwgZTPj63qqfw3wEQ7MVRXUV6pDiitXHAqqEoIDW/CkVbwIBwT3ZEB2T8ZVXRUgbh/BikUCAVL8MWkE2HSdwojtr81lnguD0D53TBRPIgaToRENXEiQh4i/jr0Mq99Rnxb2uxi4xLNbWRfaYx+ZznOXE+Gakd9uhscm0zZvqCy1QVAVJU6+aV6o8p4fFHM1tik+zNuLbHhwfnDE9Hz2FH9ek3hIeUP5BzaO6VLyOE3LGO3vGzKaEeVHzhXAvBLuWrdu03AEwjJv+MHzVxiOg1/MbFcqltnO4+KHEydO9OIfEq1JSVrjUXcAAAAASUVORK5CYII=" src={officon} />
                Log out</button>
        </div>



    </>
}