import { useContext, useState } from "react"
import { AccountContext } from "../context/account_context"
import AviatorContext from "../context/aviator_context"


import logo from '../images/logo.png'



export default function Header({ inGamePlay = false }) {

    const [showHeader, setShowHeader] = useState(false)
    const [showLoginDialog, setShowLoginDialog] = useState(false)

    const [showSignupDialog, setShowSignupDialog] = useState(false)
    const [showForgotDialog, setShowForgotDialog] = useState(false)
    const [showGameRuleModel, setShowGameRuleModel] = useState(false)

    const [showTransactionHistoryModel, setShowTransactionHistoryModel] = useState(false)
    const [showMyBetsModel, setShowBetsModel] = useState(false)
    const [showSummaryModel, setShowSummaryModel] = useState(false)
    const [depositModel, showDepositModel] = useState(false)


    const [supportModel, showSupportModel] = useState(false)

    const [changeAvatarModel, showChangeAvatarModel] = useState(false)


    const { account, signOut, accountName,mainBalance } = useContext(AccountContext)



    return <header>

      
        <>
            <div className="header-top">
                <div className="header-left">
                    <a href="dashboard">
                        <span className="logo-crash"><img src={logo} /></span>
                    </a>
                </div>
                <div className="header-right d-flex align-items-center">
                <span  id="header_wallet_balance">{"0.00"}</span>
                    {/* <button className="deposite-btn me-1" hidden={!account}>
                      
                      
                        
                    </button>
                    */}

                  
                    <div className="btn-group" hidden={!account} onClick={() => setShowHeader(!showHeader)}>
                    <span className="material-symbols-outlined f-24 menu-icon" >
                                menu
                            </span>
                     
                        <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-dark profile-dropdown p-0 ${showHeader ? 'show' : ''}`}>
                            


                            {/* <li hidden={!inGamePlay}>
                                <a className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico">
                                            volume_mute
                                        </span>SOUND

                                    </div>
                                    <div>
                                        <div className="form-check form-switch lg-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="sound" checked={gameSetting()['sound']} onChange={(e) => setGameSetting({ ...gameSetting(), "sound": e.target.checked })} />
                                            <label className="form-check-label" for="sound"></label>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li hidden={!inGamePlay}>
                                <a className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-22" >
                                            music_note
                                        </span>MUSIC
                                    </div>
                                    <div>
                                        <div className="form-check form-switch lg-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="music" checked={gameSetting()['music']} onChange={(e) => setGameSetting({ ...gameSetting(), "music": e.target.checked })} />
                                            <label className="form-check-label" for="music"></label>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li hidden={!inGamePlay}>
                                <a className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            mode_fan
                                        </span>ANIMATION
                                    </div>
                                    <div>
                                        <div className="form-check form-switch lg-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="animation" checked={gameSetting()['animation']} onChange={(e) => setGameSetting({ ...gameSetting(), "animation": e.target.checked })} />
                                            <label className="form-check-label" for="animation"></label>
                                        </div>
                                    </div>
                                </a>
                            </li> */}
                            <li className="divider" >
                            </li>



                            <li>
                                <a href="javascript:void(0);" onClick={() => setShowGameRuleModel(true)} className="f-12 justify-content-between" data-bs-toggle="modal" data-bs-target="#game-rule">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            receipt_long
                                        </span>GAME RULES
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" onClick={() => setShowBetsModel(true)} className="f-12 justify-content-between" data-bs-toggle="modal" data-bs-target="#bet-history">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            history
                                        </span>MY BET HISTORY
                                    </div>
                                </a>
                            </li>
                            {/* <li>
                                <a href="javascript:void(0);" className="f-12 justify-content-between" data-bs-toggle="modal" data-bs-target="#game-limit">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            payments
                                        </span>GAME LIMITS
                                    </div>
                                </a>
                            </li> */}

                            <li>
                                <a href="/deposit" className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            payments
                                        </span>DEPOSIT FUNDS
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="/withdraw" className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            payments
                                        </span>WITHDRAW FUNDS
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" onClick={() => setShowTransactionHistoryModel(true)} className="f-12 justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <span className="material-symbols-outlined ico f-20">
                                            receipt_long
                                        </span>TRANSACTION HISTORY
                                    </div>
                                </a>
                            </li>
                            
                        </ul>
                    </div>




                </div>
            </div>
            {/* <div className="header-bottom " hidden={!inGamePlay}>
                <div className="header-left">
                    <img src="/logo.svg" className="logo" />
                </div>
                <div className="header-right d-flex align-items-center">

                    <div className="wallet-balance h-26">
                        <span id="wallet_balance">₹0.00</span>
                    </div>
                </div>
            </div> */}
        </>
    </header>
}