


export function PlayerItem({item}) {

    return <>
        <div className={`list-items ${item['status']=='cashout'?"active":""}`}>
            <div className="column-1 users"> <img src={`/avatar/av-${item['avatar']}.png`} className="avatar me-1" /> {item['accountAlias']} </div><div className="column-2">
                <button className="btn btn-transparent  d-flex align-items-center mx-auto"> {item['amount']+".00"}₹ </button></div>
                <div className="column-3"> {item['incrementor'] && (<div className="bg3 custom-badge mx-auto">{item['incrementor']}x</div>)} </div>
                <div className="column-4"> {item['cashout_amount']} </div>
        </div>
    </>
}