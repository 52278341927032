import { BottomSheet } from "react-spring-bottom-sheet";
import { useState } from "react";

export function LevelPickerBottomSheet({ open, onClose, onLevelSelect, selectedLevel }) {


    return <BottomSheet
        className="bottomSheet"
        onDismiss={onClose}
        open={open}>
        <div className="ps-2 pe-2 d-flex flex-column">

            <div onClick={() => {
                onLevelSelect(undefined)
            }} className={`level-selector-item ${selectedLevel == undefined ? "active" : ""}`}>
                All
            </div>

            {

                [1, 2, 3, 4, 5, 6].map(item => <div onClick={() => {
                    onLevelSelect(item)
                }} key={item} className={`level-selector-item ${selectedLevel == item ? "active" : ""}`}>
                    {item} Level
                </div>)
            }
        </div>

    </BottomSheet>
}
