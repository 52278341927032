import moment from "moment"
import { useContext } from "react";
import { CurrencyContext } from "../../../context/currencyContext";



const winningAmount = (item, result) => {

    let wonAmount = 0;
    const totalAmount = item.amountAfterTax

    if (item.value.toString() == result.number.toString()) {
        wonAmount = totalAmount * 9;
    }



    else if (item.value == result['bigSmall']) {
        wonAmount = totalAmount * 2;
    }

    else if (item.value == 'green') {
        if (result.number == 5)
            wonAmount = totalAmount * 1.5;
        else if (result.number % 2 != 0) wonAmount = totalAmount * 2
    }

    else if (item.value == 'red') {
        if (result.number == 0)
            wonAmount = totalAmount * 1.5;
        else if (result.number % 2 == 0) wonAmount = totalAmount * 2
    }


    else if (item.value == 'violet') {
        if (result.number == 0 || result.number == 5)
            wonAmount = totalAmount * 4.5;
    }
    return wonAmount;

}

export function BetItem({ item, setDetailsSection, detailsSection }) {

    const {convertCoin} = useContext(CurrencyContext)
    const result = item['result']
    let wonAmount = 0;
    let loseAmount = 0;

    if (result && result.number != undefined) {
        wonAmount = winningAmount(item, result)
        if(wonAmount==0) loseAmount = (item['amount'] * item['quantity'])
    }


    return <div className="bet-column">
        <div className="bet-row" onClick={() => {
            setDetailsSection(item['_id'])
        }}>
            <span className={`bet-leading leading${item['value']}`}>
                {item['value']}
            </span>
            <span className="bet-center flex-grow ">
                <span className="bet-title">{item['gameId']}</span>
                <span className="bet-timestamp">{moment(item.createdAt).format('yyyy-MM-DD, hh:mm:ss')}</span>
            </span>
            <div className="d-flex flex-column bet-trailing align-items-end">
                <span className={`${wonAmount > 0 ? "bet-status" : loseAmount > 0 ? "bet-status danger" : ""}`} >
                    {
                        wonAmount > 0 ? "Succeed" : loseAmount > 0 ? 'Failed' : ""
                    }
                </span>
                <span className={`bet-amount ${loseAmount>0 ? "danger" : ""}`}>
                    {
                        wonAmount > 0 ? "+" + convertCoin(wonAmount) : loseAmount > 0 ? "-" + convertCoin(loseAmount) : ""
                    }
                </span>
            </div>


        </div>
        <div hidden={detailsSection != item['_id']}>
            <div data-v-373b3197="" className="MyGameRecordList__C-detail">
                <div className="MyGameRecordList__C-detail-divider"></div>
                {/* <div data-v-373b3197="" className="MyGameRecordList__C-detail-text">Details</div> */}
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Order number<div data-v-373b3197="">{item['_id']} <img data-v-373b3197="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAhFBMVEUAAABRUVFQUFBRUVFRUVFRUVFRUVFRUVFQUFBRUVFQUFBRUVFQUFBQUFBRUVFRUVFSUlJSUlJRUVFQUFBSUlJRUVFRUVFRUVFRUVFRUVFRUVFQUFBRUVFRUVFRUVFRUVFQUFBRUVFRUVFRUVFQUFBQUFBQUFBSUlJYWFhJSUlQUFBRUVGJ3MxyAAAAK3RSTlMAv0B6VerZrqiblYmCaGJIOiQdFg/79vDl39TKxbq0oY9zblxONC4pCQTPqkRvegAAAWZJREFUeNrtz0duw0AQAEGSzjnnnIP+/z8ffJOBgRfgiCts9Qca1UmSNGZDP0FDN37DbIJAQH4DAQGJAwEBiQMBAYlbTsjQLWcgtQVSWyC1BVJbILUFUlsgtdUQZJiyMSGzKRsTclbwBQEpgJwXfEFACiAXBV8QkALIWsEXBKRFyGXBF2QKSD/k1WdCruYhXV4gTUHWQUBAQsg1CEgO5BukMsgNCEgO5BYEJAfSg4CAhJA7EJAcyD0ISA5kAwQEJIRsgoCAhJAHEJAcyBYICEgI2QYBAQkhjyAgOZAdEBCQELILAgISQlZAQEDagDyBgORAnkFAciB7ICAgIWQfBAQkhLyAgORAVkEWC+nnWlbI30Bqh7yCgORADkBAQMIGEBCQNiCHICAgYW8gIDmQdxCQHMgHCEgO5AgEBCTsGKRySGog/+ik4AsC0iLktOALAtIi5LPgCwJS0FfBFwSkpH7COkmSMvoBUQl8xsUGEfcAAAAASUVORK5CYII=" /></div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Period <div data-v-373b3197="">{item['gameId']}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Purchase amount <div data-v-373b3197="">{convertCoin(item['amount'])}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Quantity <div data-v-373b3197="">{item['quantity']}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Amount after tax <div data-v-373b3197="" className="red">{convertCoin(item['amountAfterTax'])}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Tax <div data-v-373b3197="">{convertCoin(item['tax'])}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Result <div data-v-373b3197="">{result && (result.number +" " + result['bigSmall'] +" "+result['colors'].join(" "))}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Select <div data-v-373b3197="">{item['value']}</div></div>
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Status <div data-v-373b3197="">{(loseAmount > 0 ? "lossing" : wonAmount > 0 ? "winning" :  "pending")}</div></div>
                {/* <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Win/lose <div data-v-373b3197="" className={item['status'] == 'success' ? 'green' : 'red'} >{item['status'] == 'success' ? "+" + convertCoin(item['wonAmount']) : item['status'] == 'failed' ? "-" + convertCoin(item['amount'] * item['quantity']) : "-"}</div></div> */}
                <div data-v-373b3197="" className="MyGameRecordList__C-detail-line">Order time <div data-v-373b3197="">{moment(item['createdAt']).format('DD-MMM-YY hh:mm A')}</div></div>
            </div>
        </div>
    </div>
}