import { useContext, useEffect, useState } from "react"

import { AccountContext } from '../../context/account_context'
import Invitation from '../../images/Invitation.png'
import icon1 from'../../images/icon1.png'
import icon2 from'../../images/icon2.png'
import icon3 from'../../images/icon3.png'
import icon4 from'../../images/icon4.png'
import icon5 from'../../images/icon5.png'
import icon6 from'../../images/icon6.png'
import { useNavigate } from 'react-router-dom'
import { getRequest } from "../../api/api"
import { ToastContainer, toast } from 'react-toastify';

import logo from '../../images/logo.png'
import { CurrencyContext } from "../../context/currencyContext"

export default function PromotionPage() {
    const {convertCoin} = useContext(CurrencyContext)

    const { account, syncUser } = useContext(AccountContext)
    const [promotionData, setPromotionData] = useState({ loading: true, data: undefined, error: undefined })
    const [yesterdayData, setYesterdayData] = useState({})

    const navigate = useNavigate()
    useEffect(() => { syncUser() }, [])


    const fetchYesterdayData = async () => {
        try {
            let endpoint = "commision/yesterday";
            const _data = await getRequest(endpoint)
            setYesterdayData(_data)
        } catch (e) {
            setYesterdayData(undefined)
        }
    }



    const fetchPromotionData = async () => {
        try {
            let endpoint = "commision/promotion-stats";
            //let endpoint = "commision/yesterday";
            const _data = await getRequest(endpoint)
            setPromotionData({ data: _data, loading: false, error: undefined })
        } catch (e) {
            setPromotionData({ data: undefined, loading: false, error: e })
        }
    }

    useEffect(() => {
        fetchYesterdayData()
        fetchPromotionData()
    }, [])



    return <>

        <div data-v-905dfe8c="" data-v-fe2fea6f="" className="navbar">
            <div data-v-905dfe8c="" className="navbar-fixed">
                <div data-v-905dfe8c="" className="navbar__content">
                    <div data-v-905dfe8c="" className="navbar__content-left">
                    </div>
                    <div data-v-905dfe8c="" className="navbar__content-center">
                             <img src={logo}  style={{marginLeft:'8px'}}/>
                        <div data-v-905dfe8c="" className="navbar__content-title"></div>
                    </div>
                    <div data-v-905dfe8c="" className="navbar__content-right"></div>
                </div>
            </div>
        </div>


        <div data-v-cc60d719="" data-v-5b14ba80="" className="container">
            <div data-v-cc60d719="" className="amount">{
                convertCoin((yesterdayData && yesterdayData['yesterdayCommission']) ?? 0)
            }</div>
            <div data-v-cc60d719="" className="amount_txt">Yesterday's total commission</div>
            <div data-v-cc60d719="" className="tip">Upgrade the level to increase commission income</div>


            <div data-v-cc60d719="" className="info_content">
                <div data-v-cc60d719="" className="info">
                    <div data-v-cc60d719="" className="head">Direct subordinates</div>
                    <div data-v-cc60d719="" className="line1"><div data-v-cc60d719="">{yesterdayData && yesterdayData['direct'] && (yesterdayData['direct'].registerCount ?? 0)}</div>
                        number of register</div>
                    <div data-v-cc60d719="" className="line2">
                        <div data-v-cc60d719="">{yesterdayData && yesterdayData['direct'] && (yesterdayData['direct'].depositCount ?? 0)}</div> Deposit number</div>
                    <div data-v-cc60d719="" className="line3">
                        <div data-v-cc60d719="">{yesterdayData && yesterdayData['direct'] && (convertCoin(yesterdayData['direct'].depositAmount ?? 0))}</div> Deposit amount</div>
                    <div data-v-cc60d719="" className="line1"><div data-v-cc60d719="">{yesterdayData && yesterdayData['direct'] && (yesterdayData['direct'].firstDepositCount ?? 0)}</div> Number of people making first deposit</div>


                </div><div data-v-cc60d719="" className="info"><div data-v-cc60d719="" className="head u2">Team subordinates</div>
                    <div data-v-cc60d719="" className="line1"><div data-v-cc60d719="">{yesterdayData && yesterdayData['indirect'] && (yesterdayData['indirect'].registerCount ?? 0)}</div> number of register</div>
                    <div data-v-cc60d719="" className="line2"><div data-v-cc60d719="">{yesterdayData && yesterdayData['indirect'] && (yesterdayData['indirect'].depositCount ?? 0)}</div> Deposit number</div>
                    <div data-v-cc60d719="" className="line3"><div data-v-cc60d719="">{yesterdayData && yesterdayData['indirect'] && (convertCoin(yesterdayData['indirect'].depositAmount ?? 0))}</div> Deposit amount</div>
                    <div data-v-cc60d719="" className="line1"><div data-v-cc60d719="">{yesterdayData && yesterdayData['indirect'] && (yesterdayData['indirect'].firstDepositCount ?? 0)}</div> Number of people making first deposit</div></div>
            </div>
        </div>


        <div data-v-5b14ba80="" className="envenlop" ><div data-v-5b14ba80="" className="mycodetitle"><i data-v-5b14ba80="" className="van-badge__wrapper van-icon icon"><img className="van-icon__image"
            src={Invitation} /></i>
            <span data-v-5b14ba80="" className="text">Invitation code</span></div>
            {
                (!account['referral'] || !account['referral'].code) && <div>

                    <div data-v-5b14ba80="" className="text">Deposit First to activate link!</div>
                </div>
            }
            {account['referral'] && account['referral'].code && <div data-v-5b14ba80="" className="mycode">
                <div data-v-5b14ba80="" className="text">{
                    account['referral'] && account['referral'].code
                }</div>
                <div onClick={async () => {
                    await navigator.clipboard.writeText(account['referral'].code)
                    toast.success("Copied!")

                }} data-v-5b14ba80="" className="copy"><i data-v-5b14ba80="" className="van-badge__wrapper van-icon">
                        <img className="van-icon__image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAf2/fzyAQv1i/o5gAAABWSURBVBjTY0AHSYJgoApis1p0gEFzApDD1AKR8VAAchgbIaolBIA6xNvBOkAchA4gB6EDyEHooBOn0EMBweloDkCSUUP2ALrX2GBeCADJB0Fk1NCDCQANHCEHp1BAOwAAAABJRU5ErkJggg==" /></i>
                </div>
            </div>}
        </div>


        <div data-v-5b14ba80="" className="content" ><div data-v-5b14ba80="" className="shareBtnContainer">
            <button onClick={async () => {

                if(!account['referral'] || !account['referral'].code) return toast.error('Deposit first to activate link');
                var url = process.env.REACT_APP_INVITE_LINK + account['referral']['code']
                await navigator.clipboard.writeText(url)
                toast.success("Copied!")
            }} data-v-5b14ba80="" className="shareBtn">INVITATION LINK</button>
        </div>


            <div data-v-5b14ba80="" className="promote__cell">
                <div data-v-5b14ba80="" className="promote__cell-item" onClick={() => {
                    navigate('/subordinate')

                }}><div data-v-5b14ba80="" className="label"><img data-v-5b14ba80="" src={icon1} alt="" />
                        <span data-v-5b14ba80="">Subordinate data</span>
                    </div>
                    <div data-v-5b14ba80="" className="arrow"><img data-v-5b14ba80="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII=" alt="" />
                    </div>
                </div>
                <div onClick={() => {
                    navigate('/commision')
                }} data-v-5b14ba80="" className="promote__cell-item"><div data-v-5b14ba80="" className="label"><img data-v-5b14ba80="" src={icon2} alt="" /><span data-v-5b14ba80="">Commission detail</span></div><div data-v-5b14ba80="" className="arrow"><img data-v-5b14ba80="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII=" alt="" />
                    </div>
                </div>


                <div data-v-5b14ba80="" onClick={() => navigate('/new-subordinate')} className="promote__cell-item"><div data-v-5b14ba80="" className="label"><img data-v-5b14ba80="" src={icon3} alt="" />
                    <span data-v-5b14ba80="">New subordinates</span></div><div data-v-5b14ba80="" className="arrow"><img data-v-5b14ba80="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII=" alt="" />
                    </div>
                </div>


                <div data-v-5b14ba80="" className="promote__cell-item" onClick={() => navigate('/rules')}><div data-v-5b14ba80="" className="label"><img data-v-5b14ba80="" src={icon4} alt="" />
                    <span data-v-5b14ba80="">Invitation rules</span></div><div data-v-5b14ba80="" className="arrow">

                        <img data-v-5b14ba80="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAgMAAAAqbBEUAAAADFBMVEUAAABmZmZkZGRmZmZ0zVwQAAAAA3RSTlMA3yDHuD3GAAAANUlEQVQoz2MYhIC1AYnDnoDE4byBxGEqQ9Y0F1mTOk01YXAQyjANQBiNsJRGWtgTUANxMAIAk88RuZxQh1MAAAAASUVORK5CYII=" alt="" />
                    </div>
                </div>
              
            </div>


            {promotionData['data'] && <div data-v-5b14ba80="" className="commission"><div data-v-5b14ba80="" className="commission__title"><i data-v-5b14ba80="" className="van-badge__wrapper van-icon"><img className="van-icon__image" src={icon6} /></i>
                <span data-v-5b14ba80="">promotion data</span></div><div data-v-5b14ba80="" className="commission__body"><div data-v-5b14ba80=""><span data-v-5b14ba80="">{convertCoin(promotionData['data'].weekAmount??0)}</span><span data-v-5b14ba80="">This Week</span></div><span data-v-5b14ba80=""></span><div data-v-5b14ba80=""><span data-v-5b14ba80="">{convertCoin(promotionData['data'].totalCommissioin??0)}</span>
                    <span data-v-5b14ba80="">Total commission</span>
                </div></div>
                <div data-v-5b14ba80="" className="commission__body"><div data-v-5b14ba80=""><span data-v-5b14ba80="">{promotionData['data'].directSubordinate}</span>
                    <span data-v-5b14ba80="">direct subordinate</span></div><span data-v-5b14ba80=""></span><div data-v-5b14ba80=""><span data-v-5b14ba80="">{promotionData['data'].indirectSubordinate + promotionData['data'].directSubordinate}</span><span data-v-5b14ba80="">Total number of subordinates in the team</span></div></div>
            </div>}

        </div>
    </>
}