import { useContext, useEffect, useState } from "react"
import { NavBar } from "../../widgets/navBar"

import { getRequest } from "../../api/api";
import { Spinner } from "react-bootstrap";
import { LoadingPage } from "../../widgets/loading_page";
import moment from 'moment'
import { DatePickerBottomSheet } from "../../widgets/datePickerBottomSheet";
import { FaAngleDown } from 'react-icons/fa'
import { EmptyWidget } from "../../widgets/empty_widget";
import { CurrencyContext } from "../../context/currencyContext";

export const CommisionPage = () => {
    const {convertCoin} = useContext(CurrencyContext)
    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })
    const todayDate = new Date()
    todayDate.setDate(todayDate.getDate() - 1)

    const maximumDate = {
        day: todayDate.getDate(),
        month: todayDate.getMonth() + 1,
        year: todayDate.getFullYear()
    }

    const [dateSheet, showDateSheet] = useState(false)
    const [selectedDate, setSelectedDate] = useState(maximumDate)

    const fetch = async () => {
        try {
            let endpoint = "commision/commission?";
            endpoint += "date=" + `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;
            const subordinate = await getRequest(endpoint)
            setData({ data: subordinate, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }


    useEffect(() => {
        fetch()
    }, [selectedDate])


    if (data['loading']) return <LoadingPage />

    if (data['error']) return <div> ERROR</div>


    const commisionData = data['data']
    return <div>

        <NavBar title={"Commisioin"} />

        <div data-v-f29e8dc2="" className="TeamReport__C-head">
            <div data-v-f29e8dc2="" className="TeamReport__C-head-fixed">
                <div data-v-f29e8dc2="" className="TeamReport__C-head-line2 commision-head-container">

                    <div data-v-f29e8dc2="" className="commision-date-picker" onClick={() => {
                        showDateSheet(true)
                    }}><span data-v-f29e8dc2="" className="default">{selectedDate && (selectedDate['year'] + "-" + selectedDate['month'] + "-" + selectedDate['day'])}</span>
                        <i data-v-f29e8dc2="" className="van-badge__wrapper van-icon van-icon-arrow-down"><FaAngleDown></FaAngleDown></i>
                    </div>
                </div>
            </div>
        </div>

        {data.data.length == 0 && <div className="h-100 d-flex  flex-column justify-content-center align-items-center"> <EmptyWidget /></div>}

        <div className="commisions-box mt-3">
            {
                commisionData.map(item => {
                    return <Item item={item} date={selectedDate}/>
                })
            }
        </div>

        <DatePickerBottomSheet
            open={dateSheet} onDateSelect={(date) => {
                setSelectedDate(date)
                showDateSheet(false)

            }} maximumDate={maximumDate} onClose={() => { showDateSheet(false) }} />

    </div>
}




function Item({ item,date }) {
    const {convertCoin} = useContext(CurrencyContext)
    return <div data-v-f29e8dc2="" className="TeamReport__C-body-item mb-2"><div data-v-f29e8dc2="" className="TeamReport__C-body-item-head">
        <div data-v-f29e8dc2="" style={{fontSize:"12px"}} className="title-time">Time: {`${date['day'] }-${date['month']}-${date['year'] }`}</div></div><div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail">
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-lv">Bettors<span data-v-f29e8dc2="">{item['bettors']}</span></div>
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-commission">Bet amount<span data-v-f29e8dc2="">{convertCoin(item['betAmount'] ?? 0)}</span></div>
            <div data-v-f29e8dc2="" className="TeamReport__C-body-item-detail-commission">Commission<span data-v-f29e8dc2="">{convertCoin(item['commission'] ?? 0)}</span></div>
          
        </div>
    </div>
}