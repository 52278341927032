import { useContext, useEffect, useState } from "react";
import { getRequest } from "../../api/api";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { NavBar } from "../../widgets/navBar";
import { EmptyWidget } from "../../widgets/empty_widget";
import { CurrencyContext } from "../../context/currencyContext";

export function WithdrawRequest() {
    const { convertCoin, convertCoinWithoutFormat } = useContext(CurrencyContext)

    const [apiData, setApiData] = useState({ loading: true, error: null, data: undefined })


    const fetchHistory = () => {
        let endpoint = "withdraw/requests";
        getRequest(endpoint)
            .then(res => {
                debugger
                setApiData({ loading: false, data: res })

            })
            .catch(e => {
                setApiData({ loading: false, error: e })
            })
    }


    useEffect(() => {
        fetchHistory()
    }, [])



    if (apiData['loading']) return <div className="d-flex h-100 justify-content-center align-items-center w-100">
        <Spinner size="sm" className="page-loading-spinner"></Spinner>
    </div>
    if (apiData['error']) return <div>Error: </div>


    return <div className="rechargeh__container pt-4">

        <NavBar title={'Transction Histroy'} ></NavBar>
        <div className="h-100 d-flex  flex-column justify-content-center align-items-center"> <EmptyWidget /></div>
        <div className="rechargeh__container-content pb-3" data-v-17ffea4a>
            {
                apiData['data'].transactions.map(item => {
                    return <WithdrawItem item={item} />
                })
            }
        </div>
    </div>
}


function WithdrawItem({ item }) {
    return <div data-v-17ffea4a="" className="rechargeh__container-content__item" >
        <div data-v-17ffea4a="" className="rechargeh__container-content__item-header">
            <span data-v-17ffea4a="" className="recharge_tit">{statusLabel(item)}</span></div>
        <div data-v-17ffea4a="" role="separator" className="van-divider van-divider--hairline divier"></div>
        <div data-v-17ffea4a="" className="rechargeh__container-content__item-body">
            <div data-v-17ffea4a="">
                <span data-v-17ffea4a="">Balance</span><span data-v-17ffea4a="" className="price">{item['amount'] + item['currency']}</span></div>
            {/* <div data-v-17ffea4a=""><span data-v-17ffea4a="">Closing</span><span data-v-17ffea4a="">₹{fetchClosingBalance(item)}</span></div> */}
            <div data-v-17ffea4a=""><span data-v-17ffea4a="">Address</span><span data-v-17ffea4a="">{item['address']}</span></div>
            <div data-v-17ffea4a=""><span data-v-17ffea4a="">Time</span><span data-v-17ffea4a="">{moment(item.createdAt).format('yyyy-MM-DD hh:mm:ss A')}</span></div>
            <div data-v-17ffea4a=""><span data-v-17ffea4a="">Order number</span><div data-v-17ffea4a="" className="order"><span data-v-17ffea4a="" className="me-1">{item['_id']}</span>
                <img data-v-17ffea4a="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAMFBMVEUAAACvsMetr8Wqr8KusMausMarr8OvsMevsMavscelr8WusMausMausMasr8ausMY46WCNAAAAD3RSTlMAn2Ag75BAv69/EN/Pv1Dhl63OAAAAc0lEQVQoz2MgDqwUhAJRA4jA+f8w4Azms/1WggKNr2ABpo9AAsHCFEhS/A5Wr2cAEWCHm/gTIqDvCLVT4v8CsIC8Alg7mDWMBApFPqEK/P9/AVVASI0BzQwGrALxTbBI2J8AFjCBh+kfiELmQFhEziIu4gFZQkr21tMcBQAAAABJRU5ErkJggg==" alt="" />
            </div>
            </div>

          {item['rejectRemark'] && <div data-v-17ffea4a="" className="reject_remark">{item['rejectRemark']}</div>}
        </div></div>
}



const statusLabel = (item) => {

    switch (item['status']) {
        case "rejected": return 'Rejected'
        case "completed": return 'Completed'
        case 'pending': return 'Pending'
        default: return 'Other'
    }
}