import { createContext, useContext, useEffect, useState } from "react";
import { getRequest, makeSubmitRequest } from "../api/api";
import currency from 'currency.js'
import fx from 'money'
import { CurrencyContext } from "./currencyContext";
export const AccountContext = createContext()
export function AccountContextProvider({ children }) {

    const [account, setAccount] = useState(JSON.parse(localStorage.getItem('account')))
    const [wallet,setWallet] = useState(JSON.parse(localStorage.getItem('wallet')))
    
    const {convertCoin} = useContext(CurrencyContext)


    const signIn = (newAccount, token) => {
        console.log("singIn",newAccount)
        localStorage.setItem('account', JSON.stringify(newAccount))
        localStorage.setItem('wallet', JSON.stringify(newAccount['wallet']))
        localStorage.setItem('token', token)
        setAccount(newAccount)
        setWallet(newAccount['wallet'])
    }

    const signOut = () => {
        localStorage.clear()
        setAccount(undefined)
        setWallet(undefined)
    }


    const updateWinningAmount = (amount) => {
        setWallet((oldWalletState)=>{
            const newWalletState = {...oldWalletState}
            var newWinningBalance= currency(newWalletState['winning']).add(amount).value
            newWalletState['winning'] = newWinningBalance;
            localStorage.setItem('wallet', JSON.stringify(newWalletState))
            return newWalletState;
        })
    }

    const changeAvatar= (avatarIndex)=>{
        setAccount(oldState=>{
            const newState = {...oldState,"avatar": avatarIndex}
            localStorage.setItem('account', JSON.stringify(newState))
            return newState;
        })
     
       // setAccount(account)
       // localStorage.setItem('account', JSON.stringify(account))
        
    }


    const accountName = ()=>{
        const accountId = account['accountId']
        const lastTwoDigit = accountId.slice(accountId.length-3, accountId.length -1 )
        return account['name'].charAt(0).toLowerCase()+"***"+lastTwoDigit
    }





    const syncWalletBalance = async ()=>{
        try{
            const response = await getRequest('users/wallet')
            const newWalletState = response.wallet
            setWallet(newWalletState)
            localStorage.setItem('wallet', JSON.stringify(newWalletState))
        }catch(e){
            debugger
        }
    }

    const mainBalance = ()=>{
        var balance =  currency(wallet['amount'],{ precision: 6 }).add(wallet['winning'],{ precision: 6 }).value
        return convertCoin(balance)
    }

    const depositAmountFormatted = ()=>{
        var balance =  wallet['amount']
        return convertCoin(balance)
   
    }

    const depositAmount = ()=>{
        var balance =  wallet['amount']
        return convertCoin(balance)

    }


    const bonusBalance = ()=>{
        var balance =  currency(wallet['bonus'],{ precision: 6 }).value
        return convertCoin(balance)
    }


    const syncUser = async ()=>{
        try{
            const response = await getRequest('users/details')
            setAccount(response)
            localStorage.setItem('account', JSON.stringify(account))
        }catch(e){
            
        }
    }




    return <AccountContext.Provider value={{ account, signOut, signIn, updateWinningAmount,changeAvatar,accountName,syncWalletBalance,wallet,syncUser,mainBalance,bonusBalance,depositAmount,depositAmountFormatted}}>
        {children}
    </AccountContext.Provider>
}