import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { isEmail } from "../../utils/utils";
import pagebanner from "../../images/game-bnr.jpg";
import { toast } from "react-toastify";
import { AccountContext } from "../../context/account_context";
import { makeSubmitRequest } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import InputGroup from 'react-bootstrap/InputGroup';
import { FaAngleDown } from "react-icons/fa";
import { ContryCodesSheet } from "../../models/countryCodesSheet";
export function LoginPage() {



  const navigate = useNavigate();

  const { signIn } = useContext(AccountContext);
  const [countrySheet, setShowCountrySheet] = useState(false)
  const [countryCode, setCountryCode] = useState("+91")

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCloseLocal = () => {
    setData({});
    setLoading(false);
  };

  const handleSignIn = (e) => {
    console.log('handleSignIn')
    e.preventDefault();
    setLoading(true);

    const body = {...data}

    if(body['phone']){
      body['phone'] = countryCode + body['phone']
    }

    makeSubmitRequest("users/login", body)
      .then(async (data) => {
        setLoading(false);
        toast.success("Sign in successfully");
        signIn(data["user"], data["token"]);
        handleCloseLocal();
        navigate("/");
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e.message);
      });
  };

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [toggle, setToggle] = useState(1)


  function updateToggle(id) {
    setData((oldState) => {
      const password = oldState['password']
      return { "password": password }
    })

    setToggle(id)
  }

  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordEye = () => {
    setShowPassword(!showPassword)
  }

  return (
    <>

      <ContryCodesSheet open={countrySheet} onClose={() => setShowCountrySheet(false)} onSelectCountry={(code) => {
        setCountryCode(code)
        setShowCountrySheet(false)
      }}> </ContryCodesSheet>

      <div className="text-white">
        <div className="regi_bnr">
          <h6 className="fw-bold mb-2 text-uppercase text-center pt-2">Welcome To <span style={{ color: '#00ff66' }}> VpGames </span></h6>
          <p>START YOUR GAME JOURNEY NOW</p>
        </div>
        {/* <img src={pagebanner}></img> */}
        <h4 className="fw-bold mb-2 text-uppercase text-center pt-2 fs-25">Login</h4>
        {/* <h6 className=" mb-4 f-20 text-center">Please enter your login and password!</h6> */}

        <div className="mb-3">
          <ul className="d-flex m-0 tab-btn-design">
            <li className={toggle == 1 ? "active" : ""} onClick={() => updateToggle(1)}>Phone</li>
            <li className={toggle == 2 ? "active" : ""} onClick={() => updateToggle(2)}>Email</li>
          </ul>


          <Form onSubmit={handleSignIn}>


            {toggle == 1 && <div style={{ position: 'relative' }}>
              <Form.Group className="form-group">
                {/* <Form.Label className="form-label mrbl">Phone Number</Form.Label> */}
                <span className="county_code" onClick={() => setShowCountrySheet(true)}>{countryCode}<FaAngleDown /></span>
                <Form.Control
                  className="app-input LS-input pl_40"
                  required
                  defaultValue={data['phone']}
                  name="phone"
                  type="number"
                  placeholder="Enter Phone Number"
                  bsPrefix="form-control system_input"
                  onChange={onInputChange}
                  id="basic-addon1"

                />
              </Form.Group>
            </div>}

            {toggle == 2 && <div style={{ position: 'relative' }} hidden={toggle == 1}>
              <Form.Group className="form-group">

                <Form.Control
                  className="app-input LS-input"
                  required
                  name="email"
                  defaultValue={data['email']}
                  type="email"
                  placeholder="Enter Email"
                  bsPrefix="form-control system_input"
                  onChange={onInputChange}
                  id="basic-addon1"

                />
              </Form.Group>
            </div>}


            <div style={{ position: 'relative' }} className="mt-2">
              <Form.Group
                className="form-group"
                controlId="formBasicPassword"
              >
                {/* <Form.Label className="form-label mrbl">Password</Form.Label> */}
                <Form.Control
                  className="app-input LS-input "
                  required
                  defaultValue={data['password']}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  bsPrefix="form-control system_input"
                  onChange={onInputChange}
                />
              </Form.Group>
              <span onClick={togglePasswordEye} className='icon_hide_show'>
                {showPassword ? <FaEye></FaEye> : <FaEyeSlash />}
              </span>
            </div>
            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
              <p className="small text-center" onClick={() => { }}>
                <a onClick={() => {
                  navigate("/resetPassword");
                }} className="text-primary fw-bold cursor-pointer mt-2"  >
                  <b style={{ color: "#00ff66" }}>Forgot password?</b>
                </a>
              </p>
            </Form.Group>
            <div className="d-grid">
              <Button
                disabled={loading}
                variant="primary"
                type="submit"
                className="system_button app-button LSbtn"
              >
                Login
              </Button>
            </div>
          </Form>

          <div className="mt-3">
            <p className="mb-0  text-center">
              Don't have an account?{" "}
              <span
                onClick={() => navigate("/signup")}
                className="text-primary fw-bold cursor-pointer"
              >
                <b style={{ color: "#00ff66" }}> Sign Up</b>
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}