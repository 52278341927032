import {useEffect, useReducer} from "react";

const url = process.env.REACT_APP_SERVER_URL;



//admin
//ImThePass



const initialState = {
    loading: true,
    error: undefined,
    data: undefined
};

function apiReducer(state, action) {
    switch (action.type) {
        case "DATA_FETCH_START":
            return {...state, loading: true};
        case "DATA_FETCH_FAILURE":
            return {...state, loading: false, error: action.payload};
        case "DATA_FETCH_SUCCESS":
            return {...state, loading: false, data: action.payload};
        default:
            return state;
    }
}

export function useFetch(endpoint, initialData = []) {
    const token = localStorage.getItem("token");
    const [data, dispatch] = useReducer(apiReducer, initialState);
    useEffect(() => {
        dispatch({type: "DATA_FETCH_START"});
        fetch(url + endpoint, {
            method: "GET",
            headers: new Headers({
                authorization: token,
                "Content-Type": "application/json",
            }),
        })
            .then(async response => {
                if (!response.ok) {
                    const error = await response.text();
                    throw {
                        status: response.status,
                        error: parseError(error),
                    };
                }
                return response.json();
            })
            .then(json => {
                dispatch({type: "DATA_FETCH_SUCCESS", payload: json});
            })
            .catch(error => {
                dispatch({type: "DATA_FETCH_FAILURE", payload: error});
            });
    }, initialData);
    return data;
}



export async function getRequest(endpoint){
    const token = localStorage.getItem("token");
    return await fetch(url + endpoint, {
        method: "GET",
        headers: new Headers({
            authorization: token,
            "Content-Type": "application/json",
        }),
    })
        .then(async response => {
            if (!response.ok) {
                const error = await response.text();
                throw {
                    status: response.status,
                    error: parseError(error),
                };
            }
            return response.json();
        })
        .then(json => {
            return json
        })
        .catch(error => {
          throw error
        });
}


export async function makeSubmitRequest(endpoint, body,method="POST") {
    const token = localStorage.getItem("token");
    console.log(body,'Body')
    return fetch(url + endpoint, {
        method: method,
        headers: new Headers({
            authorization: token,
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(body),
    }).then(async response => {
        if (!response.ok) {
            const error = await response.text();
            throw {
                status: response.status,
                message: parseError(error),
            };
        }
        return response.json();
    })
}


export async function useDelete(endpoint) {
    const token = localStorage.getItem("token");
    return fetch(url  + endpoint, {
        method: "DELETE",
        headers: new Headers({
            authorization: token,
            "Content-Type": "application/json",
        }),
    }).then(async response => {
        if (!response.ok) {
            const error = await response.text();
            throw {
                status: response.status,
                message: parseError(error),
            };
        }
        return response.json();
    })
}



export async function useFetchRequest(endpoint) {
    const token = localStorage.getItem("token");

    const response =  await fetch(url + endpoint, {
        method: "GET",
        headers: new Headers({
            authorization: token,
            "Content-Type": "application/json",
        }),
    })
    if (!response.ok) {
        const error = await response.text();
        throw {
            status: response.status,
            error: parseError(error),
        };
    }
    return response.json();

        
}



function parseError(str) {
    try {
        const json = JSON.parse(str);
        return json.message;
    } catch (e) {
        return str || "Oops! Something went wrong";
    }
}
