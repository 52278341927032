import { Col, Container, Row, Tab, Tabs, Toast, ToastContainer } from "react-bootstrap";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { AppTab } from "../../widgets/app_tab";
import { PlayerItem } from "../../widgets/player_item";
import { BetControl } from "../../widgets/bet_control";
import { MdAdd, MdHistory, MdRemove, MdClose, MdWorkHistory, MdArrowDropUp, MdArrowDropDown } from "react-icons/md"
import Footer from "../../widgets/footer";
import AviatorContext from "../../context/aviator_context";
import Header from "../../widgets/header";
import moment from "moment";
import { CanvasView } from "./crashCanvasView";
import CounterIncrementor from "./intermentCounter";
import "./crash.css"
function CrahGamePage({ props }, ref) {

    const { currentGame } = useContext(AviatorContext)
    //const { gamePlayRef, stageBoardRef, rotateImageRef, canvasRef } = ref;
    const stageBoardRef = useRef()

    return <>
        <Header inGamePlay={true} />
        <div className="crash-container">
            <div className="right-sidebar">
                <div className="game-play">
                    <HistoryContainer />

                    <div className="stage-board" ref={stageBoardRef}>

                        <LoadingGameView currentGame={currentGame} />
                        <GameOverView currentGame={currentGame} />
                        <CanvasView currentGame={currentGame} />

                    </div>

                    <BetControl main={true} />
                </div>
            </div>
            <div className="left-sidebar">
                <PlayersContainer />
            </div>
        </div>
        {/* <Footer/> */}

        <audio id="background_audio">
            <source src="/audio/background.mp3" type="audio/mpeg" />
        </audio>
        <audio id="fly_plane_audio">
            <source src="/audio/game-start.mp3" type="audio/mpeg" />
        </audio>

        <audio id="play_crash_audio">
            <source src="/audio/plane-crash.mp3" type="audio/mpeg" />
        </audio>


        <audio id="cashout_audio">
            <source src="audio/cashout.mp3" type="audio/mpeg" />
        </audio>
        <audio id="cashout_audio2">
            <source src="audio/cashout_2.mp3" type="audio/mpeg" />
        </audio>

    </>
}


export default forwardRef(CrahGamePage)





function PlayersContainer(ref) {
    const [activeTab, setActiveTab] = useState(1)

    return <>

        {/* <AppTab tabs={['All Bets', "My Bets"]} activeTab={activeTab} tabChangeListener={(tab) => {
            setActiveTab(tab)
        }} /> */}

        <ul className="d-flex payer-tabs">
            <li className={activeTab == 1 ? "active" : ""} onClick={() => { setActiveTab(1) }}>All Bets</li>
            <li className={activeTab == 2 ? "active" : ""} onClick={() => { setActiveTab(2) }}>My Bets</li>
        </ul>


        <div className="contents-blocks">
            <div className="tab-content">


                {
                    activeTab == 1 ? <OtherPlayersTab /> : <MyBetsTab />
                }
            </div>
        </div>

        <div className="footer-tab">
            <div className="footer-indiv d-flex align-center">
                <div className="indiv-2 d-flex align-center">
                    <span className="common-clr">This game is </span>
                    <div className="provably-fair d-flex align-center">
                        <div className="i-fair"><img src="/green-tricks-footer.svg" alt="" /></div>
                        <span className="text-provably-fair">Provably Fair</span>
                    </div>
                </div>

            </div>
        </div>
    </>
}




function OtherPlayersTab() {

    const { currentGame, bets, showPrevBets, setShowPrevBets, prevBets } = useContext(AviatorContext)

    return <>
        <div className="d-flex align-items-center justify-content-between">
            <div className="bets-count secondary-font f-14">TOTAL BETS : <span className="text-success" id="total_bets">{bets.length}</span></div>

            <div className="custom-badge mx-auto bg1 bg3" hidden={!showPrevBets} id="prev_win_multi">0.00x</div>
            <div >
                {/* <button className={`btn btn-transparent previous-history d-flex align-items-center ${showPrevBets ? 'selected' : ""}`} id="current_hand_btn" onClick={() => setShowPrevBets(!showPrevBets)}>
                    <span className="material-symbols-outlined f-18 me-1 history-icos">
                        <MdHistory />
                    </span>
                    <span className="material-symbols-outlined f-18 me-1 close-icos">
                        <MdClose />
                    </span>
                    Previous hand
                </button> */}
            </div>

        </div>

        <div className="list-data-tbl mt-1">
            <div className="list-header">
                <div className="column-1">
                    User
                </div>
                <div className="column-2">
                    Bet
                </div>
                <div className="column-3">
                    Mult.
                </div>
                <div className="column-4">
                    Cash out
                </div>
            </div>
        </div>

        <div className="list-body scroll-div list-body0 mCustomScrollbar _mCS_51">



        {
                bets.length == 0 && <div className="d-flex flex-column justify-content-center align-content-center align-items-center " style={{height:"100%"}}>
                    
                    <img width={"250px"} src="/icons/empty.png"></img>
                    <p>No Bet found</p>
                </div>
            }


            {

               bets.map((e, index) => <PlayerItem key={index} item={e} />)
            }
        </div>
    </>
}

function MyBetsTab() {
    const { myBetsHistory } = useContext(AviatorContext)

    return <>


        <div className="list-data-tbl mt-1">
            <div className="list-header">
                <div className="column-1">
                    Time
                </div>
                <div className="column-2">
                    Bet
                </div>
                <div className="column-3">
                    Mult.
                </div>
                <div className="column-4">
                    Cash out
                </div>
            </div>
        </div>

        <div className="list-body scroll-div list-body0 mCustomScrollbar _mCS_51">

            {
                myBetsHistory.length == 0 && <div className="d-flex flex-column justify-content-center align-content-center align-items-center " style={{height:"100%"}}>
                    
                    <img width={"250px"} src="/icons/empty.png"></img>
                    <p>No Bet found</p>
                </div>
            }
            {
                myBetsHistory.map((e, index) => <MyBetItem item={e} />)
            }
        </div>
    </>
}


function MyBetItem({ item }) {

    return <>
        <div className={`list-items ${item['status'] == 'cashout' ? "active" : ""}`}>
            <div className="column-1 f-11 date" style={{ marginLeft: "8px" }}>
                {moment(item['timestamp']).format('hh:mm A')}
            </div>
            <div className="column-2">
                <button className="btn btn-transparent d-flex align-items-center mx-auto"> {item['amount'] + ".00"}₹ </button>
            </div>
            <div className="column-3"> {item['incrementor'] ? (<div className="bg3 custom-badge mx-auto">{item['incrementor']}x</div>) : "-"}  </div>
            <div className="column-4"> {item['cashoutAmount'] ? "₹"+item['cashoutAmount'] : "-"} </div>
        </div>
    </>
}


function HistoryContainer() {
    const { historyPointsList } = useContext(AviatorContext)
    const [showHistoryList, setShowHistoryList] = useState(false)

    return <>
        <div className="history-top">
            <div className="stats">
                <div className="payouts-wrapper">
                    <div className="payouts-block">
                        {historyPointsList.map((point, index) => {
                            return <div key={index} className={`bg${(index % 3) + 1} custom-badge`}>
                                {point}
                            </div>
                        })}

                    </div>
                </div>
                <div className="shadow">
                </div>
                <div className={`button-block ${showHistoryList ? "show" : ""}`}>
                    <div className="dropdown-toggle button histry-toggle" onClick={() => setShowHistoryList(!showHistoryList)}>
                        <div className="trigger" >
                            <span className="material-symbols-outlined">
                                <MdHistory size={'16px'} />
                            </span>
                            {/* <span className="material-symbols-outlined dd-icon up-arrow">
                                <MdArrowDropUp size={'0px'}/>
                            </span> */}
                            {/* <span className="material-symbols-outlined dd-icon down-arrow">
                                <MdArrowDropDown size={"14px"}/>
                            </span> */}
                        </div>
                    </div>
                    <div className="history-dropdown">
                        <div className="pa-5 secondary-font text-white pb-0">ROUND HISTORY</div>
                        <div className="d-flex flex-wrap pa-5 round-history-list">
                            {historyPointsList.map((point, index) => {
                                return <div key={index} className={`bg${(index % 3) + 1} custom-badge`}>
                                    {point}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

function LoadingGameView({ currentGame }) {
    return <div className="loading-game" hidden={currentGame['status'] != 'lobby'} >
        <div className="center-loading text-white text-center game-centeral-loading">
            <span className="loader-betx"></span>
            <div className="secondary-font mt-2 waiting-text"> WAITING FOR NEXT ROUND</div>
        </div>
    </div>
}

function GameOverView({ currentGame }) {

    const {multipler} = useContext(AviatorContext)
    

    return <>
        <div className="counter-num text-center" id="auto_increment_number_div" hidden={currentGame['status'] == 'lobby'}>
            <div className="flew_away_section" hidden={currentGame['status'] != 'crashed'}>FLEW AWAY!</div>
            {currentGame['status'] == 'crashed' && <div id="auto_increment_number" className={currentGame['status'] == 'crashed' ? "text-danger" : ""}>
                {currentGame['incrementor']+"x"}
            </div>}

            {currentGame['status'] == 'running' && <div id="auto_increment_number" className={currentGame['status'] == 'crashed' ? "text-danger" : ""}>
                {/* {currentGame['incremented']} */}
                {multipler.toFixed(2) + "x"}
                {/* <CounterIncrementor number={parseFloat(currentGame['incremented'])} gameId={currentGame['gameId']}></CounterIncrementor> */}
            </div>}

        </div>
    </>
}






