import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { io } from 'socket.io-client';
import { AccountContext } from "./account_context";
import { CurrencyContext } from "./currencyContext";




const WingoContext = React.createContext()

export default WingoContext;


const historyReducer = (prevState, currentData) => {
    switch (currentData['action']) {
    


        case "pageChange" : {
            const newState = {...prevState}
            newState[currentData.gameType].currentPage = currentData['pageNo']
        
            return newState; 
        }

        case "push": {
            const data = prevState[currentData.gameType]
          //  data.currentPage = 1
            data.data = [currentData['payload'], ...data.data];
            data.totalItems = data['totalItems'] + 1;

            return {
                ...prevState,
                [currentData.gameType]: data
            }
        }

        case "fetch": {
            const data = prevState[currentData.gameType]
            data.currentPage = currentData['payload'].pageNo
            data.data = [...data.data, ...currentData['payload'].data];
            data.totalItems = currentData['payload']['totalItems']

            return {
                ...prevState,
                [currentData.gameType]: data
            }
        }
    }
}





const betHistoryReducer = (prevState, currentData) => {
    switch (currentData['action']) {

        case "pageChange" : {
            const newState = {...prevState}
            newState[currentData.gameType].currentPage = currentData['pageNo']
            return newState; 
        }

        case "statusUpdate": {
            const gamePayload = currentData.payload; 
            const gameResult = gamePayload['result']
            const gameType = gamePayload['type']
            const gameId = gamePayload['gameId']
            const newState = {...prevState}

            const data = newState[gameType]

            const newList = data.data.map(item=>{
                if(item['gameId'] == gameId){
                    item['result'] = gameResult
                  //  item['status'] = betStatus['status']
                }
                return item
            })

            newState[gameType].data = newList;
            return newState
        }

        case "push": {
            const data = prevState[currentData.gameType]
            data.currentPage = 1
            data.data = [currentData['payload'], ...data.data];
            data.totalItems = data['totalItems'] + 1;

            return {
                ...prevState,
                [currentData.gameType]: data
            }
        }

        case "fetch": {
            const data = prevState[currentData.gameType]
            data.currentPage = currentData['payload'].pageNo
            data.data = [...data.data, ...currentData['payload'].data];
            data.totalItems = currentData['payload']['totalItems']

            return {
                ...prevState,
                [currentData.gameType]: data
            }
        }
    }
}

export function WinGoContextProvider({ children }) {

    const [activeGameType, setActiveGameType] = useState('1min')
    const [currentGame, setCurrentGame] = useState({})


    const [soundEnabled, setSoundEnabled] = useState(localStorage.getItem('wingoSoundEnabled')?? true)

    
    const { updateWinningAmount, wallet,syncWalletBalance } = useContext(AccountContext)

    const { currentCurrency } = useContext(CurrencyContext)
    const currentGameRef = useRef({})


    const [history, dispatch] = useReducer(historyReducer, {
        "1min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "3min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "5min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "15min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        }
    })

    const [betHistory, dispatchBetHistory] = useReducer(betHistoryReducer, {
        "1min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "3min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "5min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        },
        "15min": {
            currentPage: 0,
            totalItems: 0,
            data: []
        }
    })

    const [lastNumbers, setLastNumbers] = useState([])


    const [totalHistoryPages, setTotalHistoryPage] = useState(1)


    const timerRef = useRef()
    const socketRef = useRef()
    const [socketStatus, setSocketStatus] = useState('pending')



    const toggleSound = ()=>{
        localStorage.setItem("wingoSoundEnabled", !soundEnabled)
        setSoundEnabled(!soundEnabled)
    }

    const init = (game) => {
        setCurrentGame(game)
    }


    const onResult = (result) => {

        const gameId = result['gameId']
        const gameType = result['type']

        setTimeout(()=>{
            syncWalletBalance()
        },5000)


        
       // const currentGame = currentGameRef.current[gameType]
      //  if (!currentGame || currentGame['gameId'] != gameId) return;

    //   const currentGame = {
    //     gameId : gameId ,
    //     result : result['result']
    //   }
        //currentGame['result'] = result['result']


      
        dispatch({
            action: 'push',
            gameType: gameType,
            payload: result,
        })

        dispatchBetHistory({
            action : 'statusUpdate',
            payload: result,
        })
        
    }


    const vpGameStatus  = (result)=>{
         
         if(result['wonAmount']){
            syncWalletBalance()
        }
        dispatchBetHistory({
            action : 'statusUpdate',
            payload: result,
        })

        
    }
    const onGame = (game) => {
        const newGameState = { ...currentGameRef.current, [game['type']]: game }
        setCurrentGame(newGameState)
    }


    const placeBet = (bet, callback) => {
        socketRef.current.emit('join', {...bet,"currencyCode":currentCurrency['code']}, (response) => {
            
            callback(response)
            if(response['success']){
                syncWalletBalance()
                dispatchBetHistory(
                    {
                        payload: response['data'],
                        gameType: bet['gameType'],
                        action: "push"
                    }
                )
            }
        })
    }


    const placeBets = ()=>{


        const amounts = [100,200,300,400,500,600,700,800,1000]
        const betTypes = ['number','color','bigSmall']

        const numbers = [0,1,2,3,4,5,6,7,8,9]
        const bitSmalls = ['big','small']
        const colors = ['red','green','violet']

        const betType = betTypes[Math.ceil(Math.random() * 3) -1]
        const amount = amounts[Math.ceil(Math.random() * 8) -1]
        let value = ""
        if(betType == 'number'){
            value = numbers[Math.ceil(Math.random() * 10) -1]
        }
        if(betType == 'bigSmall'){
            value = bitSmalls[Math.ceil(Math.random() * 2) -1]
        }

        if(betType == 'color'){
            value = colors[Math.ceil(Math.random() * 3) -1]
        }

        const bet = {
            'amount': amount,
            'quantity': 1,
            'betType': betType,
            'value': value,
            'gameType' : "1min"
        }


        console.log(bet)

        placeBet({
            'amount': amount,
            'quantity': 1,
            'betType': betType,
            'value': value,
            'gameType' : "1min"
        },()=>{
            console.log('bet placed!!!')
        })
    }
    



    const startPlace = ()=>{
        // setTimeout(() => {
        //     for(let i =0; i<100;i++){
        //           placeBets()
        //       } 
        // }, 1000);
      
    }

   

    const fetchGameHistory = (gameType, pageNo) => {


        const data = history[gameType].data;

        const currentDataLength = data.length;

        if(currentDataLength >= pageNo * 10){

    
            dispatch({
                action : 'pageChange',
                pageNo : pageNo,
                gameType: gameType,
            })

            return;
        }

        socketRef.current.emit('history', {
            "gameType": gameType,
            "page": pageNo,
            "limit": 10,
        }, (response) => {
           
            if (response.success) {
                dispatch({
                    action: 'fetch',
                    gameType: gameType,
                    payload: response.data,
                })
            }
        })
    }

    const fetchBetHistory = (gameType, pageNo) => {

        const data = betHistory[gameType].data;

        const currentDataLength = data.length;

        if(currentDataLength >= pageNo * 10){
            dispatchBetHistory({
                action : 'pageChange',
                pageNo : pageNo,
                gameType: gameType,
            })

            return;
        }

        socketRef.current.emit('betHistory', {
            "gameType": gameType,
            "page": pageNo,
            "limit": 10,
        }, (response) => {
            if (response.success) {
                dispatchBetHistory({
                    action: 'fetch',
                    gameType: gameType,
                    payload: response.data,
                })
            }
        })
    }


    function connectSocket() {
        console.log('connecting to socket--> ',currentCurrency)
        let socket = io(process.env.REACT_APP_SERVER_SOCKET + "vpGo", {
            transports: ['websocket'],
            query: {
                authorization: localStorage.getItem('token'),
                currency: currentCurrency['code']
            }
        });
        socketRef.current = socket;
        socket.on("connect", () => {
            setSocketStatus('connected')
           
            console.log(socket.id, 'socket connected'); // x8WIv7-mJelg7on_ALbx
        });

        socket.on("disconnect", () => {
            setSocketStatus('disconnected')

        });

        socket.on("error", (e) => {
            setSocketStatus('error')
        });




        socket.on("connect_error", (err) => {

            console.log('connect_error', err)


            if (err.message == 'xhr poll error') {
                setSocketStatus('no_internet_error')
            }

            else if (err.message == 'unauthorized_user' || err.message == 'unknown_user') {
                setSocketStatus('unauthorized_user')
            }
            else {
                setSocketStatus('connect_error')
            }
        });


        socket.on('game', onGame)
        socket.on('init', init)
        socket.on('result', onResult)
       // socket.on('betStatus', vpGameStatus)
    }



    const closeSocket = () => {
        if (socketRef.current) socketRef.current.disconnect()

        socketRef.current = undefined;
    }



    useEffect(() => {
        currentGameRef.current = currentGame;
    }, [currentGame])


    useEffect(() => {
        if (socketStatus != 'connected') return;
        fetchGameHistory(activeGameType, 1)
        fetchBetHistory(activeGameType,1)
    }, [activeGameType,socketStatus])



    return <WingoContext.Provider value={{
        currentGame,
        placeBet,
        history,
        lastNumbers,
        closeSocket,
        connectSocket,
        activeGameType,
        setActiveGameType,
        fetchGameHistory,
        fetchBetHistory,
        betHistory,
        toggleSound,
        soundEnabled
    }}>
        {children}
    </WingoContext.Provider>

    

}