import { useContext, useEffect, useState } from "react";
import { NavBar } from "../../widgets/navBar";
import { getRequest } from "../../api/api";
import { LoadingPage } from "../../widgets/loading_page";
import { CurrencyContext } from "../../context/currencyContext";





const missionBonus = [{
    "invitees": 2,
    "amount": 5,
},
{
    "invitees": 5,
    "amount": 10,
}, {
    "invitees": 15,
    "amount": 30,
}, {
    "invitees": 50,
    "amount": 100,
}, {
    "invitees": 150,
    "amount": 250,
}, {
    "invitees": 200,
    "amount": 750,
}, {
    "invitees": 1200,
    "amount": 2000,
}, {
    "invitees": 3000,
    "amount": 3500,
}, {
    "invitees": 10000,
    "amount": 10000,
}, {
    "invitees": 20000,
    "amount": 15000,
},
{
    "invitees": 40000,
    "amount": 30000,
}
]

export function MissionBonusPage() {

    const [data, setData] = useState({ loading: true, data: undefined, error: undefined })

    const fetch = async () => {
        try {
            let endpoint = "users/referralsCount";

            const user = await getRequest(endpoint)
            setData({ data: user, loading: false, error: undefined })
        } catch (e) {
            setData({ data: undefined, loading: false, error: e })
        }
    }


    useEffect(() => {
        fetch()
    }, [])


    if (data['loading']) return <LoadingPage />

    if (data['error']) return <LoadingPage />


    const user = data['data']

    let { referralCount, successReferral } = user['referral'] ?? {referralCount : 0, successReferral : 0};

    var missionBonusList = missionBonus.map((item)=>{
        if(successReferral > item['invitees']){
            item['successInvitee'] = item['invitees'];
            successReferral -= item['invitees'];
        }else {
            item['successInvitee'] = successReferral;
            successReferral = 0;
        }
        
        return item;
    })


    return <>

        <NavBar title={"Invitation Bonus"}></NavBar>

        <div className="ps-2 pe-2 pt-4 pb-2">
            {
                missionBonusList.map((e, index) => <MissionBonusItem
                    index={index}
                    item={e}
                />)
            }

        </div>

    </>
}




function MissionBonusItem({ index, item }) {
    const {convertCoin} = useContext(CurrencyContext)
    return <div key={index} data-v-0cc018b2="" className="bonus-items">
        <div data-v-0cc018b2="" className="head">
            <div data-v-0cc018b2="" className="left">Bonus <div data-v-0cc018b2="">{index +1 }</div></div>
            <div data-v-0cc018b2="" className="right">{convertCoin(item['amount'])}</div></div>
        <div data-v-0cc018b2="" className="detail">
            <div data-v-0cc018b2="" className="text">Number of invitees</div>
            <div data-v-0cc018b2="" className="people">{`${item['successInvitee']}/${item['invitees']}`}</div>
        </div>
        <div data-v-0cc018b2="" className="line"></div>
        <div data-v-0cc018b2="" className={`btn1 ${item['successInvitee'] == item['invitees'] ? "successReferral" : "" }`}>{item['successInvitee'] == item['invitees'] ? "Completed" : "Unfinished"}</div>
    </div>
}