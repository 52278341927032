import "@taak/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar,utils } from "@taak/react-modern-calendar-datepicker";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useState } from "react";

export function DatePickerBottomSheet({ open, onClose, onDateSelect, selectedDate,maximumDate }) {

    return <BottomSheet
        className="bottomSheet"
        onDismiss={onClose}
        open={open}>
        <div className="ms-2">
            <Calendar
                value={selectedDate}
                calendarClassName="custom-calendar" // and this
                onChange={onDateSelect}
                maximumDate={maximumDate}
                shouldHighlightWeekends
            />

            
            </div>

    </BottomSheet>
}