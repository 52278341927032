import { BottomSheet } from "react-spring-bottom-sheet";
import { MdCheckCircle } from 'react-icons/md'
import { useContext, useEffect, useState } from "react";
import WingoContext from "../../context/wingo_context";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { CurrencyContext } from "../../context/currencyContext";


export default function PlaceBetSheet({ bet, onClose,gameType }) {

    console.log(bet)
    const { placeBet } = useContext(WingoContext)
    const { currentCurrency } = useContext(CurrencyContext)
    const [quantity, setQuantity] = useState()
    const [loading, setLoading] = useState(false)

    const [amount, setAmount] = useState(100)

    const changeQuantity = (value) => {
        let quan = parseInt(value)

        if (quan == NaN) {
            quan = 1;
        }
        if (quan < 1) quan = 1;

        setQuantity(quan)
    }

    const onInputChange = (e) => {
        e.stopPropagation()
        changeQuantity(e.target.value)
        //setQuantity(parseInt(e.target.value))
    }

    useEffect(()=>{
        if(!bet) return;
        setQuantity(bet['quantity'])
    },[bet])

    const onSubmit = () => {
        setLoading(true)
        placeBet({
            'amount': amount,
            'quantity': quantity,
            'betType': bet['betType'],
            'value': bet['value'],
            'gameType' : gameType
        }, (response) => {
            setLoading(false)
            if (response['success']) {
                toast.success('Bet placed!')
                onClose()
            } else {
                toast.error(response['message'])
            }
        })
    }

    return <BottomSheet open={bet}>
        <div className="bottom-sheet-header me-2 ms-2 mb-2 mt-2" style={(bet && bet['betType'] == 'color') ? { background: bet['value'], color: 'white' } : {}}>
            Select {bet && (bet['value'])}
        </div>


        <div className="balance-wrapper ms-2 me-2">
            <span className="sheet-label">
                Balance
            </span>
            <div className="amount-action-wrapper">
                {[1,100, 200, 500, 1000].map(item => {
                    return <span key={item} onClick={() => setAmount(item)} className={`amount-button ${item == amount ? "amount-button-active" : ""}`}>{item}</span>
                })}
            </div>
        </div>


        <div className="quantity-wrapper ms-2 me-2">
            <span className="sheet-label">Quantity</span>
            <span className="quantity-input-wrapper">
                <span onClick={() => changeQuantity(quantity - 1)} className="quantity-input-action">-</span>
                <input type="number" className="quantity-input" value={quantity} onChange={onInputChange}></input>
                <span onClick={() => changeQuantity(quantity + 1)} className="quantity-input-action">+</span>
            </span>
        </div>

        <span className="x-button-container-small ms-2">
            {[1, 5, 10, 20, 50, 100].map(item => {
                return <div key={item} onClick={() => setQuantity(item)} className={`x-button-small ${item == quantity ? "x-button-active" : ''}`}>X{item}</div>
            })}
        </span>


        <div className="terms-container ms-2 mt-2 mb-1">
            <span className="checkmark">
                <MdCheckCircle color="#070044" size={"24"} />
            </span>
            <span className="terms-label">
                Accept Terms & Conditions
            </span>

        </div>

        <div className="footer">
            <div className="cancel-button" onClick={onClose}>
                Cancel
            </div>
            <div className="place-bet-button" onClick={onSubmit}>
                {loading ? <Spinner size="sm" className="bet-place-loader"></Spinner> : `Total amount ${currentCurrency['symbol']} ${amount * quantity}`}
            </div>
        </div>
    </BottomSheet>
}